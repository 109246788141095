import {Field} from 'formik'
import React from 'react'

const PriceTypeFixed = ({errors, values}) => {
  // console.log(values)
  return (
    <div className='row'>
      <div className='col-md-4'>
        <div className='mb-10'>
          <Field
            as='select'
            className='form-select'
            aria-label='Select example'
            name='fixedPrice.time'
          >
            <option value='SelectType'>Select Type</option>
            <option value='TIME'>TIME</option>
            <option value='Energy'>Energy</option>
          </Field>
          {/* <span className='text-danger fs-5'>{errors.time && errors.time}</span> */}
        </div>
      </div>
      <div className='col-md-4'>
        <div className='mb-10'>
          <Field
            type='number'
            className='form-select'
            aria-label='Select example'
            placeholder='Price (excluding tax)'
            name='fixedPrice.price'
          />
          {/* <span className='text-danger fs-5'>{errors.price && errors.price}</span> */}
        </div>
      </div>
      <div className='col-md-4'>
        <div className='mb-10'>
          <Field
            type='number'
            className='form-select'
            aria-label='Select example'
            placeholder='GST PERCENTAGE'
            name='fixedPrice.gst'
          />
          {/* <span className='text-danger fs-5'>{errors.minutes && errors.minutes}</span> */}
        </div>
      </div>
    </div>
  )
}

export default PriceTypeFixed
