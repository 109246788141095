/* eslint-disable */

import React from 'react'
import {TableWidgetDT} from '../../../_metronic/partials/widgets/tables/TablesWidgetDT'
import Top from '../../modules/Chargestations/Charge/components/Top'

const DeviceTransaction = ({stationId}) => {
  return (
    <div className='mt-10'>

      <TableWidgetDT DT={DeviceTransaction} className='mt-0' stationId={stationId} />

    </div>
  )
}

export default DeviceTransaction
