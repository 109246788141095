/* eslint-disable */

import {KTIcon} from '../../../../../_metronic/helpers'

const Top = () => {
  function RefreshButton() {
    window.location.reload()
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-3'>
        <input
          type='text'
          className='form-control form-control-sm form-control-solid w-150px bg-secondary'
          name='Search'
          placeholder='Search '
        />
        <a
          href='#'
          onClick={RefreshButton}
          className='btn btn-icon btn-bg-secondary btn-active-color-success btn-sm'
        >
          <KTIcon iconName='arrows-circle' className='fs-1' />
        </a>
      </div>
    </>
  )
}


export default Top
