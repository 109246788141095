/* eslint-disable  */
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'

type Props = {
  className: string
}

export interface EmployeeUpdated {
  id: any
  rfidTag: String
  userId: String
  userName: String
  expiryDate: String
  masterRfidTag: String
  comments: String
}

export const EmployeeDetails = {
  heading: 'Employees',
  subheading: 'Over 10 Employees',
  name: 'Name',
  email: 'Email',
  mobileNumber: 'Mobile Number',
  rfidtag: '',
  userdetails: 'User Details',
  usermobile: 'User Mobile',
  createdby: 'Created By',
  status: 'Status',
  mastertag: 'Master Tag',
}

const EmployeeTable: React.FC<Props> = ({className}) => {
  const [employees, setEmployees] = useState([])

  const getAllEmployees = async () => {
    try {
      const res = await getRequest('/cms/users/employees/all')
      if (res && res.data) {
        console.log(res.data)
        setEmployees(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  // delete handler function
  const DeleteHandler = async (id: any) => {
    try {
      const res = await deleteRequest(`/employees/${id}`)
      toast.success('Deleted SuccessFully ✅')
    } catch (error) {
      toast.error('Unable To Delete')
    }
  }

  useEffect(() => {
    getAllEmployees()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{EmployeeDetails.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{EmployeeDetails.subheading}</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/AddEmployee' className='btn btn-sm btn-light-success'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Employee
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Operator Name</th>
                <th className='min-w-210px'>Email Id</th>
                <th className='min-w-210px'>Mobile Number</th>
                <th className='min-w-210px text-center'>Status</th>
                <th className='min-w-210px'>Group Type</th>
                <th className='min-w-210px text-end rounded-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {employees.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              className='h-75 align-self-end'
                              alt=''
                            />
                          
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/employee/' + item.id}
                            className='text-dark fw-bold text-hover-success mb-1 fs-6'
                          >
                            {item.OperatorName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item.email}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item.mobileNumber}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1  fs-6 text-center'>
                        {/* {item.status} */}{' '}
                        <span className='badge badge-light-success'>ACTIVE</span>
                      </a>
                    </td>
                    <td>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6 text-cente'>
                        {item?.roles[0]?.name}
                      </span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to={'/EditEmployee/' + item.id}
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        // onClick={() => EmailHandler(item.id)}
                      >
                        <KTIcon iconName='switch' className='fs-3' />
                      </button>
                      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-header bg-success'>
                              <h5 className='modal-title text-white'>Confirmation</h5>
                              <div
                                className='btn btn-icon btn-sm btn-active-light-success ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr061.svg'
                                  className='svg-icon svg-icon-2x'
                                />
                              </div>
                            </div>
                            <div className='modal-body'>
                              <div className='text-start'>
                                <h3>Are you sure you want to deactivate </h3>
                              </div>
                            </div>
                            <div className='modal-footer'>
                              <button
                                type='button'
                                className='btn btn-light-danger'
                                data-bs-dismiss='modal'
                              >
                                No
                              </button>
                              <button
                                type='button'
                                className='btn btn-light-success'
                                onClick={() => {}}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {EmployeeTable}
