/* eslint-disable */
import React, {useEffect, useState} from 'react'
import 'flatpickr/dist/flatpickr.css'
import Flatpickr from 'react-flatpickr'
import {KTIcon} from '../../../helpers'
import SettlementModal from '../../../../app/pages/layout-builder/SettlementModal'
import AddAcknowledgementModal from '../../../../app/pages/layout-builder/AddAcknowledgementModal'
import {useAuth} from '../../../../app/modules/auth'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import axios from 'axios'
import toast from 'react-hot-toast'

type Props = {
  className: string
  settlements: any
  expenses: any
  changeMonth: any
  getSettlements: any
}

const FinancialSettlementsTable: React.FC<Props> = ({
  expenses,
  settlements,
  className,
  changeMonth,
  getSettlements,
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState('')
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [settlementMonth, setSettlementMonth] = useState(new Date().getMonth() + 1)
  const [settlementId, setSettlementId] = useState('')
  const [ackId, setAckId] = useState('')

  const {currentUser} = useAuth()

  const handleChange = (e: any) => {
    setSelectedMonth(e.target.value)
    changeMonth(e.target.value, settlementMonth)
  }

  const handleSettlementMonthChange = (e: any) => {
    changeMonth(selectedMonth, e.target.value)
    setSettlementMonth(e.target.value)
  }

  const downloadAcknowledgement = async (itemId: any, item) => {
    let downloadToast = toast.loading('Downloading Acknowledgement')
    try {
      const res = await axios.get('http://localhost:3000/v1/expenses/download-ack/' + itemId, {
        withCredentials: true,
        responseType: 'blob',
      })
      const fileurl = window.URL.createObjectURL(new Blob([res.data]))

      const link = document.createElement('a')

      link.href = fileurl

      link.setAttribute('download', `${item?.split('/')[4]}`)
      document.body.appendChild(link)

      link.click()
      toast.success('Acknowledgement Downloaded Successfully', {
        id: downloadToast,
      })
    } catch (error: any) {
      console.log(error)
      console.log(error.message)
      if (error.response.status === 404) {
        toast.error('No Acknowledgement File found', {
          id: downloadToast,
        })
      }
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <h2 className='fs-3'>Expenses</h2>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px menu '>
                  <select
                    value={selectedMonth}
                    className='rounded p-1 ps-0 pe-3'
                    onChange={handleChange}
                  >
                    <option>Month</option>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </th>
                <th className='min-w-210px'>Particular</th>
                <th className='min-w-210px'>Amount</th>
                <th className='min-w-210px'>Acknowledgement</th>
                {currentUser?.group === '2' && <th className='min-w-110px'>Actions</th>}
              </tr>
            </thead>

            <tbody>
              {expenses?.map((item: any, index: any) => {
                return (
                  <tr>
                    <td>{item?.date?.split('T')[0]}</td>
                    <td>{item?.particular}</td>
                    <td>₹{item?.amount}</td>
                    <td>
                      {item?.acknowledgement?.split('/')[4]
                        ? item?.acknowledgement?.split('/')[4]
                        : currentUser?.group ===
                          '1' && (
                            <button
                              data-bs-toggle='modal'
                              data-bs-target='#upload_ack'
                              className='btn btn-sm btn-light-info'
                              onClick={() => {
                                setAckId(item?.id)
                              }}
                            >
                              Upload Ack
                            </button>
                          )}
                    </td>
                    {currentUser?.group === '2' && item?.acknowledgement?.split('/')[4] && (
                      <td>
                        <button
                          type='button'
                          className='btn btn-sm btn-outline-dark border border-2'
                          onClick={async () => {
                            downloadAcknowledgement(item?.id, item.acknowledgement)
                          }}
                        >
                          Download Ack
                        </button>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <h2 className='fs-3'>Settlements</h2>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>
                  <select
                    value={settlementMonth}
                    className='rounded p-1 ps-0 pe-3'
                    onChange={handleSettlementMonthChange}
                  >
                    <option>Month</option>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </th>
                <th className='min-w-210px'>Month</th>
                <th className='min-w-210px'>Total Amount</th>
                <th className='min-w-210px'>Settled Amount</th>
                <th className='min-w-210px'>Balance Due</th>
                {currentUser?.group === '1' && <th className='min-w-110px'>Actions</th>}
              </tr>
            </thead>

            <tbody>
              {settlements?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item?.date?.split('T')[0]}</td>
                    <td>{item?.month}</td>
                    <td>₹{item?.totalAmount}</td>
                    <td className='text-success fw-semibold'>₹{item?.settledAmount}</td>
                    <td className='text-danger fw-semibold'>₹{item?.pendingAmount}</td>
                    {currentUser?.group === '1' && (
                      <td>
                        <a
                          data-bs-toggle='modal'
                          data-bs-target='#settle_amount'
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                          onClick={() => {
                            setSettlementId(item?.id)
                          }}
                        >
                          <KTIcon iconName='setting-4' className='fs-3' />
                        </a>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <SettlementModal settlementId={settlementId} getSettlements={getSettlements} />
      <AddAcknowledgementModal expenseId={ackId} />
    </div>
  )
}

export {FinancialSettlementsTable}
