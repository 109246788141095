import React from 'react'
import RfidUserD from './Components/RfidUserD'

const RfidUserSinglePage = () => {
  return (
    <div>
      <RfidUserD />
    </div>
  )
}

export default RfidUserSinglePage
