import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import toast from 'react-hot-toast'
import { postRequest, putRequest } from '../../../app/pages/Helpers/Requests'
import { KTSVG } from '../../helpers'

const validateStatus=Yup.object().shape({
    status:Yup.string().required("Status is required")
})
function TicketStatusModal({id,getTickets}) {
  const [initialValues, setInitialValues] = useState({
    status: '',
  })
  const handleSubmit=async (values,{resetForm})=>{
    try {
        const res=await putRequest("/tickets/"+id,values);
        if(res?.status===200){
            toast.success(res.data.message)
        }
    } catch (error) {
        console.log(error)
    }finally{
        resetForm();
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='changeTicketStatus'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Change Status Of Ticket</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validateStatus}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched,errors}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Status</label>
                        <Field name='status' className='form-control' />
                        <ErrorMessage component="div" name='status' />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                       
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketStatusModal;
