
import React from 'react'
import { Link } from 'react-router-dom';
import {Form, Field, Formik} from 'formik';
import { newRfidValidation } from '../js/NewRfidValidation';

const AddConsolidates = () => {

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const initialValues = {
    rfidTag : "",
    userId : "",
    userName : "",
    date : "",
    masterRfidTag : "",
    comments : "",
  }

  return (
    <div>
      <div className='text-center mb-5'>
        <h2 className='text-white bg-primary p-2 rounded'>create RFID tag</h2>
      </div>
      <div className='d-flex justify-content-end'>
            <div className='mb-10'>
              <Link to="/Evusers/EV/RfidTags">
              <button type='button' className='btn bg-info text-white'>
                Back
              </button>
              </Link>
            </div>
      </div>
      <h4>RFID Tag Details:</h4>
      <div className='mt-5'>
        <Formik
          initialValues={initialValues}
          validationSchema={newRfidValidation}
          onSubmit={() => {}}
        >
          {({errors}) => (

        <Form >
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field
                  type='text'
                  className='form-control p-2 form-control-solid border border-1 border-dark'
                  placeholder='RFID tag'
                  name='rfidTag'
                />
                <span className='text-danger fs-5'>{errors.rfidTag && errors.rfidTag}</span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field
                  type='text'
                  className='form-control p-2 form-control-solid border border-1 border-dark'
                  placeholder='User ID'
                  name='userId'
                />
                <span className='text-danger fs-5'>{errors.userId && errors.userId}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field
                  type='text'
                  className='form-control p-2 form-control-solid border border-1 border-dark'
                  placeholder='User Name'
                  name='userName'
                />
                <span className='text-danger fs-5'>{errors.userName && errors.userName}</span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field
                  type='date'
                  className='form-control p-2 form-control-solid border border-1 border-dark'
                  min={today}
                  name='date'
                />
                <label className='fw-medium ms-1 pt-1'>Expiry Date</label><br/>
                <span className='text-danger fs-5'>{errors.date && errors.date}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field as = 'select'
                  className='form-select p-2 form-select-solid border border-1 border-dark'
                  aria-label='Select example'
                  name='masterRfidTag'
                >
                  <option>Select master Rfid tag</option>
                  <option value='ghjd-(admin)'>ghjd-(admin)</option>
                </Field>
                <span className='text-danger fs-5'>{errors.masterRfidTag && errors.masterRfidTag}</span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-10'>
                <Field as ='textarea'
                  className='form-control p-2 form-control-solid border border-1 border-dark'
                  placeholder='Comments'
                  rows={1}
                  name='comments'
                ></Field>
                <span className='text-danger fs-5'>{errors.comments && errors.comments}</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <div className='mb-10'>
              <button type='reset' className='btn bg-secondary'>
                Reset
              </button>
            </div>
            <div className='mb-10 ms-2'>
              <button type='submit' className='btn text-white bg-primary'>
                Submit
              </button>
            </div>
          </div>
        </Form>
        )}
        </Formik>
      </div>
    </div>
  )
}

export default AddConsolidates
