/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from '../API/Api_Instance'
import {StationOperatorHeadings} from './Helpers/TableHeadings'
import {StationOpeartorInterface} from './Helpers/Interfaces'
import {getRequest} from './Helpers/Requests'
import StationLogs from './layout-builder/StationLogs'


const StationOperatorDetails = () => {
  let {pointId} = useParams()
  const [cmsUsers, setCmsUsers] = useState<StationOpeartorInterface | null>(null)

  // function for getting user data
  const getUser = async () => {
    try {
      const res = await getRequest(`/cms/users/${pointId}`)
      console.log(res)
      if (res && res.data) {
        setCmsUsers(res.data.data.cmsUser)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getUser()
  }, [])

  return (
    <div>
      {/* CMS::DETAILS::STARTS*/}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        {/* <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{StationOperatorHeadings.heading}</h3>
        </div> */}
        <div className='card-header bg-success d-flex justify-content-center'>

        <h1 className='card-title text-black text-capitalize fs-1'>{StationOperatorHeadings.heading}</h1>
        </div>
        <div className='card-body p-9'>
        <div className='card mb-6'>
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex flex-wrap flex-sm-nowrap'>
                    <div className='me-7 mb-4'>
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <img src='/media/avatars/blank.png' alt='image' />
                        <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px' />
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <a
                              href='#'
                              className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'
                            >
                              {cmsUsers?.OperatorName}
                            </a>
                            <a href='#'>
                              <i className='ki-outline ki-verify fs-1 text-primary' />
                            </a>
                          </div>
                          <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                            <a
                              href='#'
                              className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                            >
                              <i className='ki-outline ki-phone fs-4 me-1' />+91 {cmsUsers?.mobileNumber}
                            </a>
                            <a
                              href='#'
                              className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                            >
                              <i className='ki-outline ki-geolocation fs-4 me-1' /> {cmsUsers?.address1}
                            </a>
                            <a
                              href='#'
                              className='d-flex align-items-center text-gray-500 text-hover-primary mb-2'
                            >
                              <i className='ki-outline ki-sms fs-4 me-1' /> {cmsUsers?.email}
                            </a>
                          </div>
                        </div>
                        <div className='d-flex my-4'>
                          
                          {
                            cmsUsers?.status===1?(<a
                              href='#'
                              className={`btn btn-sm btn-success me-3`}
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_offer_a_deal'
                            >
                              Active
                            </a>):(<a
                            href='#'
                            className={`btn btn-sm btn-danger me-3`}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_offer_a_deal'
                          >
                            Inactive
                          </a>)
                          }
                        </div>
                      </div>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                          <div className='d-flex flex-wrap'>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                              <div className='d-flex align-items-center'>
                                <i className='ki-outline ki-arrow-down fs-3 text-danger me-2' />{' '}
                                <div
                                  className='fs-2 fw-bold counted'
                                  data-kt-countup='true'
                                  data-kt-countup-value={80}
                                  data-kt-initialized={1}
                                >
                                  {cmsUsers?.AMCend}
                                </div>
                              </div>
                              <div className='fw-semibold fs-6 text-gray-500'>AMC End date</div>
                            </div>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                              <div className='d-flex align-items-center'>
                                <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '}
                                <div
                                  className='fs-2 fw-bold counted'
                                  data-kt-countup='true'
                                  data-kt-countup-value={60}
                                  data-kt-countup-prefix='%'
                                  data-kt-initialized={1}
                                >
                                  {cmsUsers?.AMCstart}
                                </div>
                              </div>
                              <div className='fw-semibold fs-6 text-gray-500'>AMC Start Date</div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          
        </div>
        
      </div>
    </div>
  )
}

export default StationOperatorDetails
