import React from 'react'
import AddUser from './Components/AddUser'

const AddNewUser = () => {
  return (
    <div>
      <AddUser />
    </div>
  )
}

export default AddNewUser
