export const Rfidata = [
    {
        id: '1',
        rfidtag: 'ghjg',
        userid: '17797',
        username: 'Mani Mani',
        status  : 'ACTIVATED',
        expirydate: '10/09/2024',
        createdate: '10/09/2023',
        createdBy: '17792',
        updatedBy: 'Admin',
        updatedDate: '12/09/2023',
        comments: 'HgHg',
        position: '0',
        select: '',
    },
]

export const RfidUsersData = [
    {
        id: '1',
        username: 'Mani Mani',
        email: 'vanapallimani.kumari@teliolabs.com',
        mobile: '+91 - 8309251545',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'NA',
        vehicle: 'NA',
    },
]

export const ConsolidateDATA = [
    {
        id: '1',
        username: 'Mani Mani',
        email: 'vanapallimani.kumari@teliolabs.com',
        mobile: '+91 - 8309251545',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'NA',
        vehicle: 'NA', 
    }
]