/* eslint-disable */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {loactindata} from '../../../../app/pages/js/locationdata'
import { deleteRequest, postRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

type Props = {
  className: string
  requests: any
}
export interface locationUpdate {
  city: String
  station: String
}
export const locationTable = {
  heading: 'Recent Tariff Change Requests',
  subheading: "Requests received from CPO's",
  cpo: 'CPO Name',
  tariffName: 'Tariff Name',
  oldPrice: 'Old Price',
  updatedPrice: 'Updated Price',
  priceType: 'Price Type',
  type: 'Request',
  topic: 'Area of Change',
  status: 'Status',
}

const TableWidgetDeep1: React.FC<Props> = ({className, requests}) => {
  
  const acceptTariffChangeRequest=async(requestId)=>{
    try {
      const res=await postRequest("/tariff-change/accept-request/"+requestId,{});
      if(res?.status===200){
        toast.success("Successfully Accepted the request, Changes will be reflected in few minutes")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteChangeRequest=async(requestId)=>{
    try {
      const res=await deleteRequest("/tariff-change/"+requestId)
      if(res?.status===200){
        toast.success("Successfully deleted the change request")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const rejectChangeRequest=async(requestId)=>{
    try {
      const res=await deleteRequest("/tariff-change/reject-request/"+requestId)
      if(res?.status===200){
        toast.success("Rejected the change request")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{locationTable.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{locationTable.subheading}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-200px'>{locationTable.cpo}</th>
                <th className='min-w-200px'>{locationTable.tariffName}</th>
                <th className='min-w-200px'>{locationTable.priceType}</th>
                <th className='min-w-150px'>{locationTable.oldPrice}</th>
                <th className='min-w-150px'>{locationTable.updatedPrice}</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-110px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {requests?.map((item: any) => {
                return (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item?.cpo?.OperatorName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.tariffName}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.priceType}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.oldPrice}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.updatedPrice}
                      </a>
                    </td>
                    <td>
                      {item?.status === 0 ? (
                        <span className='badge badge-warning'>Pending</span>
                      ) : item?.status === 5 ? (
                        <span className='badge badge-danger'>Withdrawn</span>
                      ) : item?.status === 1 ? (
                        <span className='badge badge-success'>Accepted</span>
                      ) : item?.status === 2 ? (
                        <span className='badge badge-danger'>Rejected</span>
                      ) :(
                        <span className='badge badge-info'>Other</span>
                      )}
                    </td>
                    <td className='d-flex justify-content-end'>
                      <a
                        className='btn btn-icon btn-bg-light btn-light-danger btn-active-color-success btn-sm me-1'
                        onClick={() => {
                          acceptTariffChangeRequest(item?.id)
                        }}
                      >
                        <KTIcon iconName='check' className='fs-3' />
                      </a>
                      <a
                        className='btn btn-icon btn-bg-light btn-light-success btn-active-color-success btn-sm me-1'
                        onClick={() => {
                          (item?.status===5 || item?.status===2)?deleteChangeRequest(item?.id):rejectChangeRequest(item?.id)
                        }}
                      >
                        <KTIcon
                          iconName={`${(item?.status===5 || item?.status===2) ? 'trash' : 'abstract-11'}`}
                          className='fs-3'
                        />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableWidgetDeep1}
