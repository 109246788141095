import React from 'react'
import PTransactions from './Components/PTransactions'

const PendingTransaction = () => {
  return (
    <div>
      <PTransactions />
    </div>
  )
}

export default PendingTransaction
