import * as Yup from 'yup'

export const newCmsUserValidation = Yup.object({
  operatorId: Yup.string().min(3).required('Please enter your Operator ID'),
  OperatorName: Yup.string().min(3).required('Please enter your Operator name'),
  group: Yup.string().required('Please select your group'),
  email: Yup.string().email('Please enter valid email id').required('Please enter your email id'),
  country: Yup.string().required('Please select your country'),
  state: Yup.string().required('Please enter your state name'),
  city: Yup.string().required('Please enter your city name'),
  pincode: Yup.number().required('Please enter your pincode'),
  countryCode: Yup.string().required('Please select your country code'),
  mobileNumber: Yup.number().required('Please enter your Mobile number'),
  address1: Yup.string().required('Please enter your address1'),
})
