/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {TablesWidget99} from './../../../_metronic/partials/widgets/tables/TableWidget99'
import {servicedata} from './../js/ServiceData'
import Top from './../../modules/Chargestations/Charge/components/Top'
import CPOStationAmenities from '../../../_metronic/partials/widgets/tables/CPOStationAmenities'
import {getRequest} from '../Helpers/Requests'

const AmenitiesCPO = ({stations}) => {
  const [stationId, setStationId] = useState(stations[0]?.id)
  const [amenities, setAmenities] = useState([])
  const [edit,setEdit]=useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    setStationId(selectedValue)
  }

  const getAmenitiesByStationId = async () => {
    try {
      const res = await getRequest('/amenities/station/' + stationId)
      console.log(res)
      if (res?.status === 200) {
        let modifiedAmenities = res.data.data.map((item: any) => {
          return item.amenity
        })
        console.log(modifiedAmenities)
        setAmenities(modifiedAmenities)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stationId !== '' && stationId !== null && stationId !== undefined) {
      getAmenitiesByStationId()
    }
  }, [stationId])

  useEffect(()=>{
    setStationId(stations[0]?.id)
  },[stations])


  return (
    <>
      <div className='row mb-5 justify-content-between'>
        <div className='col-md-4 d-flex justify-content-end'>
          <select className='form-select' aria-label='Select example' value={stationId} onChange={handleChange}>
            <option>Select Station</option>
            {stations?.map((item: any, index: any) => {
              return (
                <option
                  key={index}
                  value={`${item?.id}`}
                >{`${item?.stationName} (${item?.stationId})`}</option>
              )
            })}
          </select>
        </div>
        <div className='col-md-2 d-flex justify-content-end'>
          <button className='btn btn-success' onClick={()=>{setEdit(!edit)}}>{!edit?"Edit":"Disable Edit"}</button>
        </div>
      </div>

      <CPOStationAmenities amenities={amenities} edit={edit} stationId={stationId}/>
    </>
  )
}

export default AmenitiesCPO
