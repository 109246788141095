import React from 'react'
import { TableRoleUserMapping } from '../../../_metronic/partials/widgets/tables/TableRoleUserMapping';

const UserMapping = () => {
  return (
    <div>
      <TableRoleUserMapping className='mb-5 mb-xl-8' />
    </div>
  )
}

export default UserMapping
