/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Deepdive7} from '../../../../app/pages/js/ChargersPerDay'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import {Link} from 'react-router-dom'

type Props = {
  className: String
  TCD: {}
  connectors:any
}
export interface tcdUpdates {
  chargeboxid: String
  connectorid: String
  chargingdate: String
  duration: String
}
export const TCDTable = {
  heading: 'Faulted Connectors',
  subheading: '',
  chargeboxid: 'Charge Box Id',
  connectorid: 'Connector Id',
  chargingdate: 'Charging Date',
  duration: 'Total Duration',
}

const TableWidgetDeep7: React.FC<Props> = ({className,connectors}:any) => {
  

  

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{TCDTable.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{TCDTable.subheading}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-200px'>Connector Id</th>
                <th className='min-w-200px'>Station Id</th>
                <th className='min-w-200px'>Port Type</th>
                <th className='min-w-200px'>Last Updated</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {connectors.map((item: any) => {
                return (
                  // StationDetails/1

                  <tr>
                    <td>
                      <a href={`/StationDetails/${item?.station?.id}`} className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item.connector_ID}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href={`/StationDetails/${item?.station?.id}`} className='text-dark fw-bold text-hover-primary fs-6'>
                            {item?.station?.stationId}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href={`/StationDetails/${item?.station?.id}`} className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.port_type}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold d-block fs-6'>
                        {item?.updatedAt.split('T')[0] +
                          '  ' +
                          item?.updatedAt.split('T')[1].split('.')[0]}
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableWidgetDeep7}
