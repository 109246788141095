import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import { postRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
    vendorId:Yup.string().required("VendorId is required"),
    data: Yup.string().required('Data is required'),
})

function DataTransfer({stationId}) {
  const [initialValues, setInitialValues] = useState({
    vendorId: '',
    messageId:'',
    data: '',
  })
  const handleSubmit =async (values, {resetForm}) => {
    console.log("getting in data transfer handle submit")
    try {
        const res=await postRequest('/ocpp/remote/data-transfer/'+stationId,values);
        console.log(res);
        if(res && res.data){
            toast.success(res.data.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='data_transfer'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Data Transfer</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select VendorId</label>
                        <Field as='select' name='vendorId' className='form-control'>
                          <option value=' '>Select Vendor</option>
                          <option value='QUENCH'>QUENCH</option>
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Provide Message</label>
                        <Field name='messageId' className='form-control' placeholder="MessageId(Optional)" />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Data(Text)</label>
                        <Field name='data' className='form-control' placeholder="Enter the Text" />
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTransfer;
