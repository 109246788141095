/* eslint-disable */

import React, { useEffect, useState } from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Field, Formik} from 'formik'
import {newRfidValidation} from '../pages/js/NewRfidValidation'
import PopUpSumbit from './Components/PopUpSubmit'
import axios from '../API/Api_Instance'
import toast,{Toaster} from 'react-hot-toast'


export interface RfidEdit {
  id: any
  rfidTag: String
  userId: String
  userName: String
  expiryDate: String
  masterRfidTag: String
  comments: String
}


const ConsolidateEdited = () => {
  const {pointId} = useParams()

  const navigate = useNavigate()

  const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format

  const [initialValues, setInitialValues] = useState({
    rfidTag: '',
    userId: '',
    userName: '',
    expiryDate: '',
    masterRfidTag: '',
    comments: '',
  });

  useEffect(() => {
    getUserObject();
  }, [pointId]);


  const getUserObject = async () => {
    try {
      const response = await axios.get(`ConsolidateData/${pointId}`);
      const fetchedData = { ...response.data };
      delete fetchedData.id;

      const updatedInitialValues = {
        ...initialValues,
        ...fetchedData
      };


      setInitialValues(updatedInitialValues);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleSubmit = (values) => {
    axios
      .put('ConsolidateData/' + pointId, values)
      .then((res) => {
        toast.success('RFID Updated Successfully ✅')
        setTimeout(() => {
          navigate('/Evusers/EV/RfidTags')
        }, 1000) 
      })
      .catch((err) => {
        toast.error('Invalid Data Check The Data')
        console.log(err)
      })
  }

  return (
    <div>
     
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT CONSOLIDATE RFID TAG</h3>
        </div>
        <div className='card-body'>
          <div className='mt-5'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={newRfidValidation}
              onSubmit={HandleSubmit}
            >
              {({errors}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='RFID tag'
                          name='rfidTag'
                        />
                        <span className='text-danger fs-5'>{errors.rfidTag && errors.rfidTag}</span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='User ID'
                          name='userId'
                        />
                        <span className='text-danger fs-5'>{errors.userId && errors.userId}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='User Name'
                          name='userName'
                        />
                        <span className='text-danger fs-5'>
                          {errors.userName && errors.userName}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field type='date' className='form-control' min={today} name='expiryDate' />
                        <label className='fw-medium ms-1 pt-1'>Expiry Date</label>
                        <br />
                        <span className='text-danger fs-5'>{errors.expiryDate && errors.expiryDate}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='masterRfidTag'
                        >
                          <option>Select master Rfid tag</option>
                          <option value='ghjd-(admin)'>ghjd-(admin)</option>
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.masterRfidTag && errors.masterRfidTag}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='textarea'
                          className='form-control'
                          placeholder='Comments'
                          rows={1}
                          name='comments'
                        ></Field>
                        <span className='text-danger fs-5'>
                          {errors.comments && errors.comments}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='mb-10'>
                      <button type='reset' className='btn btn-light-danger'>
                        Reset
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      {/* <PopUpSumbit text={'Update'}/> */}
                      <button type='submit' className='btn btn-light-success'>Update</button>

                    </div>
                    <div className='mb-10 ms-2'>
                      <Link to='/Evusers/EV/RfidTags'>
                        <button type='button' className='btn btn-light-primary'>
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsolidateEdited
