/* eslint-disable */

import React from 'react'
import { TableWidgetCD1 } from '../../../_metronic/partials/widgets/tables/TablesWidgetCD1'
import Top from '../../modules/Chargestations/Charge/components/Top'
import { StationSettingsTable } from '../../../_metronic/partials/widgets/tables/StationSettingsTable'

const StationSettings = ({stationId}) => {
  return (
    <div className='mt-10'>
      
      <StationSettingsTable tableone={StationSettings} stationId={stationId} className='mt-10' />
    </div>
  )
}

export default StationSettings;
