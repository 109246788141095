/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {TableOnes} from '../../../../app/pages/js/TableOne'
import {useParams} from 'react-router-dom'
import {DeviceTransactions} from '../../../../app/pages/js/DeviceTransaction'
import {getRequest} from './../../../../app/pages/Helpers/Requests'
import TransactionDetailsModal from '../../../../app/pages/Components/TransactionDetailsModal'
import {error} from 'console'

type Props = {
  className: String
  DT: {}
  stationId: any
}
export interface dtUpdates {
  config: String
  value: String
  description: String
  access: String
}
export const Transactions = {
    heading: 'Wallet Transactions',
    subheading: '',
    username: 'Username',
    email: 'Email',
    contactno: 'Contact No',
    datetime: 'Date & Time',
    amount: 'Amount',
    status: 'Type',
    remaining: 'Remaining Amount',
  }

const WalletTransactionsOfUser = ({className, userId}) => {
  let {pointId} = useParams()
  // var pid: number = parseInt(pointId);
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [transactions, setTransactions] = useState<any>([])
  var result = DeviceTransactions.find(function (e) {
    return e.id == pointId
  })

  const getDeviceTransactions = async () => {
    try {
      const res = await getRequest('/wallettrans/user/' + userId)
      console.log(res)
      if (res?.status === 200) {
        setTransactions(res.data.data)
        console.log(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (userId !== null && userId !== undefined && userId !== '') {
      getDeviceTransactions()
    }
  }, [userId])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      {/* begin::Table container */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-210px'>{Transactions.datetime}</th>
                <th className='min-w-210px'>{Transactions.amount}</th>
                <th className='min-w-210px'>{Transactions.status}</th>
                <th className='min-w-210px text-end'>{Transactions.remaining}</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item: any) => {
                return (
                  <tr>
                   
                   
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {new Date(item.createdAt).toLocaleDateString() +
                          ',' +
                          new Date(item.createdAt).toLocaleTimeString()}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Date || Time
                      </span>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.amount}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        <span
                          className={`badge badge-light badge-light-${
                            item?.type === 1 ? 'success' : 'danger'
                          }`}
                        >
                          {item?.type === 1 ? 'Credit' : 'Debit'}
                        </span>
                      </a>
                    </td>

                    <td className='text-end'>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.remainingAmount}
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {WalletTransactionsOfUser}
