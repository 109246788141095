/* eslint-disable */

import {useEffect, useState} from 'react'
import CMSGroupAssignedRoles from './CMSGroupAssignedRoles'
import CMSGroupAvailableRoles from './CMSGroupAvailableRoles'
import {useParams} from 'react-router-dom'
import {getRequest} from '../Helpers/Requests'

const CmsGroup = () => {
  let {pointId} = useParams()
  const [userRoles, setUserRoles] = useState([])

  const getUserRoles = async () => {
    try {
      const res = await getRequest(`/roles/user/${pointId}`)
      if (res && res.data) {
        setUserRoles(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getUserRoles()
  }, [])
  return (
    <div>
      <div className='row g-5 mt-10'>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header d-flex justify-content-center bg-success'>
              <h3 className='card-title text-white'>Available Roles</h3>
            </div>
            <div className='card-body card-scroll h-300px m-0 p-0 w-Full'>
              <CMSGroupAvailableRoles assignedRoles={userRoles} getUserRoles={getUserRoles} />
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header d-flex justify-content-center bg-success'>
              <h3 className='card-title text-white'>Assigned Roles</h3>
            </div>
            <div className='card-body card-scroll h-400px m-0 p-0 w-Full'>
              <CMSGroupAssignedRoles assignedRoles={userRoles} getUserRoles={getUserRoles} />
            </div>
          </div>
        </div>
        {/* <div className='d-flex justify-content-end'>
          <a href='#' className='btn btn-light-success'>
            Submit
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default CmsGroup
