/* eslint-disable */

import React from 'react'
import toast,{Toaster} from 'react-hot-toast'

import 'react-toastify/dist/ReactToastify.css'

function AlertButtons() {
  const notify = () => toast('✅ Changes Are Updated Successfully !!')

  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
      <button className='btn btn-light-success' onClick={notify}>
        Submit
      </button>
    </div>
  )
}

export default AlertButtons
