/* eslint-disable */

import React, {useEffect, useState} from 'react'
import L, {Map as LeafletMap, TileLayer, Marker} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch'
import axios from 'axios'
import MakerIcon from '../../../Maker/maker.png'
import {getRequest, postRequest} from '../../Helpers/Requests'

const NewMap = ({setFieldValue, LocationHandler}) => {
  const [position, setPosition] = useState<any>([17.5319902, 78.48751482192291])
  let photoUrl = 'https://icons8.com/icon/13800/location'
  const [clickedLocation, setClickedLocation] = useState<any>(null)

  // function for getting location data
  const handleReverseGeocoding = async (lat: any, lng: any) => {
    try {
      const res = await postRequest(`/getlocation`, {
        lat,
        long: lng,
      })
      console.log(res?.data.location)
      if (res && res.data) {
        setFieldValue('location', {
          latitude: res.data.location.lat,
          longitude: res.data.location.lon,
          address: res.data.location.display_name,
          city: res.data.location.address.city,
          state: res.data.location.address.state,
          country: res.data.location.address.country,
          pincode: res.data.location.address.postcode,
        })

        LocationHandler({
          latitude: res.data.location.lat,
          longitude: res.data.location.lon,
          address: res.data.location.display_name,
          city: res.data.location.address.city,
          state: res.data.location.address.state,
          country: res.data.location.address.country,
          pincode: res.data.location.address.postcode,
        })
      }
    } catch (error) {
      console.error('Error fetching address:', error)
    }
  }

  useEffect(() => {
    const streets: TileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {})
    const map: LeafletMap = L.map('map', {
      center: position,
      zoom: 13,
      scrollWheelZoom: true,
      layers: [streets],
      zoomControl: false,
    })
    const provider = new OpenStreetMapProvider()
    const searchControl: any = GeoSearchControl({
      provider,
      autoCompleteDelay: 300,
      showPopup: false,
    })

    map.addControl(searchControl)

    map.on('click', (event: any) => {
      const {lat, lng} = event.latlng
      setClickedLocation({lat, lng})
      clearMarkers()
      handleReverseGeocoding(lat, lng)
      addMarker(lat, lng, 'Clicked Location', photoUrl)
    })

    const addMarker = (lat: number, lng: number, label: string, photoUrl: string) => {
      const customIcon = L.icon({
        iconUrl: MakerIcon,
        iconSize: [35, 35],
        iconAnchor: [25, 50],
        popupAnchor: [0, -50],
      })

      const marker = L.marker([lat, lng], {icon: customIcon}).addTo(map)

      marker.bindPopup(label)
    }

    const clearMarkers = () => {
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer)
        }
      })
    }

    return () => {
      map.remove()
    }
  }, [])

  return (
    <div>
      <div id='map' style={{width: '100%', height: '50vh'}}></div>
    </div>
  )
}

export default NewMap
