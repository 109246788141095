import React from 'react'
import ConsolidateCreate from './Components/ConsolidateCreate'

const ConsolidateCreated = () => {
  return (
    <div>
      <ConsolidateCreate />
    </div>
  )
}

export default ConsolidateCreated
