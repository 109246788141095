/* eslint-disable */

import React from 'react'
import TopHeader from './TopHeader'
import DeviceLogs from './../../modules/Chargestations/Charge/components/DeviceLogs'

const DeviceLogos = ({stationId}) => {
  return (
    <div>
      <DeviceLogs stationId={stationId} />
    </div>
  )
}

export default DeviceLogos
