/* eslint-disable */

import React from 'react'

interface DaysButton {
  Day: string
}

const DaysButton = ({disabledArray, selectedDays, days, onSelectDay}) => {
  function HandlerDayClick(day) {
    if (selectedDays.includes(day)) {
      const updatedDays = selectedDays.filter((selectedDay) => selectedDay !== day)
      onSelectDay(updatedDays)
    } else {
      onSelectDay([...selectedDays, day])
    }
  }
  return (
    <div className='col-md-12 d-flex justify-content-between flex-wrap mb-15'>
      {days.map((day, index) => {
        return (
          <button
            onClick={() => HandlerDayClick(day)}
            disabled={disabledArray.includes(day) && !selectedDays.includes(day)}
            key={index}
            type='button'
            className={
              selectedDays.includes(day)
                ? 'btn btn-success rounded-end'
                : disabledArray.includes(day)
                ? 'btn btn-light-secondary rounded-end'
                : 'btn btn-light-success rounded-end'
            }
          >
            {day}
          </button>
        )
      })}
    </div>
  )
}

export default DaysButton
