import React from 'react'
import {Private} from '../../modules/apps/chat/components/Private'
import { PageTitle } from '../../../_metronic/layout/core'

const ServiceReqD = () => {
  return (
    <div>
       <PageTitle>Private chat</PageTitle>
      <Private /> 
    </div>
  )
}

export default ServiceReqD
