/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
import TicketStatusModal from '../../modals/TicketStatusModal'
type Props = {
  className: string
  services: Service[]
  // onClick: any
}

export interface Service {
  id: any
  ticketid: string
  email: string
  query: string
  description: string
  date: string
  time: string
}

const TablesWidget16: React.FC<Props> = ({services, className}) => {
  const [tickets, setTickets] = useState([])
  const [ticketId, setTicketId] = useState('')

  const getAllTickets = async () => {
    try {
      const res = await getRequest('/tickets/')
      if (res?.status === 200) {
        setTickets(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const closeTicket = async (id) => {
    try {
      const res = await deleteRequest('/tickets/' + id)
      if (res?.status === 200) {
        toast.success('Ticket Closed Successfully')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllTickets()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Service Request</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Ticket Id</th>
                <th className='min-w-120px'>Name</th>
                <th className='min-w-120px'>Subject</th>
                <th className='min-w-120px'>Description</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {tickets.map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <td>
                    <Link
                      to={'/ServiceReqSingle/' + item.id}
                      className='text-dark fw-bold text-hover-success fs-6'
                    >
                      {item.ticketId}
                    </Link>
                  </td>

                  <td>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item.name}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item.subject}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item.message}
                    </a>
                  </td>
                  <td className='text-dark fw-bold  fs-6'>{item?.createdAt?.split('T')[0]}</td>

                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                      data-bs-toggle='modal'
                      data-bs-target='#changeTicketStatus'
                      onClick={() => {
                        setTicketId(item?.id)
                      }}
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                      onClick={() => {
                        closeTicket(item.id)
                      }}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </td>
                </tr>
              )
            })}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <TicketStatusModal id={ticketId} getTickets={getAllTickets} />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget16}
