/* eslint-disable */

import React, {useState} from 'react'
import {Card} from 'react-bootstrap'
import UploadIcon from '../../Markers/Upload.png'
import {FaTrashAlt} from 'react-icons/fa'

const Upload: React.FC<any> = ({onImageChange, image, imagesetter}) => {
  return (
    <div>
      {image == '' ? (
        <Card
          style={{width: '18rem', border: '1px dashed grey', backgroundColor: '#FFFFFF'}}
          className='d-flex align-items-center justify-content-center'
        >
          <Card.Body>
            <Card.Title className='text-bold fs-3 text-center'>Upload Image</Card.Title>
            <Card.Text>
              <input type='file' id='Upload' className='d-none' onChange={onImageChange} />
              <label htmlFor='Upload'>
                <Card.Body className='d-flex justify-content-center'>
                  <img
                    src={UploadIcon}
                    alt='Icon'
                    style={{height: '75px', cursor: 'pointer', margin: 'auto'}}
                  />
                </Card.Body>
              </label>
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{width: '18rem', border: '1px dashed grey', backgroundColor: '#FFFFFF'}}
          className='d-flex align-items-center justify-content-center'
        >
          <Card.Body>
            <Card.Text>
              <FaTrashAlt size={25} className='justify-self-end' onClick={imagesetter} />
              <Card.Body className='d-flex justify-content-center'>
                <img
                  src={image}
                  alt='image'
                  style={{height: '170px', width: '170px', cursor: 'pointer'}}
                />
              </Card.Body>
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Upload;
