import instance from '../../../API/Api_Instance'
import axios from 'axios'
export const GET_USER_BY_ACCESSTOKEN_URL = `/auth/protected`
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `/auth/forgot_password`
const USER_PERMISSIONS_URL = '/permissions/'
const USER_ROLES_URL = '/roles/current/user'

// function to login
export function login(email: string, password: string) {
  return instance.post(LOGIN_URL, {
    email,
    password,
  })
}

// function to register the user
export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstName,
    lastName: lastName,
    password,
    password_confirmation,
  })
}

// function to reset the password
export function requestPassword(email: string) {
  return instance.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

// function to get the user token by id
export function getUserByToken() {
  return instance.get(GET_USER_BY_ACCESSTOKEN_URL)
}

export const getPermissions = (id) => {
  return instance.get(USER_PERMISSIONS_URL+'user/'+id)
}

export const getUserRoles = () => {
  return instance.get(USER_ROLES_URL)
}
