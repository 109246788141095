import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Form, Field, Formik} from 'formik'
import {newRfidValidation} from '../js/NewRfidValidation'
import toast,{Toaster} from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'
import {postRequest, getRequest} from '../Helpers/Requests'
import {rfIdTagInitialValues} from '../Helpers/AllInitialValues'
// import {DatePicker} from 'antd'
const AddTags = () => {
  const navigate = useNavigate()
  const [UserList, setUserList] = useState([])

  // function to all ev users
  const getUsers = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/ev/users/`)
      if (res && res.data) {
        console.log(res.data)
        setUserList(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // function to handling submit requests
  const handleSubmit = async (values: any) => {
    try {
      console.log(values)
      const res = await postRequest(`${process.env.REACT_APP_API_URL}/rfids`, values)
      if (res && res.data) {
        console.log(res.data)
        toast.success('RFID Created Successfully ✅')
        setTimeout(() => {
          navigate('/Evusers/EV/RfidTags')
        }, 1000)
      }
    } catch (error) {
      toast.error('Failed to create data ❌')
      console.log(error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])
  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title text-black'>CREATE NEW RFID TAG</h3>
        </div>
        <div className='card-body'>
          <div className='mt-5'>
            <Formik
              initialValues={rfIdTagInitialValues}
              validationSchema={newRfidValidation}
              onSubmit={handleSubmit}
            >
              {({errors, resetForm, setFieldValue, values}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          className='form-control'
                          placeholder='RFID tag'
                          name='rfIdTag'
                        />
                        <span className='text-danger fs-5'>{errors.rfIdTag && errors.rfIdTag}</span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          className='form-control'
                          placeholder='User ID'
                          name='userId'
                          disabled={true}
                        />
                        <span className='text-danger fs-5'>{errors.userId && errors.userId}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='userName'
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            if (e.target.value !== 'Select User') {
                              let val: string = e.target.value
                              let user: any | undefined = UserList.find((ele: any) => {
                                return ele.firstName === val
                              })
                              if (user) {
                                setFieldValue('userId', user.id)
                                setFieldValue('userName', user.firstName)
                              }
                            }
                          }}
                        >
                          <option>Select User</option>
                          {UserList.map((item: any, i) => (
                            <option key={i} value={item.firstName}>
                              {item.firstName}
                            </option>
                          ))}
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.userName && errors.userName}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        {/* <DatePicker
                          className='form-control'
                          name='expiryDate'
                          onChange={(date, dateString) => {
                            setFieldValue('expiryDate', dateString)
                          }}
                        /> */}
                        {/* <label className='fw-medium ms-1 pt-1'>Expiry Date</label> */}
                        <Field type='date' className='form-control' name='expiryDate' />
                        <br />
                        <span className='text-danger fs-5'>
                          {errors.expiryDate && errors.expiryDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='masterRfidTag'
                        >
                          <option>Select master Rfid tag</option>
                          <option value='ghjd-(admin)'>ghjd-(admin)</option>
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.masterRfidTag && errors.masterRfidTag}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='textarea'
                          className='form-control'
                          placeholder='Comments'
                          rows={1}
                          name='comments'
                        ></Field>
                        <span className='text-danger fs-5'>
                          {errors.comments && errors.comments}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='mb-10'>
                      <button
                        type='reset'
                        className='btn btn-light-danger'
                        onClick={() => resetForm()}
                      >
                        Reset
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      {/* <PopUpSumbit text={'Submit'} /> */}
                      <button type='submit' className='btn btn-light-success'>
                        Submit
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      <Link to='/Evusers/EV/RfidTags'>
                        <button type='button' className='btn btn-light-primary'>
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddTags
