import React from 'react'
import AddTags from './Components/AddTags'

const AddTag = () => {
  return (
    <div>
      <AddTags />
    </div>
  )
}


export default AddTag
