export const DeviceTransactions = [
    {
        id: '1',
        connector: 'Connector Id',
        username: 'UserName',
        start: 'Start Date',
        stop: 'Stop Date',
        duration: 'Charging Duration',
        meter: 'Total Meter Value (kWh)',
        platform: 'Platform',
        status: 'Status',
    },
    {
        id: '2',
        connector: 'Connector Id',
        username: 'UserName',
        start: 'Start Date',
        stop: 'Stop Date',
        duration: 'Charging Duration',
        meter: 'Total Meter Value (kWh)',
        platform: 'Platform',
        status: 'Status',
    }
]