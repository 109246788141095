import CMSDATA from './Components/CMSDATA'

const CMSsinglepage = () => {
  return (
    <div>
      <CMSDATA />
    </div>
  )
}

export default CMSsinglepage
