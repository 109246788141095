/* eslint-disable */

import Top from '../../../Chargestations/Charge/components/Top'
import {TableWidget97} from './../../../../../_metronic/partials/widgets/tables/TableWidget97'


const CmsUsers = ({stationId}) => {
  return (
    <>
      <TableWidget97 className='mb-5 mb-xl-8' stationId={stationId} />
    </>
  )
}

export default CmsUsers
