import * as Yup from 'yup'

export const newScheduleValidation = Yup.object({
  reportName: Yup.string().required('Please select your report name'),
  reportInterval: Yup.string().required('Please select your report interval'),
  operatorId: Yup.string().required('Please select your operator'),
  toRecepient: Yup.string().min(3).required('Please enter your To Recepient'),
  ccRecepient: Yup.string().min(3).required('Please select your Cc Recepient'),
  startDate: Yup.string().required('Please select your start date'),
  endDate: Yup.string().required('Please select your end date'),
  nextExecutionDate: Yup.string().required('Please select next execution date'),
})
