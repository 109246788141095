/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {TableOnes} from '../../../../app/pages/js/TableOne'
import {useParams} from 'react-router-dom'
import {DeviceTransactions} from '../../../../app/pages/js/DeviceTransaction'
import {getRequest} from './../../../../app/pages/Helpers/Requests'
import TransactionDetailsModal from '../../../../app/pages/Components/TransactionDetailsModal'
import {error} from 'console'

type Props = {
  className?: String
}
export interface dtUpdates {
  config: String
  value: String
  description: String
  access: String
}
export const DTable = {
  id: 'Transaction Id',
  connector: 'Connector Id',
  username: 'UserName',
  start: 'Start Date',
  stop: 'Stop Date',
  duration: 'Duration',
  meter: 'Total Meter value(Wh)',
  platform: 'Platform',
  status: 'Status',
}

const TotalDeviceTransaction: React.FC<Props> = ({className}) => {
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [transactions, setTransactions] = useState<any>([])
  const [gstTransactions, setGstTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [filter, setFilter] = useState('ALL')

  const getDeviceTransactionsOfGstUsers = async () => {
    try {
      const res = await getRequest('/device-transaction/with/gst-user/device-transactions')
      console.log(res)
      if (res?.status === 200) {
        setFilteredTransactions(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDeviceTransactions = async () => {
    try {
      const res = await getRequest('/device-transaction/')
      console.log(res)
      if (res?.status === 200) {
        setFilteredTransactions(res.data.data)
        console.log(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getDeviceTransactions()
  }, [])
  useEffect(() => {
    if (filter === 'ALL') {
      getDeviceTransactions()
    } else {
      getDeviceTransactionsOfGstUsers()
    }
  }, [filter])


  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Device Transactions</span>
        </h3>
        <div className='card-toolbar'>
          <select
            className='form-select'
            onChange={(e) => {
              setFilter(e.target.value)
            }}
          >
            <option value='All'>ALL</option>
            <option value='GST'>GST User Transactions</option>
          </select>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              <th className='min-w-210px'>{DTable.id}</th>
              <th className='min-w-210px'>ChargeBox Id</th>
              <th className='min-w-210px'>{DTable.connector}</th>
              <th className='min-w-210px'>{DTable.username}</th>
              <th className='min-w-210px'>{DTable.start}</th>
              <th className='min-w-210px'>{DTable.stop}</th>
              <th className='min-w-210px'>{DTable.duration + '(Mins)'}</th>
              <th className='min-w-210px'>{DTable.meter}</th>
              <th className='min-w-210px'>Amount(Rs)</th>
              <th className='min-w-110px'>Reason</th>
              <th className='min-w-210px'>{DTable.status}</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions &&
              filteredTransactions?.map((transaction: any, index: any) => {
                const updatedAt = new Date(transaction.updatedAt)
                const createdAt = new Date(transaction.createdAt)
                const timeDifference = updatedAt.getTime() - createdAt.getTime()
                const secondsDifference = timeDifference / 1000
                const localStartTime = new Date(transaction?.createdAt).toLocaleTimeString()
                const localStopTime = new Date(transaction?.updatedAt).toLocaleTimeString()
                return (
                  <tr key={index}>
                    <td>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#transaction_details'
                        className='cursor-pointer text-black'
                        onClick={() => {
                          setSelectedTransaction(transaction.transactionId)
                        }}
                      >
                        {transaction?.transactionId}
                      </a>
                    </td>
                    <td>
                      <span className='fw-bold'>{transaction?.stationId}</span>
                      {/* {" "}({transaction?.connectorId}) */}
                    </td>
                    <td>{transaction?.connectorId}</td>
                    <td>{transaction?.user?.firstName}</td>
                    <td>{transaction?.startDate?.split('T')[0] + ' ' + localStartTime}</td>
                    <td>
                      {transaction?.stopDate &&
                        transaction?.stopDate?.split('T')[0] + ' ' + localStopTime}
                    </td>
                    <td>{(secondsDifference / 60).toFixed(1)}</td>
                    <td>{(transaction?.total_wh/1000).toFixed(3)}</td>
                    <td>{transaction?.price}</td>
                    <td>{transaction?.reason}</td>
                    <td>
                      {transaction?.status == 0 ? (
                        <a href='#' className={`btn btn-sm btn-warning me-3`}>
                          Charging
                        </a>
                      ) : (
                        <a href='#' className={`btn btn-sm btn-success me-3`}>
                          Completed
                        </a>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <TransactionDetailsModal transactionId={selectedTransaction} />
        </table>
      </div>
    </div>
  )
}

export {TotalDeviceTransaction}
