/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Field, Formik} from 'formik'
import {newRfidValidation} from '../pages/js/NewRfidValidation'
import toast,{Toaster} from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'
import {getRequest, putRequest} from './Helpers/Requests'
import {rfIdTagInitialValues} from './Helpers/AllInitialValues'
import {DatePicker} from 'antd'

const RfidEdit = () => {
  const {pointId} = useParams()
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState(rfIdTagInitialValues)
  const today = new Date().toISOString().split('T')[0]

  // function to get details of RfId Tag
  const getRfIdTag = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/rfids/${pointId}`)
      if (res && res.data) {
        let rfidtag = res.data.data.RfIdTag
        setInitialValues(rfidtag)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const HandleSubmit = async (values: any) => {
    try {
      const res = await putRequest(`${process.env.REACT_APP_API_URL}/rfids/${pointId}`, values)
      if (res && res.data) {
        toast.success('RFID Updated Successfully ✅')
        setTimeout(() => {
          navigate('/Evusers/EV/RfidTags')
        }, 1000)
      }
    } catch (error: any) {
      toast.error('Invalid Data Check The Data 😒')
      console.log(error.message)
    }
  }

  useEffect(() => {
    getRfIdTag()
  }, [])

  return (
    <div>
    
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT ASSIGNED RFID TAG</h3>
        </div>
        <div className='card-body'>
          <div className='mt-5'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={newRfidValidation}
              onSubmit={HandleSubmit}
            >
              {({errors, setFieldValue}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='RFID tag'
                          name='rfIdTag'
                        />
                        {/* <span className='text-danger fs-5'>{errors.rfidTag && errors.rfidTag}</span> */}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='User ID'
                          name='userId'
                        />
                        <span className='text-danger fs-5'>{errors.userId && errors.userId}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          type='text'
                          className='form-control'
                          placeholder='User Name'
                          name='userName'
                        />
                        <span className='text-danger fs-5'>
                          {errors.userName && errors.userName}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <DatePicker
                          name='expiryDate'
                          className='form-control'
                          onChange={(date, dateString) => {
                            setFieldValue('expiryDate', dateString)
                          }}
                        />
                        <label className='fw-medium ms-1 pt-1'>Expiry Date</label>
                        <br />
                        <span className='text-danger fs-5'>
                          {errors.expiryDate && errors.expiryDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          disabled
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='masterRfidTag'
                        >
                          <option>Select master Rfid tag</option>
                          <option value='ghjd-(admin)'>ghjd-(admin)</option>
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.masterRfidTag && errors.masterRfidTag}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='textarea'
                          className='form-control'
                          placeholder='Comments'
                          rows={1}
                          name='comments'
                        ></Field>
                        <span className='text-danger fs-5'>
                          {errors.comments && errors.comments}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='mb-10'>
                      <button type='reset' className='btn btn-light-danger'>
                        Reset
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      <button type='submit' className='btn btn-light-success'>
                        Update
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      <Link to='/Evusers/EV/RfidTags'>
                        <button type='button' className='btn btn-light-primary'>
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RfidEdit
