export const Deepdive7 = [
    {
        chargeboxid:'Charge Box Id',
        connectorid: 'Connector Id',
        chargingdate: 'Charging Date',
        duration: 'Total Duration'
    },
    {
        chargeboxid:'Charge Box Id',
        connectorid: 'Connector Id',
        chargingdate: 'Charging Date',
        duration: 'Total Duration'
    },
    {
        chargeboxid:'Charge Box Id',
        connectorid: 'Connector Id',
        chargingdate: 'Charging Date',
        duration: 'Total Duration'
    },
    {
        chargeboxid:'Charge Box Id',
        connectorid: 'Connector Id',
        chargingdate: 'Charging Date',
        duration: 'Total Duration'
    },
  ]
  