/* eslint-disable */

import React, {useState} from 'react'
import {TwoCMS} from '../js/CMSS'
import {FaCheck} from 'react-icons/fa'
import {ImCross} from 'react-icons/im'
import axios from 'axios'
import {useParams} from 'react-router-dom'

export interface RoleUpdate {
  id: any
  role: string // Changed 'String' to 'string'
}

const CMSGroupAssignedRoles = ({assignedRoles, getUserRoles}) => {
  const {pointId} = useParams()
  interface AuthData {
    token: string
  }
  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

  const removePermissionForRole = (id) => {
    const data = {userId: pointId, roleId: id}
    axios
      .post(`${process.env.REACT_APP_API_URL}/roles/remove`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        getUserRoles()
      })
      .catch((err) => console.log(err))
  }
  return (
    <div>
      <ul className='list-group rounded-0'>
        {assignedRoles.map((item: any, index: number) => (
          <li
            key={index}
            className='list-group-item text-dark fw-bold py-7 d-flex fs-6 align-items-center gap-5'
          >
            <div className='d-flex justify-content-end flex-shrink-0'>
              <ImCross
                size={15}
                className='text-danger hoverable'
                style={{cursor: 'pointer'}}
                // data-bs-toggle='modal'
                // data-bs-target='#modal'
                onClick={() => removePermissionForRole(+item.id)}
              />
              {/* <div className='modal fade' tabIndex={-1} id='modal'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header  bg-success'>
                    <h5 className='modal-title text-white'>Confirmation</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-success ms-2 text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body d-flex  align-items-center'>
                    <h5>Are You Sure Want To Remove This Role</h5>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-dismiss='modal'
                    >
                      No
                    </button>
                    <button
                      type='button'
                      className='btn btn-light-success'
                      data-bs-dismiss='modal'
                  
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
            <label>{item.name}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CMSGroupAssignedRoles
