/* eslint-disable */

import React, {useState} from 'react'
import {TariffTableCpo} from './../../../_metronic/partials/widgets/tables/TariffTableCpo'
import {servicedata} from './../js/ServiceData'
import Top from './../../modules/Chargestations/Charge/components/Top'
const Tariff = () => {
  return (
    <>
      <TariffTableCpo  className='card-xxl-stretch mb-5 mb-xl-8' />
    </>
  )
}

export default Tariff;
