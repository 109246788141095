import React from 'react'
import DetailsTransaction from './Components/DetailsTransaction'

const TransactionSinglePage = () => {
  return (
    <div>
      <DetailsTransaction />
    </div>
  )
}

export default TransactionSinglePage
