/* eslint-disable */

import React from 'react'
import { TableWidgetCD1 } from '../../../_metronic/partials/widgets/tables/TablesWidgetCD1'
import Top from '../../modules/Chargestations/Charge/components/Top'

const DeviceConfiguration = ({stationId}) => {
  return (
    <div className='mt-10'>
      <TableWidgetCD1 tableone={DeviceConfiguration} stationId={stationId} className='mt-10'/>
    </div>
  )
}

export default DeviceConfiguration
