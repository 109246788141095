/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {newCmsUserValidation} from '../js/newCmsUserValidation'
import {useNavigate} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import {getRequest, postRequest} from '../Helpers/Requests'
import {CmsUserinitialValues} from '../Helpers/AllInitialValues'

const AddCms = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState([])

  // Submit function
  const handleSubmit = async (values) => {
    try {
      const res = await postRequest(`${process.env.REACT_APP_API_URL}/cms/users/`, values)
      if (res && res.data) {
        toast.success('Created SuccessFully ✅')
        setTimeout(() => {
          navigate('/UserManagment/User/CmsUsers')
        }, 1000)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // funuction to get assignable roles according to user
  const getAssignableRoles = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/roles`)
      if (res && res.data) {
        console.log(res.data)
        setRoles(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAssignableRoles()
  }, [])
  return (
    <div className='mt-n5'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD NEW CMS USER</h3>
        </div>
        <div className='card-body'>
          <Formik
            initialValues={{...CmsUserinitialValues}}
            // validationSchema={newCmsUserValidation}
            onSubmit={handleSubmit}
          >
            {({errors}) => (
              <Form>
                <div className='row pt-5'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='operator Id'
                        name='operatorId'
                      />
                      <span className='text-danger fs-5'>
                        {errors.operatorId && errors.operatorId}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='operator Name'
                        name='OperatorName'
                      />
                      <span className='text-danger fs-5'>
                        {errors.OperatorName && errors.OperatorName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                      />
                      <span className='text-danger fs-5'>{errors.email && errors.email}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='group'
                      >
                        <option value=''>Select Group</option>
                        {roles.map((e: any, i) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.name}
                            </option>
                          )
                        })}
                      </Field>
                      <span className='text-danger fs-5'>{errors.group && errors.group}</span>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='country'
                      >
                        <option value=''>Select Country</option>
                        <option value='India'>India</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.country && errors.country}</span>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='state'
                      >
                        <option value=''>Select State</option>
                        <option value='Andaman and Nicobar Islands'>
                          Andaman and Nicobar Islands
                        </option>
                        <option value='Andhra Pradesh'>Andhra Pradesh</option>
                        <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                        <option value='Assam'>Assam</option>
                        <option value='Bihar'>Bihar</option>
                        <option value='Chhattisgarh'>Chhattisgarh</option>
                        <option value='Goa'>Goa</option>
                        <option value='Gujarat'>Gujarat</option>
                        <option value='Haryana'>Haryana</option>
                        <option value='Himachal Pradesh'>Himachal Pradesh</option>
                        <option value='Jharkhand'>Jharkhand</option>
                        <option value='Karnataka'>Karnataka</option>
                        <option value='Kerala'>Kerala</option>
                        <option value='Madhya Pradesh'>Madhya Pradesh</option>
                        <option value='Maharashtra'>Maharashtra</option>
                        <option value='Manipur'>Manipur</option>
                        <option value='Meghalaya'>Meghalaya</option>
                        <option value='Mizoram'>Mizoram</option>
                        <option value='Nagaland'>Nagaland</option>
                        <option value='Odisha'>Odisha</option>
                        <option value='Punjab'>Punjab</option>
                        <option value='Rajasthan'>Rajasthan</option>
                        <option value='Sikkim'>Sikkim</option>
                        <option value='Tamil Nadu'>Tamil Nadu</option>
                        <option value='Telangana'>Telangana</option>
                        <option value='Tripura'>Tripura</option>
                        <option value='Uttar Pradesh'>Uttar Pradesh</option>
                        <option value='Uttarakhand'>Uttarakhand</option>
                        <option value='West Bengal'>West Bengal</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.state && errors.state}</span>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field type='text' className='form-control' placeholder='City' name='city' />
                      <span className='text-danger fs-5'>{errors.city && errors.city}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        name='pincode'
                      />
                      <span className='text-danger fs-5'>{errors.pincode && errors.pincode}</span>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='countryCode'
                      >
                        <option value='+91'>India</option>
                        <option value='+977'>Nepal</option>
                        <option value='+1'>United States</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.countryCode && errors.countryCode}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Mobile Number'
                        name='mobileNumber'
                      />
                      <span className='text-danger fs-5'>
                        {errors.mobileNumber && errors.mobileNumber}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line1'
                        name='address1'
                      />
                      <span className='text-danger fs-5'>{errors.address1 && errors.address1}</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line2'
                        name='address2'
                      />
                      <span className='text-danger fs-5'>{errors.address2 && errors.address2}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='GST Number'
                        name='gstNumber'
                      />
                      <span className='text-danger fs-5'>{errors.gstNumber && errors.gstNumber}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <label htmlFor='startDate'>AMC Start Date</label>
                      <Field
                        type='date'
                        className='form-control'
                        id='startDate'
                        // disabled
                        name='AMCstart'
                      />
                      <span className='text-danger fs-5'>{errors.AMCstart && errors.AMCstart}</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10 '>
                      <label htmlFor='endDate'>AMC End Date</label>
                      <Field
                        type='date'
                        className='form-control'
                        id='endDate'
                        // disabled
                        name='AMCend'
                      />
                      <span className='text-danger fs-5'>{errors.AMCend && errors.AMCend}</span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-primary'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button type='submit' className='btn btn-light-success'>
                      Submit
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </div>
                          </div>
                          <div className='modal-body'>
                            <p>Your data is not saved, Are you sure to exit ?</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                            >
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/UserManagment/User/CmsUsers')}
                              className='btn btn-light-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddCms
