/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {FaCheck} from 'react-icons/fa'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {getRequest, postRequest} from '../Helpers/Requests'

const CMSGroupAvailableRoles = ({assignedRoles, getUserRoles}) => {
  const {pointId} = useParams()
  const [AvailableRoles, setAvailableRoles] = useState([])

  // function to get all roles
  const getAllRoles = async () => {
    try {
      const res = await getRequest(`/roles/`)
      if (res && res.data) {
        let updated = res.data.data.filter((e) => {
          let assign = assignedRoles.find((ele) => e.name === ele.name)
          if (!assign) return e
        })
        setAvailableRoles(updated)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  // function to handle assigning role to user
  const assignRole = async (id: any) => {
    const data = {roleId: id, userId: pointId}
    try {
      const res = await postRequest(`/roles/assign`, data)
      if (res && res.data) {
        toast.success('Role Assigned to user success fully')
        getUserRoles()
      }
    } catch (error) {
      toast.error('Role not assigned to user')
    }
  }

  useEffect(() => {
    getAllRoles()
  }, [assignedRoles])
  return (
    <div>
      <ul className='list-group rounded-0'>
        {AvailableRoles.map((item: any, index: number) => (
          <li
            key={index}
            className='list-group-item text-dark fw-bold py-7 d-flex fs-6 align-items-center gap-5'
          >
            <div className='d-flex justify-content-end flex-shrink-0'>
              <FaCheck
                size={20}
                className='text-success hoverable'
                style={{cursor: 'pointer'}}
                onClick={() => assignRole(+item.id)}
              />
            </div>
            <label>{item.name}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CMSGroupAvailableRoles
