import React from 'react'
import WalletDetailsHeader from './Components/WalletDetailsHeader'

const WalletSinglePage = () => {
  return (
    <div>
      <WalletDetailsHeader />
    </div>
  )
}

export default WalletSinglePage
