import React from 'react'
import AddConsolidateData from './Components/AddConsolidateData'

const AddConsolidate = () => {
  return (
    <div>
      <AddConsolidateData />
    </div>
  )
}

export default AddConsolidate
