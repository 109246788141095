/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {TablesWidget99} from './../../../_metronic/partials/widgets/tables/TableWidget99'
import {servicedata} from './../js/ServiceData'
import Top from './../../modules/Chargestations/Charge/components/Top'
import {getRequest} from '../Helpers/Requests'
const TransactionCPO = ({stations}) => {
  const [stationId, setStationId] = useState('')
  const [transactions, setTransactions] = useState([])
  const getAllDeviceTransactionsByStationId = async () => {
    try {
      const res = await getRequest('/device-transaction/station/' + stationId)
      if (res?.status === 200) {
        setTransactions(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    console.log(selectedValue)
    setStationId(selectedValue)
  }

  useEffect(() => {
    if(stationId!=="" && stationId!==null && stationId!==undefined){
      getAllDeviceTransactionsByStationId()
    }
  }, [stationId])

  useEffect(()=>{
    setStationId(stations[0]?.stationId)
  },[stations])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-md-4 d-flex justify-content-end'>
          <select className='form-select' aria-label='Select example' value={stationId} onChange={handleChange}>
            <option>Select Station</option>
            {stations?.map((item: any,index:any) => {
              return (
                <option
                key={index}
                  value={`${item?.stationId}`}
                >{`${item?.stationName} (${item?.stationId})`}</option>
              )
            })}
          </select>
        </div>
      </div>
      {
        stationId==="" && <h1>Please Select Station to Check Transactions</h1>
      }
      {transactions && stationId!=="" && (
        <TablesWidget99 transactions={transactions} className='card-xxl-stretch mb-5 mb-xl-8' />
      )}
    </>
  )
}

export default TransactionCPO
