/* eslint-disable */

import React, {useState} from 'react'
import {roles} from '../js/AvailableRoles'
import {CMSRolesAvailable} from '../js/CmsData'
import {FaCheck} from 'react-icons/fa'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {postRequest} from '../Helpers/Requests'

export interface RoleUpdate {
  id: any
  role: string // Changed 'String' to 'string'
}
const CMSRole = ({AvailableRoles, getData}) => {
  const {pointId}: any = useParams()
  const assignPermission = async (id: any) => {
    try {
      const res = await postRequest(`/permissions/assign/user`, {
        userId: parseInt(pointId),
        permissionId: parseInt(id),
      })
      if (res && res.data) {
        console.log(res.data)
        getData()
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  return (
    <div>
      <ul className='list-group rounded-0'>
        {AvailableRoles.map((item: any, index: number) => (
          <li
            key={index}
            className='list-group-item text-dark fw-bold py-7 d-flex fs-6 align-items-center gap-5'
          >
            <div className='d-flex justify-content-end flex-shrink-0'>
              <FaCheck
                size={20}
                className='text-success hoverable'
                style={{cursor: 'pointer'}}
                // data-bs-toggle='modal'
                // data-bs-target='#modal'
                onClick={() => {
                  assignPermission(item.id)
                }}
              />
              {/* <div className='modal fade' tabIndex={-1} id='modal'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header  bg-success'>
                  <h5 className='modal-title text-white'>Confirmation</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-success ms-2 text-white'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body d-flex  align-items-center'>
                  <h5>Are You Sure Want To Remove This Role</h5>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light-danger'
                    data-bs-dismiss='modal'
                  >
                    No
                  </button>
                  <button
                    type='button'
                    className='btn btn-light-success'
                    data-bs-dismiss='modal'
                
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div> */}
            </div>
            <label>{item.name}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CMSRole
