import axios from '../../API/Api_Instance'
import toast from 'react-hot-toast';


const getRequest = async (endpoint: string) => {
  try {
    const res = await axios.get(endpoint)
    return res
  } catch (error) {
    console.log(error)
  }
}

const postRequest = async (endpoint: string, data: any, other = {}) => {
  try {
    let res = await axios.post(endpoint, data, other)
    return res
  } catch (error:any) {
    console.log(error)
    toast.error(error.response.data.message);
  }
}

const putRequest = async (endpoint: string, data: any) => {
  try {
    const res = await axios.put(endpoint, data)
    return res
  } catch (error) {
    console.log(error)
  }
}

const patchRequest=async(endpoint:string,data:any)=>{
  try {
    const res=await axios.patch(endpoint,data)
    return res
  } catch (error) {
    console.log(error);
  }
}

const deleteRequest = async (endpoint: string) => {
  try {
    const res = await axios.delete(endpoint)
    return res
  } catch (error) {
    console.log(error)
  }
}


export { getRequest, postRequest, putRequest,patchRequest, deleteRequest }
