import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
const validationSchema = Yup.object().shape({
  list_version: Yup.string().required('List Version is required'),
  update_type: Yup.string().required('Update Type is required'),
})

function SendLocalList({stationId}) {
  const [initialValues, setInitialValues] = useState({
    location: '',
    update_type: '',
    update_list: '',
    delete_list: '',
    empty_list:''
  })
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await postRequest('/ocpp/remote/start-transaction/' + stationId, values)
      if (res && res.data) {
        toast.success('Request to change Availability sent successfully')
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='send_localList'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Send Local List</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>List Version</label>
                        <Field placeholder='List Version*' name='x' className='form-control' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Update Type</label>
                        <Field
                          as='select'
                          name='update_type'
                          className='form-control'
                          placeholder='Update Type'
                        >
                          <option value=' '>Update Type</option>
                          <option value='FULL'>Full</option>
                          <option value='DIFFERENTIAL'>DIFFERENTIAL</option>
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Add Updated List</label>
                        <Field
                          as='select'
                          name='updated_list'
                          className='form-control'
                          placeholder='Updated List'
                        >
                          <option value=''>Add Updated List</option>
                          <option>List 1</option>
                          <option>List 2</option>
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Delete List</label>
                        <Field
                          as='select'
                          name='delete_list'
                          className='form-control'
                          placeholder='Delete List'
                        >
                          <option value=''>Delete List</option>
                          <option>List 1</option>
                          <option>List 2</option>
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        {/* <label className='form-label'></label> */}
                        <input
                          type='checkbox'
                          id='scales'
                          name='empty_list'
                          className='form-label'
                        />{' '}
                        Send Empty List
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendLocalList
