/* eslint-disable */

import {Formik, Form, Field} from 'formik'
import React from 'react'
import PopUpSumbit from './Components/PopUpSubmit'

const WalletEditPage = () => {
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>WALLET ADJUSTMENT</h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='mb-10'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Your Name'
                  disabled
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='mb-10'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Your User ID'
                  disabled
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='mb-10'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Wallet Amount Rs.'
                  disabled
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='mb-10'>
                <input type='text' className='form-control' placeholder='Adjustment Amount Rs' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <textarea
                  className='form-control'
                  id='exampleFormControlTextarea1'
                  placeholder='Add Comments description (maximum of 100 Characters'
                ></textarea>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-10'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-warning'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                   {/* <PopUpSumbit text={'Update'}/> */}
                   <button type='submit' className='btn btn-light-success' onClick={() => ""}>Update</button>

                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                  </div>  
          </div>        
        </div>
      </div>
    </div>
  )
}

export default WalletEditPage
