/* eslint-disable */

import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import PopUpSumbit from './PopUpSubmit'
import {Formik, Form, Field} from 'formik'
import {AccountDetailsValidation} from '../js/AccountDetatilsValidation'
import {Handler} from 'leaflet'
import { putRequest } from '../Helpers/Requests'
import { useParams } from 'react-router-dom'

export interface AccountForm {
  id: any
  accountholder: String
  accountnumber: String
  confirmaccount: String
  accountType: String
  transferType: String
  transfercode: String
  branch: String
  gstnumber: String
  pannumber: String
}

const ChargePointEditAC = () => {
  const {pointId} = useParams()
  const initialValues = {
    accountholder: '',
    accountnumber: '',
    confirmaccount: '',
    accountType: '',
    transferType: '',
    transfercode: '',
    branch: '',
    gstnumber: '',
    pannumber: '',
  }

  const handleSubmit=async(values,{resetForm})=>{
    try {
      const response=await putRequest(`/account/${pointId}`,values)
    } catch (error) {
      console.log(error);
    }
  }
  


  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-60px w-60px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/account.PNG')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Account Details
            </a>
          </div>

          <div className='card-toolbar m-0'>
           
          </div>
        </div>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={AccountDetailsValidation}
            onSubmit={() => {}}
          >
            {({errors}) => (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Account Holders Name'
                            name='accountholder'
                          />
                          <span className='text-danger fs-5'>
                            {errors.accountholder && errors.accountholder}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Account Number'
                            name='accountnumber'
                          />
                          <span className='text-danger fs-5'>
                            {errors.accountnumber && errors.accountnumber}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Confirm Account Number'
                            name='confirmaccount'
                          />
                          <span className='text-danger fs-5'>
                            {errors.confirmaccount && errors.confirmaccount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='accountType'
                      >
                        <option>Account Type</option>
                        <option value='SAVINGS'>SAVINGS</option>
                        <option value='CURRENT'>CURRENT</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.accountType && errors.accountType}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='transferType'
                      >
                        <option>Transfer Type</option>
                        <option value='IFSCODE'>IFSCODE</option>
                        <option value='SWIFTCODE'>SWIFTCODE</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.transferType && errors.transferType}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Transfer Code'
                        name='transfercode'
                      />
                      <span className='text-danger fs-5'>
                        {errors.transfercode && errors.transfercode}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Branch Code'
                        name='branch'
                      />
                      <span className='text-danger fs-5'>{errors.branch && errors.branch}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='GST Number'
                        name='gstnumber'
                      />
                      <span className='text-danger fs-5'>
                        {errors.gstnumber && errors.gstnumber}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='PAN Number'
                        name='pannumber'
                      />
                      <span className='text-danger fs-5'>
                        {errors.pannumber && errors.pannumber}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button
                      type='button'
                      className='btn btn-light-primary me-5'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Reset
                    </button>
                  </div>
                  <div className='mb-10'>
                    <button type='submit' className='btn btn-light-success' onClick={() => ''}>
                      Update
                    </button>
                  </div>
                  {/* <PopUpSumbit text={'Update'}/> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ChargePointEditAC
