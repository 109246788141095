// // [2,"c472fb31-ee4c-4b47-b500-2264d99c7095","GetConfiguration",{"key":["AuthorizationKey"]}]
// import React, {useState} from 'react'
// import * as Yup from 'yup'
// import {Formik, Form, ErrorMessage, Field} from 'formik'
// import {KTSVG} from '../../../helpers'
// import {postRequest} from '../../../../app/pages/Helpers/Requests'
// import toast from 'react-hot-toast'
// import Select from 'react-select'

// const validationSchema = Yup.object().shape({
//   // key: Yup.string().required('Connector is required')
//   key: Yup.array()
//     .min(1, 'Select at least one configuration key')
//     .required('Configuration keys are required'),
// })

// const keys = [
//   'AdditionalRootCertificateCheck',
//   'AllowOfflineTxForUnknownId',
//   'AuthorizationCacheEnabled',
//   'AuthorizationKey',
//   'AuthorizeRemoteTxRequests',
//   'BlinkRepeat',
//   'CentralContractValidationAllowed',
//   'CertificateSignedMaxChainSize',
//   'CertSigningWaitMinimum',
//   'CertSigningRepeatTimes',
//   'CertificateStoreMaxLength',
//   'ClockAlignedDataInterval',
//   'ConnectionTimeOut',
//   'ConnectorPhaseRotation',
//   'ContractValidationOffline',
//   'CpoName',
//   'HeartbeatInterval',
//   'ISO15118PnCEnabled',
//   'LightIntensity',
//   'LocalAuthListEnabled',
//   'LocalAuthorizeOffline',
//   'LocalPreAuthorize',
//   'MaxEnergyOnInvalidId',
//   'MeterValueSampleInterval',
//   'MinimumStatusDuration',
//   'ResetRetries',
//   'SecurityProfile',
//   'StopTransactionOnEVSideDisconnect',
//   'StopTransactionOnInvalidId',
//   'TransactionMessageAttempts',
//   'TransactionMessageRetryInterval',
//   'UnlockConnectorOnEVSideDisconnect',
//   'WebSocketPingInterval',
//   "OCPPEndpointToBackend",
//   "maxPowerLimitInkW",
//   "maxCurrentLimitInAmps",
//   "chargerName",
//   "superFastMode",
//   "NumberOfConnectors",
//   "SupportedFeatureProfiles",
//   "GetConfigurationMaxKeys",
//   "forceOfflineMode",
//   "ConnectorSwitch3to1PhaseSupported",
// ]

// function GetConfiguration({stationId}) {
//   const [initialValues, setInitialValues] = useState({
//     key: '',
//   })
//   const handleSubmit = async (values, {resetForm}) => {
//     try {
//       console.log(values)
//       const keys = values.key.map((value) => value.value)
//       console.log('keys', keys)
//       const res = await postRequest('/ocpp/remote/get-configuration/' + stationId, keys)
//       if (res && res.data) {
//         toast.success(res.data.message)
//       }
//     } catch (error) {
//       console.log(error)
//       toast.error(error.response.data.message)
//     }
//   }

//   return (
//     <div className='modal fade' tabIndex={-1} id='get_configuration'>
//       <div className='modal-dialog'>
//         <div className='modal-content'>
//           <div className='modal-header bg-success'>
//             <h5 className='modal-title text-white'>Get Configuration Of Device</h5>
//             <div
//               className='btn btn-icon btn-sm btn-active-light-success ms-2'
//               data-bs-dismiss='modal'
//               aria-label='Close'
//             >
//               <KTSVG
//                 path='/media/icons/duotune/arrows/arr061.svg'
//                 className='svg-icon svg-icon-2x'
//               />
//             </div>
//           </div>
//           <div className='modal-body'>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               {({values, setFieldValue, touched}) => (
//                 <Form>
//                   <div>
//                     <div className='row'>
//                       <div className='col-md-12 mt-3'>
//                         <label className='form-label'>Select Configuration Key</label>
//                         {/* <Field as='select' name='key' className='form-control'>
//                           <option value=' '>Select Configuration Key</option>
//                           {
//                             keys.map((key)=>{
//                                 return <option value={key}>{key}</option>
//                             })
//                           }
//                         </Field> */}
//                         <Field name='key'>
//                           {({field, form}) => (
//                             <Select
//                               options={keys.map((key) => ({value: key, label: key}))}
//                               isMulti
//                               onChange={(selectedOption) =>
//                                 form.setFieldValue(field.name, selectedOption)
//                               }
//                               onBlur={field.onBlur}
//                               value={keys.find((k) => field.value.includes(k))} // for initial values
//                             />
//                           )}
//                         </Field>
//                       </div>
//                     </div>
//                     <div className='modal-footer mt-3'>
//                       <button
//                         type='button'
//                         className='btn btn-light-danger'
//                         data-bs-dismiss='modal'
//                       >
//                         Cancel
//                       </button>
//                       <button
//                         type='submit'
//                         className='btn btn-light-success'
//                         data-bs-dismiss='modal'
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default GetConfiguration


// [2,"c472fb31-ee4c-4b47-b500-2264d99c7095","GetConfiguration",{"key":["AuthorizationKey"]}]
import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
import Select from 'react-select'

const validationSchema = Yup.object().shape({
  // key: Yup.string().required('Connector is required')
  key: Yup.array()
    .min(1, 'Select at least one configuration key')
    .required('Configuration keys are required'),
})

const keys = [
  'AdditionalRootCertificateCheck',
  'AllowOfflineTxForUnknownId',
  'AuthorizationCacheEnabled',
  'AuthorizationKey',
  'AuthorizeRemoteTxRequests',
  'BlinkRepeat',
  'CentralContractValidationAllowed',
  'CertificateSignedMaxChainSize',
  'CertSigningWaitMinimum',
  'CertSigningRepeatTimes',
  'CertificateStoreMaxLength',
  'ClockAlignedDataInterval',
  'ConnectionTimeOut',
  'ConnectorPhaseRotation',
  'ContractValidationOffline',
  'CpoName',
  'HeartbeatInterval',
  'ISO15118PnCEnabled',
  'LightIntensity',
  'LocalAuthListEnabled',
  'LocalAuthorizeOffline',
  'LocalPreAuthorize',
  'MaxEnergyOnInvalidId',
  'MeterValueSampleInterval',
  'MinimumStatusDuration',
  'ResetRetries',
  'SecurityProfile',
  'StopTransactionOnEVSideDisconnect',
  'StopTransactionOnInvalidId',
  'TransactionMessageAttempts',
  'TransactionMessageRetryInterval',
  'UnlockConnectorOnEVSideDisconnect',
  'WebSocketPingInterval',
  "OCPPEndpointToBackend",
  "maxPowerLimitInkW",
  "MeterValuesSampledData",
  "MeterValuesAlignedData",
  "maxCurrentLimitInAmps",
  "chargerName",
  "superFastMode",
  "NumberOfConnectors",
  "SupportedFeatureProfiles",
  "GetConfigurationMaxKeys",
  "forceOfflineMode",
  "ConnectorSwitch3to1PhaseSupported",
  "stackLevel",
  "stackSelectionEnabled",
  "SendLocalListMaxLength",
  "MaxChargingProfilesInstalled",
  "ChargeProfileMaxStackLevel",
  "SupportedFeatureProfilesMaxLength",
  "SimultaneousChargeEnable"
]

function GetConfiguration({stationId}) {
  const [initialValues, setInitialValues] = useState({
    key: '',
  })
  const handleSubmit = async (values, {resetForm}) => {
    try {
      console.log(values)
      const keys = values.key.map((value) => value.value)
      console.log('keys', keys)
      const res = await postRequest('/ocpp/remote/get-configuration/' + stationId, keys)
      if (res && res.data) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='get_configuration'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Get Configuration Of Device</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select Configuration Key</label>
                        {/* <Field as='select' name='key' className='form-control'>
                          <option value=' '>Select Configuration Key</option>
                          {
                            keys.map((key)=>{
                                return <option value={key}>{key}</option>
                            })
                          }
                        </Field> */}
                        <Field name='key'>
                          {({field, form}) => (
                            <Select
                              options={keys.map((key) => ({value: key, label: key}))}
                              isMulti
                              onChange={(selectedOption) =>
                                form.setFieldValue(field.name, selectedOption)
                              }
                              onBlur={field.onBlur}
                              value={keys.find((k) => field.value.includes(k))} // for initial values
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetConfiguration
