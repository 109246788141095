import React from 'react'
import ConsolidateData from './Components/ConsolidateData'

const ConsolidateSinglePage = () => {
  return (
    <div>
      <ConsolidateData />
    </div>
  )
}

export default ConsolidateSinglePage
