/* eslint-disable */

import React, {useState} from 'react'
import {TablesWidget16} from '../../_metronic/partials/widgets/tables/TablesWidget16'
import {servicedata} from './js/ServiceData'
import Top from '../modules/Chargestations/Charge/components/Top'
const Servicerequest = () => {
  return (
    <>
      <TablesWidget16 services={servicedata} className='card-xxl-stretch mb-5 mb-xl-8' />
    </>
  )
}

export default Servicerequest
