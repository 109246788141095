
/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import axios from '../../API/Api_Instance'

 interface ConsolidateData {
  id: any
  rfidTag: String
  userId: String
  userName: String
  status: String
  date: String
  createdate: String
  createdBy: String
  updatedBy: String
  updatedDate: String
  comments: String
  position: String
  select: String
}

export const RFID = {
  heading: 'RFID TAG Details',
  rfidtag: 'RFID Tag',
  userid: 'User Id',
  username: 'User Name',
  status: 'Status',
  expirydate: 'Expiry Date',
  createdate: 'Created Date',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  updatedDate: 'Update Date',
  comments: 'Comments',
  position: 'State',
  select: 'Vehicle',
}


const ConsolidateData = () => {

  let {pointId} = useParams()
  const [RFIDUsers, setRFIDUsers] = useState<ConsolidateData | null>(null)
  useEffect(() => {
      axios.get(`ConsolidateData`).then((res) => {
      const result = res.data.find((e: ConsolidateData) => e.rfidTag == pointId)
      setRFIDUsers(result || null)
    })
  }, [pointId])

  return (
    <div>
      {/* TariffHeader:Details:: Starts */}
      <div className='text-end'>
        <Link
          to='/Evusers/EV/RfidTags'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{RFID.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.rfidtag}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{RFIDUsers?.rfidTag}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.userid}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{RFIDUsers?.userId}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {RFID.username}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{RFIDUsers?.userName}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.status}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {/* {RFIDUsers?.status} */} Status ?
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {RFID.expirydate}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{RFIDUsers?.date}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.createdate}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.createdate} */} Created Date ?
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.createdBy}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.createdBy} */}Created By ?
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {RFID.updatedBy}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.updatedBy} */} Updated By
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.updatedDate}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.updatedDate} */}Updated Date ?
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.comments}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{RFIDUsers?.comments}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.position}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.position} */} State ?
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{RFID.select}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {RFIDUsers?.select} */}Vehicle ?
                </span>
            </div>
          </div>
        </div>
      </div>
      {/* TariffHeader:Details:: Ends */}
    </div>
  )
}

export default ConsolidateData
