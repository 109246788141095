import React from 'react'
import GroupSingleData from './Components/GroupSingleData'

const GroupSinglePage = () => {
  return (
    <div>
      <GroupSingleData />
    </div>
  )
}

export default GroupSinglePage
