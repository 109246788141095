const CmsUserinitialValues = {
  operatorId: '',
  OperatorName: '',
  group: '',
  email: '',
  country: 'India',
  state: '',
  city: '',
  pincode: '',
  countryCode: '+91',
  mobileNumber: '',
  address1: '',
  address2: '',
  AMCstart:new Date().toLocaleString(),
  AMCend: new Date().toLocaleString(),
  gstNumber:''
}


const EmployeeInitialValues = {
  OperatorName: '',
  group: '',
  email: '',
  country: 'India',
  state: '',
  city: '',
  pincode: '',
  countryCode: '+91',
  mobileNumber: '',
  address1: '',
  address2: '',
}
const ChargeStationInitialValues = {
  operatorId: '',
  stationName: '',
  stationId: '',
  communication: '',
  charge_type: '',
  total_power_output: 0,
  voltage_rating: '',
  status: '',
  image: '',
  location: {
    latitude: '',
    longitude: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  },
  connectors: [
    {
      connector_ID: 1,
      port_type: '',
      power_output: '',
      tariffId: '',
    },
  ],
}

const rfIdTagInitialValues = {
  rfIdTag: '',
  userId: '',
  userName: '',
  expiryDate: '',
  masterRfidTag: '',
  comments: '',
}

const TariffEditInitialValues = {
  name: '',
  operatorId: '',
  operator: { OperatorName: '' },
  country: 'India',
  currency: 'INR',
  priceType: 'Fixed',
  fixedPrice: {
    price: '',
    time: '',
    minutes: '',
    gst:""
  },
  variablePrices: [
    {
      vp: [{ time: '', startTime: '00:00', endTime: '', price: '' }],
      days: [],
    },
  ],
}

const locationInitialValues = {
  latitude: '',
  longitude: '',
  address: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
}

const scheduleReportInitial = {
  reportName: '',
  reportInterval: '',
  operatorId: '',
  toRecepient: '',
  ccRecepient: '',
  startDate: '',
  endDate: '',
  nextExecutionDate: '',
}

const evUserInitialValues = {
  firstName: '',
  lastName: '',
  countryCode: '+91',
  mobileNumber: '',
  email: '',
  country: 'India',
  contact:"",
  state: '',
  city: '',
  pincode: '',
  address1: '',
  address2: '',
  gstNum: '',
  platform: '',
  tenantid: '',
  date: '',
  rfidtag: '',
  progress: '',
}
export {
  CmsUserinitialValues,
  ChargeStationInitialValues,
  rfIdTagInitialValues,
  TariffEditInitialValues,
  locationInitialValues,
  scheduleReportInitial,
  evUserInitialValues,
  EmployeeInitialValues
}
