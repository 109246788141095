import React from 'react'
import RfidUsers from './Components/RfidUsers'

const RfidSinglePage = () => {
  return (
    <div>
      <RfidUsers />
    </div>
  )
}

export default RfidSinglePage
