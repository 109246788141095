export const WalletId = [
    {
        id: '1',
        payment: '',
        order: '',
        gateway: '',
        date: '',
        operation: '',
        paymentgateway: '',
        description: '',
        amount: '',
        adjustment: '',
    },
    {
        id: '2',
        payment: '',
        order: '',
        gateway: '',
        date: '',
        operation: '',
        paymentgateway: '',
        description: '',
        amount: '',
        adjustment: '',
    }
]