/* eslint-disable */

import React from 'react'
import EditDetailsCMS from './Components/EditDetailsCMS'
import EditCMSAC from './Components/EditCMSAC'
import EditCMSPermission from './Components/EditCMSPermission'
import CmsPermission from './Components/CmsPermission'

const EditCMS = () => {
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT CMS USER</h3>
        </div>
        <div className='card-body'>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                DETAILS
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                PERMISSIONS
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_6'>
                ACCOUNT DETAILS
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
              <EditDetailsCMS />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <CmsPermission />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
              <EditCMSAC />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCMS
