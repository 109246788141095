import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import { postRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  connector: Yup.string().required('Connector is required'),
  status: Yup.string().required('Status is required'),
})

const chargePointStatuses = [
  "Inoperative",
  "Operative"
]

function ChangeAvailability({stationId,connectors}) {
  const [initialValues, setInitialValues] = useState({
    connector: '',
    status: '',
  })
  const handleSubmit =async (values, {resetForm}) => {
    try {
        const res=await postRequest('/ocpp/remote/change-availability/'+stationId,values);
        if(res && res.data){
            toast.success("Request to change Availability sent successfully");
        }
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
    }
  }


  return (
    <div className='modal fade' tabIndex={-1} id='change_availability'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Change Availability Of Connector</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label className='form-label'>Select Connector</label>
                        <Field as='select' name='connector' className='form-control'>
                          <option value=' '>Select Connector</option>
                          {
                            connectors.map((connector)=>{
                              return <option value={connector.connector_ID}>{connector.connector_ID}{" "}{connector.port_type}</option>
                            })
                          }
                        </Field>
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label'>Select Status</label>
                        <Field as='select' name='status' className='form-control'>
                          <option value=''>Select Status of Connector</option>
                          {
                            chargePointStatuses.map((key)=>{
                                return <option value={key}>{key}</option>
                            })
                          }
                          
                        </Field>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeAvailability
