import React from 'react'
import AddCms from './Components/AddCms'

const AddCmsUser = () => {
  return (
    <div>
      <AddCms />
    </div>
  )
}

export default AddCmsUser
