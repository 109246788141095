import * as Yup from 'yup'

export const AccountDetailsValidation = Yup.object().shape({
  accountHolderName: Yup.string().required('Account Holder name is required'),
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(/^\d{10,}$/, 'Account number must be at least 10 digits'),
  confirm_ac: Yup.string()
    .required('Please confirm account number')
    .oneOf([Yup.ref('accountNumber'), null], 'Account numbers must match'),
  accountType: Yup.string().required('Account type is required'),
  bankName: Yup.string().required('Bank Name is required'),
  ifscCode: Yup.string().required('IFSC Code is required'),
})
