/* eslint-disable */

import React, {useEffect, useState} from 'react'
import GroupDetails from './GroupDetails'
import GroupUserMapping from './GroupUserMapping'
import GroupRoleMapping from './GroupRoleMapping'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {getRequest} from '../Helpers/Requests'

const GroupSingleData = () => {
  let {pointId} = useParams()
  const [role, setRole] = useState<any>({})
  const getRole = async () => {
    try {
      const res = await getRequest(`/roles/${pointId}`)
      if (res && res.data) {
        console.log(res.data)
        setRole(res.data.data.role)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getRole()
  }, [])
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title text-black'>GROUPS-{role.name}</h3>
        </div>

        <div className='card-body mt-5'>
          <div className='d-flex justify-content-end'>
            <Link
              to='/UserManagment/User/Roles'
              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
            >
              <KTIcon iconName='arrow-left' className='fs-5' />
            </Link>
          </div>
          {/* Tabs::Row::Starts*/}
          <ul className='nav nav-tabs nav-line-tabs mb-10 fs-5'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                GROUP DETAILS
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                USER MAPPING
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                ROLE MAPPING
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              <GroupDetails groupData={role} />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              <GroupUserMapping />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
              <GroupRoleMapping />
            </div>
          </div>
          {/* Tabs::Row::Ends*/}
        </div>
      </div>
    </div>
  )
}

export default GroupSingleData
