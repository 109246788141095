import React from 'react'
import { TableWidget100 } from '../../../_metronic/partials/widgets/tables/TableWidget100'

const GroupUserMapping = () => {

  return (
    <>

      {/*Row::Table::Starts */}

      <TableWidget100 className='mb-5 mb-xl-8' />

      {/*Row::Table::Ends */}
    </>
  )
}

export default GroupUserMapping
