import React from 'react'
import AddSchedules from './Components/AddSchedules'

const AddSchedule = () => {
  return (
    <div>
      <AddSchedules />
    </div>
  )
}

export default AddSchedule
