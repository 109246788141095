/* eslint-disable */

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { getRequest } from '../../../pages/Helpers/Requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  setPermissions: Dispatch<SetStateAction<any | undefined>>
  setRoles: Dispatch<SetStateAction<any | undefined>>
  permissions: any
  roles: any
  userId:any
  setUserId:any
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  user: {},
  setPermissions: () => {},
  setRoles: () => {},
  permissions: [],
  roles: [],
  userId:null,
  setUserId:()=>{}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [userId,setUserId]=useState<any>()
  
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    const res=await getRequest("/auth/logout")
    if(res?.status===200){
      console.log(res.data)
    }
    saveAuth(undefined)
    setCurrentUser(undefined)
    
    localStorage.removeItem('user')
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        setPermissions,
        setRoles,
        permissions,
        roles,
        userId,
        setUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setPermissions, setRoles, currentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // function to see if the token is present in the local if present get user details
  // else call the logout

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const res = await getUserByToken()
          if (res.data) {
            setCurrentUser(res.data.data.user)
            
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (!currentUser) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}



export {AuthProvider, AuthInit, useAuth}
