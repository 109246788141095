/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import ConnectorDetails from './ConnectorDetails'
import {getRequest} from '../Helpers/Requests'

const GeneralDetailsOne = ({errors, values, setFieldValue,touched}) => {
  const [cms, setCms] = useState<any>([])

  const getAllOperators = async () => {
    try {
      const res = await getRequest(`/cms/users`)
      if (res && res.data) {
        setCms(res.data.data.CmsUsers)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllOperators()
  }, [])
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-70px w-70px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/car-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Station Details
            </a>
          </div>

          <div className='card-toolbar m-0'>
           
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='operatorId'
                    >
                      <option>Select Operator</option>
                      {cms.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.OperatorName}
                          </option>
                        )
                      })}
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.OperatorName && errors.OperatorName && errors.OperatorName}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      aria-label='Select example'
                      placeholder='Name of Station'
                      name='stationName'
                    />
                    <span className='text-danger fs-5'>
                      {touched.stationName && errors.stationName && errors.stationName}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='StationID'
                      name='stationId'
                    />
                    <span className='text-danger fs-5'>{touched.stationId && errors.stationId && errors.stationId}</span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='communication'
                    >
                      <option value='Communication Medium'>Communication Medium</option>
                      <option value='Wifi'>Wifi</option>
                      <option value='3G/4G'>3G/4G</option>
                      <option value='5G'>5G</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.communication && errors.communication && errors.communication}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='charge_type'
                    >
                      <option>Charge Type</option>
                      <option value='AC'>AC</option>
                      <option value='DC'>DC</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.charge_type && errors.charge_type && errors.charge_type}
                    </span>
                  </div>
                </div>
                {/* <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='multiconnectors'
                      // onChange={handleVisibilityToggle}
                    >
                      <option>Multiconnectors</option>
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {errors.multiconnectors && errors.multiconnectors}
                    </span>
                  </div>
                </div> */}
                <div className='col-md-4'>
                  <div className='mb-10 opacity-50'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Total power output'
                      disabled
                      name='total_power_output'
                    />
                    <span className='text-danger fs-5'>
                      {touched.total_power_output && errors.total_power_output && errors.total_power_output}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                {/* <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='noOfConnectors'
                    >
                      <option value=''>No.Of Connectors</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {errors.noOfConnectors && errors.noOfConnectors}
                    </span>
                  </div>
                </div> */}
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='voltage Rating(V)'
                      name='voltage_rating'
                    />
                    <span className='text-danger fs-5'>
                      {touched.voltage_rating && errors.voltage_rating && errors.voltage_rating}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='status'
                    >
                      <option value=''>Status</option>
                      <option value='Active'>Active</option>
                      <option value='InActive'>InActive</option>
                    </Field>
                    <span className='text-danger fs-5'>{touched.status && errors.status && errors.status}</span>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div>
                  <ConnectorDetails errors={errors} values={values} touched={touched}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralDetailsOne
