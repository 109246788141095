import React, {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import toast,{Toaster} from 'react-hot-toast'
import {EmployeeInitialValues} from '../pages/Helpers/AllInitialValues'
import {getRequest, postRequest} from './Helpers/Requests'

const AddCms = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState([])

  const validationSchema = Yup.object().shape({
    OperatorName: Yup.string().required('Employee Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    group: Yup.string().required('Group is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    pincode: Yup.string().required('Pincode is required'),
    countryCode: Yup.string().required('Country code is required'),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]+$/, 'Mobile number must contain only digits')
      .min(10, 'Mobile number must be at least 10 digits long')
      .max(15, 'Mobile number cannot be more than 15 digits long'),
    address1: Yup.string().required('Address line 1 is required'),
    address2: Yup.string().required('Address line 2 is required'),
  })

  const handleSubmit = async (values) => {
    try {
      const res = await postRequest(`/cms/users/employee`, values)
      if (res && res.data) {
        toast.success('Created Successfully ✅')
        setTimeout(() => {
          navigate('/Employees')
        }, 1000)
      }
    } catch (error) {
      console.log(error)
      toast.error('Created Successfully ✅')
    }
  }

  const getAssignableRoles = async () => {
    try {
      const res = await getRequest(`/roles`)
      if (res && res.data) {
        setRoles(res.data.data.filter((item) => item.name !== 'admin' && item.name !== 'CPO'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAssignableRoles()
  }, [])

  return (
    <div className='mt-n5'>
      <Toaster position='top-right' reverseOrder={true} />
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD NEW EMPLOYEE</h3>
        </div>
        <div className='card-body'>
          <Formik
            initialValues={EmployeeInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className='row pt-5'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Employee Name'
                        name='OperatorName'
                      />
                      <ErrorMessage
                        name='OperatorName'
                        component='div'
                        className='text-danger fs-5'
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                      />
                      <ErrorMessage name='email' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select Group'
                        name='group'
                      >
                        <option value=''>Select Group</option>
                        {roles.map((item: any, i: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>
                      <ErrorMessage name='group' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select Country'
                        name='country'
                      >
                        <option value=''>Select Country</option>
                        <option value='India'>India</option>
                        {/* Add more country options if needed */}
                      </Field>
                      <ErrorMessage name='country' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select State'
                        name='state'
                      >
                        <option value=''>Select State</option>
                        <option value='Andhra Pradesh'>Andhra Pradesh</option>
                        <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                        <option value='Assam'>Assam</option>
                        <option value='Bihar'>Bihar</option>
                      </Field>
                      <ErrorMessage name='state' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field type='text' className='form-control' placeholder='City' name='city' />
                      <ErrorMessage name='city' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        name='pincode'
                      />
                      <ErrorMessage name='pincode' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select Country Code'
                        name='countryCode'
                      >
                        <option value='+91'>India</option>
                        <option value='+977'>Nepal</option>
                        <option value='+1'>United States</option>
                      </Field>
                      <ErrorMessage
                        name='countryCode'
                        component='div'
                        className='text-danger fs-5'
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Mobile Number'
                        name='mobileNumber'
                      />
                      <ErrorMessage
                        name='mobileNumber'
                        component='div'
                        className='text-danger fs-5'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address Line 1'
                        name='address1'
                      />
                      <ErrorMessage name='address1' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address Line 2'
                        name='address2'
                      />
                      <ErrorMessage name='address2' component='div' className='text-danger fs-5' />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-primary'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button type='submit' className='btn btn-light-success'>
                      Submit
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <button
                              type='button'
                              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </button>
                          </div>
                          <div className='modal-body'>
                            <p>Your data is not saved, Are you sure to exit ?</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                            >
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/UserManagment/User/CmsUsers')}
                              className='btn btn-light-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddCms
