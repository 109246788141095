import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'

const QRScanner = ({value, title}) => {
  console.log(value);
  useEffect(() => {
    const container = document.getElementById('Container')
    if (!container) {
      return // Exit early if the container is not found
    }

    // Render a QRCode to an element with ID 'Container' after the component has mounted
    ReactDOM.render(<QRCode value='hey' />, container)

    // Clean up function to unmount the QRCode when the component unmounts
    return () => {
      ReactDOM.unmountComponentAtNode(container)
    }
  }, []) // Empty dependency array ensures this effect runs only once after initial mount

  return (
    <div style={{textAlign: 'center'}}>
      <div style={{height: 'auto', margin: '0 auto', maxWidth: 256}}>
        <h3 className='p-5'>{title}</h3>
        <QRCode
          size={256}
          style={{height: 'auto', maxWidth: '100%', width: '100%'}}
          value={value}
          viewBox='0 0 256 256'
        />
      </div>
    </div>
  )
}

export default QRScanner
