/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link, useParams} from 'react-router-dom'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import {User} from '../../../../app/pages/Helpers/Interfaces'

type Props = {
  className: string
}

const TablesWidget14: React.FC<Props> = ({className}) => {
  const [Operators, setOperators] = useState<User[]>([])
  const [groupType, setGroupType] = useState('All')
  const [value, setValues] = useState<User>()

  const userData =
    groupType == 'All' ? Operators : Operators.filter((item) => item.group == groupType)

  const getAllUsers = async () => {
    try {
      const res = await getRequest(`/cms/users/cms/cpo`)
      console.log(res?.data)
      if (res && res.data) {
        setOperators(res.data.cmsUsers)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const handleOnChange = (e: any) => {
    e.preventDefault()
    setGroupType(e.target.value)
  }

  function EmailHandler(id: any) {
    const EmailFliter = Operators.find((e: User) => e.id === id)
    setValues(EmailFliter)
  }

  useEffect(() => {
    getAllUsers()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Charge Point Operator</span>
        </h3>
        <div className='card-toolbar'>
          
          <select className='form-select' onChange={handleOnChange}>
            <option value='All'>ALL</option>
            <option value='2'>CPO</option>
            <option value='3'>SubCpo</option>
          </select>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-success fw-semibold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>New Ticket</a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>New Customer</a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div
              className='menu-item px-3'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='right-start'
              data-kt-menu-flip='left-start, top'
            >
              {/* begin::Menu item */}
              <a className='menu-link px-3'>
                <span className='menu-title'>New Group</span>
                <span className='menu-arrow'></span>
              </a>
              {/* end::Menu item */}
              {/* begin::Menu sub */}
              <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a className='menu-link px-3'>Admin Group</a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a className='menu-link px-3'>Staff Group</a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a className='menu-link px-3'>Member Group</a>
                </div>
                {/* end::Menu item */}
              </div>
              {/* end::Menu sub */}
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>New Contact</a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mt-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content px-3 py-3'>
                <a className='btn btn-success btn-sm px-4'>Generate Reports</a>
              </div>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Operator Name</th>
                <th className='min-w-210px'>Email Id</th>
                <th className='min-w-210px'>Mobile Number</th>
                <th className='min-w-210px text-center'>Status</th>
                <th className='min-w-210px'>Group Type</th>
                <th className='min-w-210px text-end rounded-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                            className='h-75 align-self-end'
                            alt=''
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/OperatorInformation/' + item.id}
                            className='text-dark fw-bold text-hover-success mb-1 fs-6'
                          >
                            {item.OperatorName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item.email}</a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item.mobileNumber}</a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1  fs-6 text-center'>
                        {/* {item.status} */}{' '}
                        <span className='badge badge-light-success'>ACTIVE</span>
                      </a>
                    </td>
                    <td>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6 text-cente'>
                        {item.roles[0]?.name}
                      </span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to={'/ChargePointEdit/' + item.id}
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={() => EmailHandler(item.id)}
                      >
                        <KTIcon iconName='switch' className='fs-3' />
                      </button>
                      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-header bg-success'>
                              <h5 className='modal-title text-white'>Confirmation</h5>
                              <div
                                className='btn btn-icon btn-sm btn-active-light-success ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr061.svg'
                                  className='svg-icon svg-icon-2x'
                                />
                              </div>
                            </div>
                            <div className='modal-body'>
                              <div className='text-start'>
                                <h3>Are you sure you want to deactivate {value?.email}</h3>
                              </div>
                            </div>
                            <div className='modal-footer'>
                              <button
                                type='button'
                                className='btn btn-light-danger'
                                data-bs-dismiss='modal'
                              >
                                No
                              </button>
                              <button
                                type='button'
                                className='btn btn-light-success'
                                onClick={() => {}}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget14}
