// import React, {useState} from 'react'
// import * as Yup from 'yup'
// import {Formik, Form, ErrorMessage, Field} from 'formik'
// import {postRequest} from '../../../../app/pages/Helpers/Requests'
// import toast from 'react-hot-toast'
// import {KTSVG} from '../../../../_metronic/helpers'

// function StationImageUploadModal({stationId}) {
//   const [files, setFiles] = useState([])
//   const handleSubmit = async (values, {resetForm}) => {
//     try {
//         let formData=new FormData();
//         console.log(values)
//         console.log(typeof values.images)
//         formData.append("image",values.images)

//         const res=await postRequest(`/stations/upload/${stationId}`,formData)
//         if(res?.status===201){
//             toast.success("Successfully uploaded the Station Images")
//         }
//     } catch (error: any) {
//       console.log(error)
//     }
//   }

//   return (
//     <div className='modal fade' tabIndex={-1} id='upload_images'>
//       <div className='modal-dialog'>
//         <div className='modal-content'>
//           <div className='modal-header bg-success'>
//             <h5 className='modal-title text-white'>Upload Station Images</h5>
//             <div
//               className='btn btn-icon btn-sm btn-active-light-success ms-2'
//               data-bs-dismiss='modal'
//               aria-label='Close'
//             >
//               <KTSVG
//                 path='/media/icons/duotune/arrows/arr061.svg'
//                 className='svg-icon svg-icon-2x'
//               />
//             </div>
//           </div>
//           <div className='modal-body'>
//             <Formik initialValues={{}} onSubmit={handleSubmit}>
//               {({values, setFieldValue, touched}) => (
//                 <Form>
//                   <div>
//                     <div className='row'>
//                       <div className='col-md-12 mt-3'>
//                         <label className='form-label'>Select Images</label>
//                         <input
//                           type='file'
//                           name='image'
//                           className='form-control'
//                           multiple

//                         />
//                       </div>
//                     </div>
//                     <div className='modal-footer mt-3'>
//                       <button
//                         type='button'
//                         className='btn btn-light-danger'
//                         data-bs-dismiss='modal'
//                       >
//                         Cancel
//                       </button>
//                       <button
//                         type='submit'
//                         className='btn btn-light-success'
//                         data-bs-dismiss='modal'
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default StationImageUploadModal

import React from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
import {KTSVG} from '../../../../_metronic/helpers'

function StationImageUploadModal({stationId}) {
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const formData = new FormData()

      // Append each file to the FormData object
      values.images.forEach((file: any, index) => {
        formData.append('image', file)
      })

      const res = await postRequest(`/stations/upload/${stationId}`, formData)

      if (res?.status === 201) {
        toast.success('Successfully uploaded the Station Images')
        resetForm() // Reset form after successful upload
      }
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to upload images')
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='upload_images'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Upload Station Images</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={{images: []}}
              onSubmit={handleSubmit}
              validationSchema={Yup.object({
                images: Yup.array()
                  .of(Yup.mixed().required('A file is required'))
                  .required('At least one file is required'),
              })}
            >
              {({setFieldValue, values}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Select Images</label>
                      <input
                        type='file'
                        name='images'
                        className='form-control'
                        multiple
                        onChange={(event: any) => {
                          setFieldValue('images', Array.from(event.target.files))
                          const files = Array.from(event.target.files)
                          const maxFileSize = 2 * 1024 * 1024

                          files.map((file: any) => {
                            if (file.size > maxFileSize) {
                              toast.error(`File ${file.name} exceeds the 2MB size limit.`)
                            }
                          })
                        }}
                      />
                      <ErrorMessage name='images' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationImageUploadModal
