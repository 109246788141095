/* eslint-disable */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {permissions, roles} = useAuth()

  const {currentUser, logout} = useAuth()
  console.log(currentUser, 'current user')
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='chart-simple-2'
        title='Dashboard'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem to='/map' icon='map' title='Map' fontIcon='bi-layers' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CPO Management</span>
        </div>
      </div>

      <SidebarMenuItem to='/Chargepoint' title='CPO Management' icon='gear' />

      <SidebarMenuItemWithSub
        to='/apps/analytics/menu'
        title='Analytics'
        fontIcon='bi-chat-left'
        icon='abstract-8'
      >
        <SidebarMenuItem to='/apps/menu/Revenue' title='Revenue' icon='dollar' />
        {/* <SidebarMenuItem to='/apps/menu/Footfall' title='Footfall' hasBullet={true} /> */}
        <SidebarMenuItem to='/apps/menu/ChargeBox' title='ChargeBox' icon='technology-4' />
        {/* <SidebarMenuItem to='/apps/menu/DeepDive' title='DeepDive' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>

      {currentUser && currentUser?.group == '1' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Station Management
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/Chargestations/Charge/Stations'
            title='Station Management'
            icon='electricity'
          />
          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Reports</span>
            </div>
          </div> */}

          {/* <SidebarMenuItemWithSub
            to='/report/data'
            title='Reports'
            fontIcon='bi-chat-left'
            icon='chart-simple'
          > */}
          {/* <SidebarMenuItem
          to='/report/data/TransactionSummary'
          title='Transaction Summary'
          hasBullet={true}
        />
        <SidebarMenuItem to='/report/data/Settlement' title='Settle Report' hasBullet={true} /> */}
          {/* <SidebarMenuItem
              to='/report/data/ChargerReport'
              title='Charge Report'
              hasBullet={true}
            />
             <SidebarMenuItem to='/report/data/Schedule' title='Schedule A Report' hasBullet={true} />
          </SidebarMenuItemWithSub> */}

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tariff</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/Chargestations/Charge/Tariff'
            title='Tariff Management'
            fontIcon='bi-chat-left'
            icon='two-credit-cart'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Ev User</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='Evusers/EV'
            title='EV USERS'
            fontIcon='bi-chat-left'
            icon='car-2'
          >
            <SidebarMenuItem to='/Evusers/EV/UserList' title='User List' icon='people' />
            <SidebarMenuItem to='/Evusers/EV/Wallet' title='Wallet ' icon='credit-cart' />
            <SidebarMenuItem
              to='/Evusers/EV/Transactions'
              title='Transactions'
              icon='chart-simple-3'
            />
            <SidebarMenuItem
              to='/Evusers/EV/walletTransactions'
              title='Wallet Transactions'
              icon='cheque'
            />
            <SidebarMenuItem to='/Evusers/EV/RfidTags' title='Rfid Tags' icon='tag' />
            <SidebarMenuItem
              to='/Evusers/EV/ReferAFriend'
              title='Refer A Friend'
              icon='user-edit'
            />
          </SidebarMenuItemWithSub>

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Employee Management
              </span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='Employees/data'
            title='Employee Management'
            fontIcon='bi-chat-left'
            icon='profile-user'
          >
            {/* <SidebarMenuItem to='/Employees' icon='share' title='Employees' fontIcon='bi-layers' />
            <SidebarMenuItem to='#' title='Role Creation' icon='fasten' fontIcon='bi-layers' /> */}
            <SidebarMenuItem
              to='/UserManagment/User/Roles'
              icon='abstract-14'
              title='Tasks'
              fontIcon='bi-layers'
            />
            <SidebarMenuItem
              to='/UserManagment/User/Groups'
              icon='focus'
              title='Roles'
              fontIcon='bi-layers'
            />
          </SidebarMenuItemWithSub>
          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Financial Management
              </span>
            </div>
          </div> */}
          {/* <SidebarMenuItemWithSub
            to='FinancialManagement'
            icon='bill'
            title='Financial Management'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem
              to='FinancialManagement/revenue'
              title='Total Revenues'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='FinancialManagement/report'
              title='Revenue Reports'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub> */}

          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
            </div>
          </div> */}

          {/* <SidebarMenuItemWithSub
            to='/UserManagment/User'
            title='User Management'
            fontIcon='bi-chat-left'
            icon='user'
          > */}
          <SidebarMenuItem
            to='/UserManagment/User/CmsUsers'
            title='User Management'
            icon='people'
          />
          {/* </SidebarMenuItemWithSub> */}

          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Amenity Management
              </span>
            </div>
          </div> */}
          {/* <SidebarMenuItem
            to='/amenity/'
            title='Amenity Management'
            fontIcon='bi-chat-left'
            icon='user'
          > */}
          <SidebarMenuItem to='/amenity/amenities' title='Amenity Management' icon='category' />
          <SidebarMenuItem to='/transactions' title="Transaction's " icon='abstract-45' />
          <SidebarMenuItem to='/total-connectors' title="Connector's" icon='abstract-45' />
          {/* </SidebarMenuItem> */}

          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Support</span>
            </div>
          </div>

          <SidebarMenuItemWithSub
            to='Support/data'
            title='Support '
            fontIcon='bi-chat-left'
            icon='support-24'
          >
            <SidebarMenuItemWithSub
              to='support/user'
              title='User'
              fontIcon='bi-chat-left'
              icon='user'
            >
              <SidebarMenuItem title='Tickets List' to='/Servicerequest' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='support/employees'
              title='Employee'
              fontIcon='bi-chat-left'
              icon='user'
            >
              <SidebarMenuItem title='Tickets List' to='/Servicerequest' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub> */}
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
