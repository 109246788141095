import * as Yup from 'yup'

export const newRfidValidation = Yup.object({
  rfIdTag: Yup.string().required('Please enter your rfidTag'),
  userId: Yup.string().required('Please enter your user Id'),
  userName: Yup.string().required('Please enter your user name'),
  expiryDate: Yup.date().required('Please choose your expiry date'),
  masterRfidTag: Yup.string().required('Please select your master Rfid tag'),
  comments: Yup.string().required('Please leave your comments'),
})
