/* eslint-disable */

import React from 'react'
import {useParams} from 'react-router-dom'
import { ConsolidateDATA } from '../js/RFIData'

export interface onsolidateCreateUpdate {
  id: String
  username: String
  email: String
  mobile: String
  role: String
  activestatus: String
  tenantid: String
  signUp: String
  language: String
  PAN: String
  GST: String
  state: String
  vehicle: String
}

export const ConsolidateCreate1 = {
  heading: 'Created By RFID Details',
  username: 'User Name',
  email: 'Email',
  mobile: 'Mobile',
  role: 'Role',
  activestatus: 'Active Status',
  tenantid: 'Tenant Id',
  signUp: 'SignUp',
  language: 'Language',
  PAN: 'PAN Number',
  GST: 'GST Number',
  state: 'State',
  vehicle: 'Vehicle',
}

const ConsolidateCreate = () => {
  let {pointId} = useParams()

  // var pid: number = parseInt(pointId);
  var result = ConsolidateDATA.find(function (e) {
    return e.id == pointId
  })
  return (
    <div>
      {/* TariffHeader:Details:: Starts */}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{ConsolidateCreate1.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.username}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{result?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {ConsolidateCreate1.mobile}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{result?.mobile}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.role}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {result?.role}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {ConsolidateCreate1.activestatus}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.activestatus}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.tenantid}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.tenantid}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.signUp}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.signUp}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.language}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.language}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.PAN}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.PAN}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.GST}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.GST}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.state}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.state}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{ConsolidateCreate1.vehicle}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.vehicle}</span>
            </div>
          </div>
        </div>
      </div>
      {/* TariffHeader:Details:: Ends */}
    </div>
  )
}

export default ConsolidateCreate
