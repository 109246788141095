interface VPItem {
    time: string
    startTime: string
    endTime: string
    price: number
  }

  interface DataItem {
    time: string
    startTime: string
    endTime: string
    price: number
    days: string[]
  }

  interface GroupedItem {
    vp: VPItem[]
    days: string[]
  }

  function groupByDaysArray(variablePrices: DataItem[]): GroupedItem[] {
    const groupedItemsMap: {[key: string]: DataItem[]} = {}

    variablePrices.forEach((item) => {
      const daysKey = item.days.sort().join('-')
      if (!groupedItemsMap[daysKey]) {
        groupedItemsMap[daysKey] = []
      }
      groupedItemsMap[daysKey].push(item)
    })

    const groupedItems: GroupedItem[] = Object.values(groupedItemsMap).map((items) => {
      const vpItems: VPItem[] = items.map((item) => ({
        time: item.time,
        startTime: item.startTime,
        endTime: item.endTime,
        price: item.price,
      }))
      return {vp: vpItems, days: items[0].days}
    })

    return groupedItems
  }



  interface InputElement {
    vp: {
      time: string
      startTime: string
      endTime: string
      price: number
    }[]
    days: string
  }

  interface UpdatedVp {
    time: string
    startTime: string
    endTime: string
    price: number
    days: string
  }

  const generateNewVariablePrices = (input: InputElement[]): UpdatedVp[] => {
    let updatedArray: UpdatedVp[] = []
    input.forEach((element, index) => {
      element.vp.forEach((item, i) => {
        let Vp: UpdatedVp = {
          time: item.time,
          startTime: i !== 0 ? input[index].vp[i - 1].endTime : '00:00',
          endTime: item.endTime,
          price: item.price,
          days: element.days,
        }
        updatedArray.push(Vp)
      })
    })
    return updatedArray
  }




  
  export {groupByDaysArray,generateNewVariablePrices}