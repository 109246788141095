export const servicedata = [
  {
    id: '1',
    ticketid: '#TICT3156',
    email: 'ManiKumari354@gmail.com',
    query: 'Other',
    description: 'ACTIVE',
    date: '2023-09-12',
    time: '08:09 PM',
  },
  
]
