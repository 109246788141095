/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {addNewUserValidation} from '../pages/js/AddNewUserValidation'
import axios from '../API/Api_Instance'
import toast, {Toaster} from 'react-hot-toast'
import token from './Token'
import {evUserInitialValues} from './Helpers/AllInitialValues'
import {getRequest, postRequest, putRequest} from './Helpers/Requests'
const EditUserDetails = () => {
  const {pointId} = useParams()
  const [initialValues, setInitialValues] = useState(evUserInitialValues)

  const navigate = useNavigate()

  useEffect(() => {
    GetUserObject()
  }, [pointId])

  const GetUserObject = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/ev/users/${pointId}`)
      if (res && res.data) {
        setInitialValues(res.data.data.EvUser)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      const res = await putRequest(`${process.env.REACT_APP_API_URL}/ev/users/${pointId}`, values)
      if (res && res.data) {
        toast.success('Updated SuccessFully')
        setTimeout(() => {
          navigate('/Evusers/EV/UserList')
        }, 1000)
      }
    } catch (error: any) {
      toast.error('Invalid Data Check The Data')
      console.log(error.message)
    }
  }

  return (
    <div className='mt-n5'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title'>EDIT NEW USER</h3>
        </div>
        <div className='card-body mt-5 mb-10'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={addNewUserValidation}
            onSubmit={handleSubmit}
          >
            {({errors}) => (
              <Form>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='First Name'
                        name='firstName'
                      />
                      <span className='text-danger fs-5'>
                        {errors.firstName && errors.firstName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Last Name'
                        name='lastName'
                      />
                      <span className='text-danger fs-5'>{errors.lastName && errors.lastName}</span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='email'
                        className='form-control'
                        placeholder='Email or Phone'
                        name='contact'
                        value={initialValues.mobileNumber || initialValues.email}
                      />
                      <span className='text-danger fs-5'>{errors.email && errors.email}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='country'
                      >
                        <option value=''>Select Country</option>
                        <option value='United States'>United States</option>
                        <option value='India'>India</option>
                        <option value='Nepal'>Nepal</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.country && errors.country}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='State'
                        name='state'
                      />
                      <span className='text-danger fs-5'>{errors.state && errors.state}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field type='text' className='form-control' placeholder='City' name='city' />
                      <span className='text-danger fs-5'>{errors.city && errors.city}</span>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        name='pincode'
                      />
                      <span className='text-danger fs-5'>{errors.pincode && errors.pincode}</span>
                    </div>
                  </div>
                </div>
                <div className='row'></div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='GST number'
                        name='gstNum'
                      />
                      <span className='text-danger fs-5'>{errors.gstNum && errors.gstNum}</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className='col-md-9'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Email for Invoice Purpose'
                            name='email'
                          />
                          <span className='text-danger fs-5'>
                            {errors.email && errors.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-warning'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    {/* <PopUpSumbit text={'Submit'} /> */}
                    <button type='submit' className='btn btn-light-success'>
                      Update
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-info ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </div>
                          </div>
                          <div className='modal-body'>
                            <p>Your data is not saved, Are you sure to exit ?</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                            >
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/Evusers/EV/UserList')}
                              className='btn btn-light-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditUserDetails
