import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Form, Field, Formik} from 'formik'
import {newRfidValidation} from '../js/NewRfidValidation'
import axios from '../../API/Api_Instance'
import toast,{Toaster} from 'react-hot-toast'

interface UserDetails {
  firstName: String
}

const AddConsolidateData = () => {
  const [UserList, setUserList] = useState([])


  
  useEffect(() => {
    axios
      .get(`/UserList`)
      .then((res) => {
        setUserList(res.data)
      })
      .catch((err) => console.log(err))
  }, [UserList])

  const navigate = useNavigate()
  const handleSubmit = (values) => {
    axios
      .post('ConsolidateData', values)
      .then((res) => {
        toast.success('RFID Created SucessFully ✅')
        setTimeout(() => {
          navigate('/Evusers/EV/RfidTags')
        }, 1000)
      })
      .catch((err) => {
        toast.error('Invalid Data Check Data ❌')
        console.log(err)
      })
  }
  const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format

  const initialValues = {
    rfIdTag: '',
    userId: '',
    userName: '',
    expiryDate: '',
    masterRfidTag: '',
    comments: '',
  }

  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title text-black'>ADD CONSOLIDATE USER</h3>
        </div>
        <div className='card-body'>
          <div className='mt-5'>
            <Formik
              initialValues={initialValues}
              validationSchema={newRfidValidation}
              onSubmit={handleSubmit}
            >
              {({errors}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          className='form-control'
                          placeholder='RFID tag'
                          name='rfidTag'
                        />
                        <span className='text-danger fs-5'>{errors.rfIdTag && errors.rfIdTag}</span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          className='form-control'
                          placeholder='User ID'
                          name='userId'
                        />
                        <span className='text-danger fs-5'>{errors.userId && errors.userId}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='userName'
                        >
                          <option>Select User</option>
                          {UserList.map((item: UserDetails) => (
                            <option value={item.firstName.toString()}>{item.firstName}</option>
                          ))}
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.userName && errors.userName}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        {/* <label className='fw-medium ms-1 pt-1'>Expiry Date</label> */}
                        <Field type='date' className='form-control' min={today} name='expiryDate' />
                        <br />
                        <span className='text-danger fs-5'>{errors.expiryDate && errors.expiryDate}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='masterRfidTag'
                        >
                          <option>Select master Rfid tag</option>
                          <option value='ghjd-(admin)'>ghjd-(admin)</option>
                        </Field>
                        <span className='text-danger fs-5'>
                          {errors.masterRfidTag && errors.masterRfidTag}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          as='textarea'
                          className='form-control'
                          placeholder='Comments'
                          rows={1}
                          name='comments'
                        ></Field>
                        <span className='text-danger fs-5'>
                          {errors.comments && errors.comments}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='mb-10'>
                      <button type='reset' className='btn btn-light-danger'>
                        Reset
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      {/* <PopUpSumbit text={'Submit'} /> */}
                      <button type='submit' className='btn btn-light-success'>
                        Submit
                      </button>
                    </div>
                    <div className='mb-10 ms-2'>
                      <Link to='/Evusers/EV/RfidTags'>
                        <button type='button' className='btn btn-light-primary'>
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddConsolidateData
