import React from 'react'
import TabsSlide from './Components/Tabs'

const OperatorInformation = () => {
  return (
    <div>
      {/* Tabs Starts Here */}
      <TabsSlide />
      {/* Tabs Ends Here */}
    </div>
  )
}

export default OperatorInformation
