/* eslint-disable */

import {useEffect, useState} from 'react'

import {useParams} from 'react-router-dom'
import {getRequest, postRequest} from '../../../../app/pages/Helpers/Requests'
import {toAbsoluteUrl} from '../../../helpers'
import AmenitiesEditCpo from './AmenitiesEditCpo'
import toast from 'react-hot-toast'

const CPOStationAmenities = ({amenities, edit, stationId}) => {
  console.log('getting here')
  console.log(amenities)

  const [ComplimentaryAmenities, setComplimentaryAmenities] = useState([])
  const [NearByAmenities, setNearByAmenities] = useState([])
  const [updatedAmenities, setUpdatedAmenities] = useState<any>([])
  const [oldAmenities, setOldAmenities] = useState(amenities)
  const [enableEdit, setEnableEdit] = useState(edit)

  const filterAmenities = async () => {
    try {
      let amen = amenities

      let comp = amen.filter((e) => {
        if (e.type === 'Complimentary Services') return e
      })

      let near = amen.filter((e) => {
        if (e.type === 'Near By Services') return e
      })
      setComplimentaryAmenities(comp)
      setNearByAmenities(near)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAmenities = (newId) => {
    try {
      const existsInUpdated = updatedAmenities.includes(newId)
      const existsInOld = oldAmenities.some((item) => item.id === newId)
      if (existsInUpdated) {
        setUpdatedAmenities((prev) => prev.filter((id) => id !== newId))
      }

      if (existsInOld) {
        setOldAmenities((prev) => prev.filter((item) => item.id !== newId))
      }

      if (!existsInOld && !existsInUpdated) {
        const newObject = {
          id: newId,
          name: '',
        }

        setOldAmenities((prev) => [...prev, newObject])
        setUpdatedAmenities((prev) => [...prev, newId])
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  const postUpdateAmenities = async () => {
    console.log('updating')
    try {
      const res = await postRequest('/amenity-change/' + stationId, {updatedAmenities})
      // const res=await axios.post("http://localhost:3000/v1/amenity-change/"+stationId,{values:"xyx"})
      console.log(res)
      if (res?.status === 200) {
        toast.success('Successfully posted the request')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    filterAmenities()
    setUpdatedAmenities(amenities.map((item: any) => item.id))
  }, [amenities])

  useEffect(() => {
    setOldAmenities(amenities)
  }, [amenities])

  useEffect(() => {
    setEnableEdit(edit)
  }, [edit])

  return (
    <>
      {!enableEdit ? (
        <>
          <div className='card card-custom'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0'>
                <div className='symbol symbol-50px w-50px bg-light me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/complementary-services-01.svg')}
                    alt='Metronic'
                    className='p-3'
                  />
                </div>

                <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                  Complimentary Services
                </a>
              </div>

              <div className='card-toolbar m-0'></div>
            </div>
            <div className='card-body'>
              <div className='row'>
                {ComplimentaryAmenities?.map((item: any) => {
                  return (
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm'>
                          <label
                            className='form-check-label d-flex align-items-center fs-5  text-dark'
                            htmlFor='Restrooms'
                          >
                            <i className={`fa-solid ${item.icon} fs-3 fw-bold text-dark me-3`}></i>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='card card-custom mt-15'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0'>
                <div className='symbol symbol-50px w-50px bg-light me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/NearBy.svg')}
                    alt='Metronic'
                    className='p-3'
                  />
                </div>

                <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                  NearBy Services
                </a>
              </div>

              <div className='card-toolbar m-0'></div>
            </div>
            <div className='card-body'>
              <div className='row'>
                {NearByAmenities.map((item: any) => {
                  return (
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm'>
                          <label
                            className='form-check-label d-flex align-items-center fs-5  text-dark'
                            htmlFor='Restrooms'
                          >
                            <i className={`fa-solid ${item.icon} fs-3 fw-bold text-dark me-3`}></i>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <AmenitiesEditCpo
            UpdateAmenities={updateAmenities}
            Amenities={oldAmenities.map((item: any) => item.id)}
          />
          <div className='d-flex justify-content-end'>
            <button className='btn btn-sm btn-success' onClick={postUpdateAmenities}>
              Save Changes
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default CPOStationAmenities
