/* eslint-disable */

import React from 'react'
import {TableWidgetCD1} from '../../../_metronic/partials/widgets/tables/TablesWidgetCD1'
import Top from '../../modules/Chargestations/Charge/components/Top'
import {Button} from 'react-bootstrap'
import ChangeAvailability from './../../../_metronic/partials/modals/OCPP/ChangeAvailability';
import ChangeConfiguration from '../../../_metronic/partials/modals/OCPP/ChangeConfiguration';
import ConfirmModal from '../../modules/Confirmation/ConfirmModal';
import { Console } from 'console';
import toast from 'react-hot-toast';
import DataTransfer from '../../../_metronic/partials/modals/OCPP/DataTransfer';
import GetConfiguration from '../../../_metronic/partials/modals/OCPP/GetConfiguration';
import RemoteStartTransaction from '../../../_metronic/partials/modals/OCPP/RemoteStartTransaction';
import RemoteStopTransaction from '../../../_metronic/partials/modals/OCPP/RemoteStopTransaction';
import UnlockConnector from '../../../_metronic/partials/modals/OCPP/UnlockConnector';
import ResetDevice from '../../../_metronic/partials/modals/OCPP/ResetDevice';
import UpdateFirmware from '../../../_metronic/partials/modals/OCPP/UpdateFirmware';
import GetDiagnostics from '../../../_metronic/partials/modals/OCPP/GetDiagnostics';
import FetchLocalList from '../../../_metronic/partials/modals/OCPP/FetchLocalList';
import SendLocalList from '../../../_metronic/partials/modals/OCPP/SendLocalList';
import ReserveNow from '../../../_metronic/partials/modals/OCPP/ReserveNow';
import CancelReservation from '../../../_metronic/partials/modals/OCPP/CancelReservation';
import TriggerMessage from '../../../_metronic/partials/modals/OCPP/TriggerMessage';
import CustomObject from '../../../_metronic/partials/modals/OCPP/CustomObject';

import { getRequest, postRequest } from '../Helpers/Requests';
import TestDataTransfer from '../../../_metronic/partials/modals/OCPP/TestDataTransfer';
const DeviceManagement = ({stationId,id,connectors}) => {
  return (
    <div className='mt-10'>
      <div className='card-body mt-5'>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab4'>
              Core
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab3'>
              Security
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab5'>
              Firmware Management
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab6'>
              Local Auth List Management
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab7'>
              Reservation
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab8'>
              Remote Trigger
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab9'>
              Smart Charging
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab4' role='tabpanel'>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'  data-bs-toggle="modal" data-bs-target="#change_availability">
                    <div className='d-flex align-items-center'>
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Change
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Availability</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'  data-bs-toggle="modal" data-bs-target="#change_configuration">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Change
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Configuration</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'  data-bs-toggle="modal" data-bs-target="#confirm_modal">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Clear
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Cache</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#data_transfer">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Data
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Transfer</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#get_configuration">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Get
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Configuration</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#remote_startTransaction">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Remote Start
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Transaction</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#remote_stopTransaction">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Remote Stop
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Transaction</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#unlock_connector">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Unlock
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Connector</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#reset_device">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Reset
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Device</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#custom_request">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Custom
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Request</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#test_data_transfer">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Test Data
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Transfer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab3' role='tabpanel'></div>
          <div className='tab-pane fade' id='kt_tab5' role='tabpanel'>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#update_firmware">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Update
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Firmware</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#get_diagnostics">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Get
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Diagnostics</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab6' role='tabpanel'>
          <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#fetch_localList">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Get Local List
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Version</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#send_localList">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Send
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Localist</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab7' role='tabpanel'>
          <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#reserve_now">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Reserve
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Now</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#cancel_reservation">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Reservation</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab8' role='tabpanel'>
          <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary' data-bs-toggle="modal" data-bs-target="#trigger_message">
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Trigger
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Message</div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab9' role='tabpanel'>
          <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Set
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Charging Profile</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Clear
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Charging Profile</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer bg-secondary'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={60}
                        data-kt-countup-prefix='%'
                        data-kt-initialized={1}
                      >
                        Get
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-500'>Composite Schedule</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeAvailability stationId={stationId} connectors={connectors}/>
      <ChangeConfiguration stationId={stationId}/>
      <DataTransfer stationId={stationId} />
      <GetConfiguration stationId={stationId} />
      <RemoteStartTransaction stationId={stationId} connectors={connectors}/>
      <RemoteStopTransaction stationId={stationId} />
      <UnlockConnector stationId={stationId} connectors={connectors}/>
      <ResetDevice stationId={stationId} />
      <UpdateFirmware stationId={stationId} />
      <GetDiagnostics stationId={stationId} />
      <SendLocalList stationId={stationId} />
      <ReserveNow stationId={stationId} connectors={connectors}/>
      <CancelReservation stationId={stationId} />
      <TriggerMessage stationId={stationId} connectors={connectors}/>
      <CustomObject stationId={stationId} />
      <TestDataTransfer stationId={stationId} />
      <FetchLocalList text="Are You sure yo want to Fetch Local List" handleSubmit={async()=>{
        try {
          const res=await getRequest("/ocpp/remote/get-local-list-version/"+stationId);
          if(res && res.data){
            toast.success(res.data.message);
          }
        } catch (error) {
          console.log(error);
        }
      }} />
      <ConfirmModal text='Are You sure you want to Clear Cache ? ' handleSubmit={async()=>{
        try {
          const res=await getRequest("/ocpp/remote/clear-cache/"+stationId);
          if(res && res.data){
            toast.success(res.data.message);
          }
        } catch (error) {
          console.log(error);
        }
      }} />
    </div>
  )
}

export default DeviceManagement
