import React from 'react'
import CmsMap from './CmsMap'
import CmsGroup from './CmsGroup'

const CmsPermission = () => {
  return (
    <div>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 mt-10 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
            CMS ROLE MAPPING
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
            CMS GROUP MAPPING
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
          <CmsMap />
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <CmsGroup />
        </div>
      </div>
    </div>
  )
}

export default CmsPermission
