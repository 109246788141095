import React from 'react'
import UserDetailsHeader from './Components/UserDetailsHeader'

const UserSinglePage = () => {
  return (
    <div>
        <UserDetailsHeader />
    </div>
  )
}

export default UserSinglePage
