/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {UsersID} from '../js/Users'
import {KTIcon} from '../../../_metronic/helpers'
import axios from '../../API/Api_Instance'
import { TablesWidget32 } from '../../../_metronic/partials/widgets/tables/TablesWidget32'
import { TablesWidget33 } from '../../../_metronic/partials/widgets/tables/TablesWidget33'
import { UserTransactions } from '../../../_metronic/partials/widgets/tables/UserTransactions'
import { UserChargingTransactions } from '../../../_metronic/partials/widgets/tables/UserChargingTransactions'
import { WalletTransactionsOfUser } from '../../../_metronic/partials/widgets/tables/WalletTransactionsOfUser'

export interface UserDetails {
  id: any
  firstName: String
  email: String
  mobileNum: String
  role: String
  activestatus: String
  tenantid: String
  signUp: String
  language: String
  PAN: String
  gstNum: String
  state: String
  vehicle: String
}

export const Users = {
  heading: 'Users Details',
  username: 'User Name',
  email: 'Email',
  mobile: 'Mobile',
  role: 'Role',
  activestatus: 'Active Status',
  tenantid: 'Tenant Id',
  signUp: 'SignUp',
  language: 'Language',
  PAN: 'PAN Number',
  GST: 'GST Number',
  state: 'State',
  vehicle: 'Vehicle',
}

const UserDetailsHeader = () => {
  let {pointId} = useParams()
  const [DetailsUser, setDetailsUser] = useState<any>(null)

  interface AuthData {
    token: string
  }
  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ev/users/${pointId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.EvUser)
        setDetailsUser(res.data.data.EvUser)
      })
  }, [])

  return (
    <div>
      <div className='text-end'>
        <Link
          to='/Evusers/EV/UserList'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title'>{Users.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.firstName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{DetailsUser?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Users.mobile}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{DetailsUser?.mobileNumber}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Users.activestatus}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              {
                DetailsUser?.status==="Active"?<span className='badge badge-light-success'>{DetailsUser?.status}</span>:<span className='badge badge-light-danger'>{DetailsUser?.status}</span>
              }
              
            </div>
          </div>
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.tenantid}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                 Tenant Id ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.signUp}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                Sign Up ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.language}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                 Language ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.PAN}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
          PAN NUMBER ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.GST}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.gstNum}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.state}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.state}</span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.vehicle}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.vehicle}</span>
            </div>
          </div> */}
        </div>
      </div>

      <div className='mt-10'>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-4'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              Payment Transactions
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              Charging Transactions
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_6'>
              Wallet Transactions
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <UserTransactions userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>

            <UserChargingTransactions userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          
          <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
            <WalletTransactionsOfUser userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default UserDetailsHeader
