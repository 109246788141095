/* eslint-disable */
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import QRScanner from '../../../../app/UI/QRScanner'
import toast, {Toaster} from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import {AllChargeStationTableHeadings} from '../../../../app/pages/Helpers/TableHeadings'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'

const TableWidget98 = ({className, stations}) => {
  console.log(stations)
  const [Stations, setStations] = useState([])
  const [value, setValues] = useState<any>(null)
  const [seletedId, setSelectedId] = useState<any>()

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/stations/${seletedId}`)
      console.log(res, res?.data)
      toast.success('Deleted SuccessFully')
    } catch (error) {
      console.log(error)
      // toast.error(error)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.stationame}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.chargebox}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.type}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.connectors}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.port_type}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.location}</th>
                <th className='min-w-200px'>{AllChargeStationTableHeadings.status}</th>
              </tr>
            </thead>
            <tbody>
              {stations.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <a className='text-dark fw-bold d-block mb-1 fs-6'>{item.stationName}</a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item.stationId}</a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item.charge_type}</a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Charge</span>
                    </td>
                    <td>
                      <span className='badge badge-light-info fs-6 fw-semibold'>
                        {item.connectors.length}
                      </span>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item.connectors[0].port_type}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Ports</span>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item.location.city}</a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>India</span>
                    </td>
                    <td>
                      <span
                        className={
                          item.status == 'Active'
                            ? 'badge fs-7 fw-semibold badge-light-success'
                            : 'badge fs-7 fw-semibold badge-light-danger'
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <ConfirmModal text='Do You Want To Delete this Station?' handleSubmit={DeleteHandler} />
      </div>
    </div>
  )
}

export {TableWidget98}
