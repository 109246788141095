import React from 'react'

import {TablesWidget14} from '../../_metronic/partials/widgets/tables/TablesWidget14'

const Chargepoint = () => {
  return (
    <>
      <TablesWidget14 className='card-xxl-stretch mb-5 mb-xl-8' />
    </>
  )
}

export default Chargepoint
