/* eslint-disable */

import React, {useEffect, useState} from 'react'
import RolesDetails from './RolesDetails'
import UserMapping from './UserMapping'
import {KTIcon} from '../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
// import { Header } from '../js/RolesHeader'
import axios from 'axios'

const RolesSingleData = () => {
  let {pointId} = useParams()

  let [permission, setPermission] = useState<any>([])

  interface AuthData {
    token: string
  }
  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}
  const getAllPermissions = async () => {
    try {
      let {data} = await axios.get(`${process.env.REACT_APP_API_URL}/permissions/${pointId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setPermission(data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllPermissions()
  }, [])
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>ROLES - {permission.name}</h3>
        </div>
        <div className='card-body mt-5'>
          <div className='d-flex justify-content-end'>
            <Link
              to='/UserManagment/User/Roles'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTIcon iconName='arrow-left' className='fs-5' />
            </Link>
          </div>

          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-4'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                Roles Details
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                User Mapping
              </a>
            </li>
          </ul>

          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
              <RolesDetails role={permission} />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <UserMapping />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolesSingleData
