/* eslint-disable */

import React, {useEffect, useState} from 'react'
import GroupDetails from './Components/GroupDetails'
import GroupUserMapping from './Components/GroupUserMapping'
import GroupRoleMapping from './Components/GroupRoleMapping'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../src/_metronic/helpers'
import axios from '../API/Api_Instance'

interface Group {
  id: number
  groupname: string
}

const GroupEditPage = () => {
  let {pointId} = useParams()
  const [groupData, setGroupData] = useState<Group | null>(null)

  useEffect(() => {
    axios
      .get(`GroupCMS`)
      .then((res) => {
        const result = res.data.find((e: Group) => e.id === Number(pointId))
        setGroupData(result || null)
      })
      .catch((err) => console.log(err))
  }, [pointId])

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>GROUPS-{groupData?.groupname}</h3>
        </div>

        <div className='card-body mt-5'>
          <div className='d-flex justify-content-end'>
            <Link
              to='/UserManagment/User/Roles'
              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
            >
              <KTIcon iconName='arrow-left' className='fs-5' />
            </Link>
          </div>
          {/* Tabs::Row::Starts*/}
          <ul className='nav nav-tabs nav-line-tabs mb-10 fs-5'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                GROUP DETAILS
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                USER MAPPING
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                ROLE MAPPING
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {/* <GroupDetails /> */}
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              <GroupUserMapping />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
              <GroupRoleMapping />
            </div>
          </div>
          {/* Tabs::Row::Ends*/}
        </div>
      </div>
    </div>
  )
}

export default GroupEditPage
