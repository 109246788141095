/* eslint-disable */

import React, {useEffect, useState} from 'react'
import GeneralDetail from './GeneralDetail'
import Amenities from './Amenities'
import {Formik, Form} from 'formik'
import {newChargeStationValidation} from '../js/NewChargeStationValidation'
import toast, {Toaster} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {ChargeStationInitialValues} from '../Helpers/AllInitialValues'
import {getRequest, postRequest} from '../Helpers/Requests'
import api_instance from '../../API/Api_Instance'
import * as Yup from 'yup'

const ChargeStationValidationSchema = Yup.object().shape({
  operatorId: Yup.string().required('Operator ID is required'),
  stationName: Yup.string().required('Station Name is required'),
  stationId: Yup.string().required('Station ID is required'),
  communication: Yup.string().required('Communication type is required'),
  charge_type: Yup.string().required('Charge type is required'),
  total_power_output: Yup.number()
    .required('Total power output is required')
    .min(0, 'Total power output must be greater than or equal to 0'),
  voltage_rating: Yup.string().required('Voltage rating is required'),
  status: Yup.string().required('Status is required'),
  location: Yup.object().shape({
    latitude: Yup.string().required('Latitude is required'),
    longitude: Yup.string().required('Longitude is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    pincode: Yup.string().required('Pincode is required'),
  }),
})

const AddStationDetails = () => {
  const [openAmenities, setOpenAmenities] = useState(false)
  const [UpdateAmenities, setUpdateAmenities] = useState<any>([])
  const [image, setImage] = useState<any>()
  const navigate = useNavigate()

  function AmenitiesHandler(item) {
    if (UpdateAmenities.includes(item)) {
      let Filtered = UpdateAmenities.filter((e) => {
        if (item !== e) return e
      })
      setUpdateAmenities(Filtered)
    } else {
      setUpdateAmenities([...UpdateAmenities, item])
    }
  }

  const handleSubmit = async (values: any, formikBag: any) => {
    try {
      let UpdateValues = {...values, amenities: UpdateAmenities}
      const formData = new FormData()
      formData.append('charge_type', values.charge_type)
      formData.append('communication', values.communication)
      formData.append('location[address]', values.location.address)
      formData.append('location[country]', values.location.country)
      formData.append('location[latitude]', values.location.latitude)
      formData.append('location[longitude]', values.location.longitude)
      formData.append('location[pincode]', values.location.pincode)
      formData.append('location[state]', values.location.state)
      formData.append('location[city]', values.location.city)
      formData.append('operatorId', values.operatorId)
      formData.append('stationId', values.stationId)
      formData.append('stationName', values.stationName)
      formData.append('status', values.status)
      formData.append('total_power_output', values.total_power_output)
      formData.append('voltage_rating', values.voltage_rating)

      values.connectors.forEach((connector: any, index: any) => {
        formData.append(`connectors[${index}][connector_ID]`, connector.connector_ID)
        formData.append(`connectors[${index}][port_type]`, connector.port_type)
        formData.append(`connectors[${index}][power_output]`, connector.power_output)
        formData.append(`connectors[${index}][tariffId]`, connector.tariffId)
      })

      formData.append('image', values.image)
      UpdateAmenities.forEach((amenity: any, index: any) => {
        formData.append(`amenities[${index}]`, amenity)
      })
      formData.forEach((value, key) => {
        console.log(key, value)
      })
      delete UpdateValues.image
      const res = await api_instance.post(`/stations/AddChargeStation`, formData)
      if (res && res.data) {
        toast.success('Charge Station Created Successfully ✅')
        setTimeout(() => {
          formikBag.resetForm()
          navigate('/Chargestations/Charge/Stations')
        }, 1000)
      }
    } catch (error) {
      toast.error('Invalid Data Check Data ❌')
      console.error('Error:', error)
    }
  }

  return (
    <div className='mt-0'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD CHARGE STATION</h3>
        </div>
        <div className='card-body mt-5'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-5'>
            <li className='nav-item'>
              <a
                className={`nav-link ${!openAmenities && 'active'}`}
                onClick={() => setOpenAmenities(false)}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
              >
                GENERAL DETAILS
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${openAmenities && 'active'}`}
                onClick={() => setOpenAmenities(true)}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
              >
                AMENITIES
              </a>
            </li>
          </ul>
          <Formik
            initialValues={ChargeStationInitialValues}
            // validationSchema={ChargeStationValidationSchema}
            onSubmit={handleSubmit}
          >
            {({errors, values, setFieldValue, touched}) => (
              <Form>
                <div className='mt-15'>
                  <div className={`${openAmenities ? 'd-none' : 'd-block'}`}>
                    <GeneralDetail
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                    <div className='d-flex justify-content-end'>
                      <div className='mb-10'>
                        <button
                          type='button'
                          className='btn btn-light-success'
                          onClick={() => setOpenAmenities(true)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${openAmenities ? 'd-block' : 'd-none'}`}>
                    <Amenities UpdateAmenities={AmenitiesHandler} Amenities={UpdateAmenities} />
                    <div className='d-flex justify-content-end'>
                      <div className='mb-10 me-5'>
                        <button
                          type='button'
                          onClick={() => setOpenAmenities(false)}
                          className='btn btn-light-danger'
                        >
                          Back
                        </button>
                      </div>
                      <div className='mb-10 me-5'>
                        <button type='submit' className='btn btn-light-success'>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddStationDetails
