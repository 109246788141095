/* eslint-disable */

import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {Fdetails} from '../js/TDetails'
import { KTIcon } from '../../../_metronic/helpers'
export interface traiffD {
  id: String
  username: String
  email: String
  mobile: String
  role: String
  activestatus: String
  tenantid: String
  signUp: String
  language: String
  PAN: String
  GST: String
  state: String
  vehicle: String
}

export const TD = {
  heading: 'Failed Transaction Single Details',
  username: 'User Name',
  email: 'Email',
  mobile: 'Mobile',
  role: 'Role',
  activestatus: 'Active Status',
  tenantid: 'Tenant Id',
  signUp: 'SignUp',
  language: 'Language',
  PAN: 'PAN Number',
  GST: 'GST Number',
  state: 'State',
  vehicle: 'Vehicle',
}

const FailedDetails = () => {
  let {pointId} = useParams()

  // var pid: number = parseInt(pointId);
  var result = Fdetails.find(function (e) {
    return e.id == pointId
  })
  return (
    <div>
      {/* TransactionDetails-2:Details:: Starts */}
      <div className='text-end'>
        <Link to='/Evusers/EV/Transactions' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{TD.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.username}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{result?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {TD.mobile}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{result?.mobile}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.role}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {result?.role}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {TD.activestatus}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.activestatus}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.tenantid}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.tenantid}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.signUp}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.signUp}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.language}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.language}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.PAN}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.PAN}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.GST}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.GST}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.state}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.state}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{TD.vehicle}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{result?.vehicle}</span>
            </div>
          </div>
        </div>
      </div>
      {/* TransactionDetails-2:Details:: Ends */}
    </div>
  )
}

export default FailedDetails
