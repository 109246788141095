import React from 'react'
import ServiceReqD from './Components/ServiceReqD'

const ServiceReqSingle = () => {
  return (
    <div>
      <ServiceReqD />
    </div>
  )
}

export default ServiceReqSingle
