/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {CompleteList} from '../../../../app/pages/js/TransactionsEv'
import {Link} from 'react-router-dom'
import Top from '../../../../app/modules/Chargestations/Charge/components/Top'
import {getRequest} from '../../../../app/pages/Helpers/Requests'

type Props = {
  className: string
  transactions: any
}

export interface CompleteUpdate {
  id: any
  username: String
  userid: String
  walletid: String
  balance: String
}

export const CompleteTable = {
  heading: 'Complete Transactions',
  subheading: 'Over 20 Transactions',
  username: 'Username',
  userid: 'User Id',
  walletid: 'Order Id',
  balance: 'Amount (Rs /-)',
  payment_type: 'Payment Type',
}

const UserTransactions = ({userId, className}) => {
  const [transactions, setTransactions] = useState([])

  const getTransactionsOfUser = async () => {
    try {
      const res = await getRequest('/transaction/user/' + userId)
      console.log(res)
      if (res?.status === 200) {
        setTransactions(res.data.data)
        
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTransactionsOfUser()
  }, [userId])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>{CompleteTable.username}</th>
                <th className='min-w-150px'>{CompleteTable.walletid}</th>
                <th className='min-w-170px'>{CompleteTable.balance}</th>
                <th className='min-w-170px'>{CompleteTable.payment_type}</th>
                <th className='min-w-210px'>Date, Time</th>
                <th className='min-w-110px'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {transactions?.map((item: any) => {
                return (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/avatars/300-17.jpg')} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link to={'#'} className='text-dark fw-bold text-hover-success fs-6'>
                            {item?.user?.firstName + ' ' + item?.user?.lastName}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            User
                          </span>
                        </div>
                      </div>
                    </td>
                    
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.orderId}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6 '>
                        {item?.amount}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item?.payment_type}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {new Date(item?.createdAt).toLocaleDateString() +
                          ',' +
                          new Date(item?.createdAt).toLocaleTimeString()}
                      </a>
                    </td>
                    <td className='text-center'>
                      {item?.status === 'Success' ? (
                        <span className='badge badge-light-success fs-7 fw-semibold'>
                          {item.status}
                        </span>
                      ) : item?.status === 'Pending' ? (
                        <span className='badge badge-light-warning fs-7 fw-semibold'>
                          {item.status}
                        </span>
                      ) : item?.status === 'Failed' ? (
                        <span className='badge badge-light-danger fs-7 fw-semibold'>
                          {item.status}
                        </span>
                      ) : (
                        <span className='badge badge-light-info fs-7 fw-semibold'>
                          {item.status}
                        </span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {UserTransactions}
