import React from 'react'
import { KTSVG } from '../../../helpers'


function FetchLocalList({handleSubmit, text}) {
  return (
    <div className='modal fade' tabIndex={-1} id='fetch_localList'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Confirm</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-10'>{text}</div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-light-success'
                data-bs-dismiss='modal'
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FetchLocalList
