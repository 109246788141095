import React from 'react'
import AddTraiff from './Components/AddTraiff'

const AddNewTariff = () => {
  return (
    <div>
      <AddTraiff />
    </div>
  )
}

export default AddNewTariff
