import React, {useEffect, useState} from 'react'
import {CardsWidget20, StatisticsWidget5} from '../../../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ChartsWidget9} from '../../../../../_metronic/partials/widgets/charts/ChartsWidget9'
import {TableWidgetDeep1} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep1'
import {loactindata} from '../../../../pages/js/locationdata'
import {TableWidgetDeep2} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep2'
import {ChargeDay} from '../../../../pages/js/Chargeperday'
import {TableWidgetDeep3} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep3'
import {Deepdive3} from '../../../../pages/js/ChargingChargeBox'
import {TableWidgetDeep4} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep4'
import {Deepdive4} from '../../../../pages/js/AvailableChargeBox'
import {TableWidgetDeep5} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep5'
import {Deepdive5} from '../../../../pages/js/FacultyCharge'
import {TableWidgetDeep6} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep6'
import {Deepdive6} from '../../../../pages/js/CustomerKife'
import {TableWidgetDeep7} from '../../../../../_metronic/partials/widgets/tables/TablesWidgetDeep7'
import {Deepdive7} from '../../../../pages/js/ChargersPerDay'
import {getRequest} from '../../../../pages/Helpers/Requests'
import {Header} from '../../../../pages/js/RolesHeader'
import {DashboardPaymentTransactionTable} from '../../../../../_metronic/partials/widgets/tables/DashboardPaymentTransactionTable'
import {DashboardTransactionTable} from '../../../../../_metronic/partials/widgets/tables/DashboardTransactionTable'
import {useAuth} from '../../../auth'

const Dashboard = () => {
  const [counts, setCounts] = useState({
    chargeBoxCount: 0,
    usersCount: 0,
    dcCount: 0,
    acCount: 0,
  })
  const [analytics, setAnalytics] = useState<any>()
  const [locations, setLocations] = useState([])
  const [sessions, setSessions] = useState([])
  const [requests, setRequests] = useState([])
  const [connectors, setConnectors] = useState([])
  const [amenityRequests, setAmenityRequests] = useState([])
  const [displayingOptions, setDisplayingOptions] = useState({
    totalUsers: true,
    todayRegistrations: true,
    totalTransactions: true,
    todayTransactions: true,
    totalStations: true,
    availableConnectors: true,
    engagedConnectors: true,
    faultedConnectors: true,
  })

  const {currentUser} = useAuth()
  const getDashboardAnalytics = async () => {
    try {
      const res = await getRequest('/dashboard/dashboard-analytics')
      console.log(res)
      if (res?.status === 200) {
        setAnalytics(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllRequests = async () => {
    try {
      const res = await getRequest('/tariff-change/')
      if (res?.status === 200) {
        setRequests(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllAmenityChangeRequests = async () => {
    try {
      const res = await getRequest('/amenity-change/')
      console.log(res)
      if (res?.status === 200) {
        const dataWithCounts = res.data.data.map((item) => {
          const countItem = res.data.counts.find((countItem) => item.stationId === countItem.id)

          return {
            ...item,
            newCount: countItem ? countItem.newCount : 0,
            oldCount: countItem ? countItem.oldCount : 0,
            delCount: countItem ? countItem.delCount : 0,
          }
        })
        setAmenityRequests(dataWithCounts)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getFaultedConnectors = async () => {
    try {
      const res = await getRequest('/dashboard/faulted-connectors')
      console.log(res)
      if (res?.status === 200) {
        console.log(res?.data.data)
        setConnectors(res?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFaultedConnectors()
  }, [])

  useEffect(() => {
    getDashboardAnalytics()
    getAllRequests()
    getAllAmenityChangeRequests()
  }, [])

  useEffect(() => {
    if (currentUser?.group === '2') {
      setDisplayingOptions({
        ...displayingOptions,
        totalStations: false,
        totalUsers: false,
        todayRegistrations: false,
        engagedConnectors: false,
      })
    }
  }, [currentUser])

  return (
    <>
      {/* begin::Row */}
      <h1 className='fw-bold' style={{fontSize: '32px'}}>
        Complete Analytics of Charging Stations
      </h1>
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <div className='row g-5 g-xl-10'>
          {currentUser?.group === '1' && connectors.length > 0 && (
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12' id='faulted'>
              <TableWidgetDeep7 TCD={Deepdive7} className='mb-5 mb-xl-8' connectors={connectors} />
            </div>
          )}
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        {displayingOptions.totalUsers && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8 text-white'
              svgIcon='people'
              color='info'
              iconColor='white'
              title={`${analytics?.totalUsersCount}`}
              description='Total Users Count'
            />
          </div>
        )}

        {displayingOptions.todayRegistrations && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='user'
              color='primary'
              iconColor='white'
              title={`${analytics?.todayRegisteredUsersCount}`}
              titleColor='white'
              description='Users Registered Today'
              descriptionColor='white'
            />
          </div>
        )}

        {displayingOptions.totalTransactions && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='chart-simple-3'
              color='dark'
              iconColor='white'
              title={`${analytics?.totalTransactionsCount}`}
              titleColor='white'
              description='Total Transactions'
              descriptionColor='white'
            />
          </div>
        )}

        {displayingOptions.todayTransactions && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='ranking'
              color='warning'
              iconColor='white'
              title={`${analytics?.todayTransactionsCount}`}
              titleColor='white'
              description="Today's Transactions"
              descriptionColor='white'
            />
          </div>
        )}

        {displayingOptions.totalStations && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='white'
              iconColor='dark'
              title={`${analytics?.totalStationsCount}`}
              description='Total Stations'
            />
          </div>
        )}

        {displayingOptions.availableConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='success'
              iconColor='white'
              title={`${analytics?.availableStationCount}`}
              titleColor='white'
              description='Available Connectors'
              descriptionColor='white'
            />
          </div>
        )}

        {displayingOptions.engagedConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='secondary'
              iconColor='dark'
              title={`${analytics?.engagedStationCount}`}
              titleColor='dark'
              description='Engaged Connectors'
              descriptionColor='dark'
            />
          </div>
        )}
        {displayingOptions.faultedConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='electricity'
              color='danger'
              iconColor='white'
              title={`${analytics?.faultedStationCount}`}
              titleColor='white'
              description='Faulted Connectors'
              descriptionColor='white'
            />
          </div>
        )}
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <DashboardTransactionTable
              transactions={analytics?.recentDeviceTransactions}
              className='mb-5 mb-xl-8'
            />
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <DashboardPaymentTransactionTable
              transactions={analytics?.recentPaymentTransactions}
              className='mb-5 mb-xl-8'
            />
          </div>
        </div>

        {requests.length > 0 && currentUser?.group === '1' && (
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <TableWidgetDeep1 requests={requests} className='mb-5 mb-xl-8' />
          </div>
        )}

        {amenityRequests.length > 0 && currentUser?.group === '1' && (
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <TableWidgetDeep2 sessions={amenityRequests} className='mb-5 mb-xl-8' />
          </div>
        )}

        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep3 chargingchargebox={Deepdive3} className='mb-5 mb-xl-8' />
        </div> */}

        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep4 available={Deepdive4} className='mb-5 mb-xl-8' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep5 faculty={Deepdive5} className='mb-5 mb-xl-8' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep6 customer={Deepdive6} className='mb-5 mb-xl-8' />
        </div>

         */}

        {/*begin:End */}
      </div>
      {/* end::Row */}
    </>
  )
}

export default Dashboard
