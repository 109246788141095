/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {TableOnes} from '../../../../app/pages/js/TableOne'
import {getRequest} from '../../../../app/pages/Helpers/Requests'

type Props = {
  className: String
  tableone: {}
  stationId: any
}
export interface tableoneUpdate {
  config: String
  value: String
  description: String
  access: String
}

export const Tableone = {
  config: 'Configuration Name',
  value: 'Configuration Value',
  description: 'Configuration Description',
  access: 'Accessibility',
}

const TableWidgetCD1: React.FC<Props> = ({className, stationId}) => {
  const [configuration, setConfiguration] = useState([])

  const getAllConfigurationKey = async () => {
    try {
      const res = await getRequest('/config/' + stationId)
      console.log(res)
      if (res?.status === 200) {
        setConfiguration(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stationId !== null && stationId !== '' && stationId !== undefined) {
      getAllConfigurationKey()
    }
  }, [stationId])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      {/* begin::Table container */}
      <div className='table-responsive'>
        <table className='table table-row-dashed table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              <th className='min-w-110px'>{Tableone.config}</th>
              <th className='max-w-110px'>{Tableone.value}</th>
              <th className='min-w-110px'>{Tableone.description}</th>
              <th className='min-w-110px'>{Tableone.access}</th>
            </tr>
          </thead>
          <tbody>
            {configuration.map((item: any) => {
              return (
                <tr>
                  <td>{item.configName}</td>
                  <td>{item.configValue}</td>
                  <td>{item.configDescription}</td>
                  <td>{item.accessibility}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export {TableWidgetCD1}
