import React from 'react'
import StationDetailsHeader from './Components/StationDetailsHeader'

const StationDetails = () => {
  return (
    <div>
      <StationDetailsHeader />
    </div>
  )
}

export default StationDetails
