/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {UsersID} from '../js/Users'
import {KTIcon} from '../../../_metronic/helpers'
import axios from '../../API/Api_Instance'

export interface UserDetails {
  id: any
  firstName: String
  email: String
  mobileNumber: String
  role: String
  activestatus: String
  tenantid: String
  signUp: String
  language: String
  PAN: String
  gstNum: String
  state: String
  vehicle: String
  userName: String
}

export const Users = {
  heading: 'Users Details',
  username: 'User Name',
  email: 'Email',
  mobile: 'Mobile',
  role: 'Role',
  activestatus: 'Active Status',
  tenantid: 'Tenant Id',
  signUp: 'SignUp',
  language: 'Language',
  PAN: 'PAN Number',
  GST: 'GST Number',
  state: 'State',
  vehicle: 'Vehicle',
}

const RfidUserD = () => {
  let {pointId} = useParams()
  const [DetailsUser, setDetailsUser] = useState<any>(null)

  interface AuthData {
    token: string
  }
  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/rfids/${pointId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.RfIdTag.user)
        setDetailsUser(res.data.data.RfIdTag.user)
      })
  }, [])

  return (
    <div>
      {/* TariffHeader:Details:: Starts */}
      <div className='text-end'>
        <Link
          to='/Evusers/EV/UserList'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{Users.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.firstName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{DetailsUser?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Users.mobile}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{DetailsUser?.mobileNumber}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.role}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {/* {DetailsUser?.role} */} ROLE ?
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Users.activestatus}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              {/* <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.activestatus}</span> */}
              <span className='badge badge-light-success'>Active ?</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.tenantid}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {DetailsUser?.tenantid} */} Tenant Id ?
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.signUp}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {DetailsUser?.signUp} */}Sign Up ?
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.language}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {DetailsUser?.language} */} Language ?
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.PAN}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {/* {DetailsUser?.PAN} */} PAN NUMBER ?
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.GST}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.gstNum}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.state}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.state}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.vehicle}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.vehicle}</span>
            </div>
          </div>
        </div>
      </div>
      {/* TariffHeader:Details:: Ends */}
    </div>
  )
}

export default RfidUserD
