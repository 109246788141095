import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast';
const validationSchema = Yup.object().shape({
  key: Yup.string().required('Configuration key is required'),
  value: Yup.string().required('Configuration Value is required'),
})

const keys = [
  "WebSocketPingInterval",
  "UnlockConnectorOnEVSideDisconnect",
  "TransactionMessageRetryInterval",
  "TransactionMessageAttempts",
  "SupportedFeatureProfiles",
  "superFastMode",
  "StopTxnSampledData",
  "StopTxnAlignedData",
  "StopTransactionOnInvalidId",
  "StopTransactionOnEVSideDisconnect",
  "stackSelectionEnabled",
  "SendLocalListMaxLength",
  "RFIDEnabled",
  "ResetRetries",
  "ReserveConnectorZeroSupported",
  "NumberOfConnectors",
  "MinimumStatusDuration",
  "MeterValuesSampledData",
  "MeterValueSampleInterval",
  "MeterValuesAlignedData",
  "maxPowerLimitInkW",
  "maxCurrentLimitInAmps",
  "MaxChargingProfilesInstalled",
  "LocalPreAuthorize",
  "LocalAuthorizeOffline",
  "LocalAuthListMaxLength",
  "LocalAuthListEnabled",
  "Identity",
  "HeartbeatInterval",
  "gracefulStopOnHardReset",
  "GetConfigurationMaxKeys",
  "forceOfflineMode",
  "ConnectorSwitch3to1PhaseSupported",
  "ConnectorPhaseRotation",
  "ConnectionTimeOut",
  "ClockAlignedDataInterval",
  "ChargingScheduleMaxPeriods",
  "ChargingScheduleAllowedChargingRateUnit",
  "chargingPointVendor",
  "chargingPointModel",
  "chargerName",
  "ChargeProfileMaxStackLevel",
  "chargePointSerialNumber",
  "basicAuthentication",
  "AuthorizeRemoteTxRequests",
  "AuthorizationCacheEnabled",
  "authAllOutlet",
  "OCPPEndpointToBackend",
  "SimultaneousChargeEnable"
]




function ChangeConfiguration({stationId}) {
  const [initialValues, setInitialValues] = useState({
    key: '',
    value: '',
  })
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await postRequest('/ocpp/remote/change-configuration/' + stationId, values);
      if (res && res.data) {
        toast.success("Request to change Configuration sent successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='change_configuration'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Change Configuration Of Device</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select Configuration Key</label>
                        <Field as='select' name='key' className='form-control'>
                          <option value=' '>Select Configuration Key</option>
                          {
                            keys.map((key) => {
                              return <option value={key}>{key}</option>
                            })
                          }
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Value</label>
                        <Field name='value' className='form-control' placeholder="Value of the configuration Key" />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeConfiguration
