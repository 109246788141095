/* eslint-disable */

import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getRequest} from '../Helpers/Requests'

export const CMSD = {
  heading: ' USER DETAILS',
  username: 'User Name',
  email: 'Email',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  addressline: 'Address Line 1',
  amcstartdate: 'AMC Start Date',
  amcenddate: 'AMC End Date',
}

const CMSDETAILS = () => {
  let {pointId} = useParams()
  const [cmsUsers, setcmsUsers] = useState<any | null>(null)

  // function to get user details by Id
  const getUserById = async () => {
    try {
      const res = await getRequest(`/cms/users/${pointId}`)
      if (res && res.data) {
        console.log(res.data)
        setcmsUsers(res.data.data.cmsUser)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    getUserById()
  }, [])

  return (
    <div>
      {/* CMS::DETAILS::STARTS*/}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
       
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{CMSD.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{cmsUsers?.OperatorName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{CMSD.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{cmsUsers?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {CMSD.mobilenumber}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{cmsUsers?.mobileNumber}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{CMSD.status}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-success'>
                {cmsUsers?.status}
                <span className='badge badge-light-success'>Active ?</span>
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {CMSD.addressline}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{cmsUsers?.address1}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{CMSD.amcstartdate}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{cmsUsers?.AMCstart}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{CMSD.amcenddate}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{cmsUsers?.AMCend}</span>
            </div>
          </div>
        </div>
      </div>
      {/* CMS::DETAILS::ENDS*/}
    </div>
  )
}

export default CMSDETAILS
