/* eslint-disable */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {loactindata} from '../../../../app/pages/js/locationdata'
import { useAuth } from '../../../../app/modules/auth'

export interface sessionUpdates {
  chargeboxid: String
  connectorid: String
  sessions: string
}
export const SessionTable = {
  heading: 'Payment Transactions',
  subheading: 'Recent 5 Transactions',
  referenceNumber: 'Reference Number',
  walletId:"Wallet Id",
  amount: 'Amount',
  type: 'Type'
}

const DashboardPaymentTransactionTable = ({className,transactions}) => {
  const {currentUser}=useAuth();
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{SessionTable.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{SessionTable.subheading}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px'>{SessionTable.referenceNumber}</th>
                <th className='min-w-100px'>{SessionTable.walletId}</th>
                <th className='min-w-100px'>{SessionTable.amount}</th>
                <th className='min-w-100px'>{SessionTable.type}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {transactions?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item?.refNo}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.walletId}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.amount}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.type === 1 ? 'Credit' : 'Debit'}
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          {currentUser?.group === '1' && (
            <div className='d-flex justify-content-end'>
              <a href='/Evusers/EV/Transactions'>...More</a>
            </div>
          )}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {DashboardPaymentTransactionTable}
