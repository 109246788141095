/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {useParams} from 'react-router-dom'
import axios from '../../API/Api_Instance'
import {getRequest} from '../Helpers/Requests'

interface Amenities {
  id: any
  name: string
  icon: string
  type: string
}

const Amentites = ({amenities}) => {
  const {pointId} = useParams()

  interface AuthData {
    token: string
  }

  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

  const [ComplimentaryAmenities, setComplimentaryAmenities] = useState([])
  const [NearByAmenities, setNearByAmenities] = useState([])

  const filterAmenities = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/amenities/`)
      if (res && res.data) {
        let amen = res.data.data.filter((e) => {
          if (amenities.includes(e.id)) {
            return e
          }
        })

        let comp = amen.filter((e) => {
          if (e.type === 'Complimentary Services') return e
        })

        let near = amen.filter((e) => {
          if (e.type === 'Near By Services') return e
        })
        setComplimentaryAmenities(comp)
        setNearByAmenities(near)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    filterAmenities()
  }, [amenities])
  return (
    <>
      {/*Complimentary Services Starts Here */}
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/complementary-services-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Complimentary Services
            </a>
          </div>

          <div className='card-toolbar m-0'>
            
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            {ComplimentaryAmenities?.map((item: Amenities) => {
              return (
                <div className='col-md-3'>
                  <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Restrooms'
                      >
                        <i className={`fa-solid ${item.icon} fs-3 fw-bold text-dark me-3`}></i>
                        {item.name}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/*Complimentary Services Starts Ends */}

      {/*NearBy Services Starts Here */}
      <div className='card card-custom mt-15'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/NearBy.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              NearBy Services
            </a>
          </div>

          <div className='card-toolbar m-0'>
           
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            {NearByAmenities.map((item: Amenities) => {
              return (
                <div className='col-md-3'>
                  <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Restrooms'
                      >
                        <i className={`fa-solid ${item.icon} fs-3 fw-bold text-dark me-3`}></i>
                        {item.name}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/*NearBy Services Starts Ends */}
    </>
  )
}

export default Amentites
