import React from 'react'
import {Dropdown1} from '../../../_metronic/partials'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import QRScanner from '../../UI/QRScanner'

const StationQrCode = ({connectors, stationId}) => {
  console.log(connectors)
  return (
    <div>
      <div className='card card-custom mt-15'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img src={toAbsoluteUrl('/qr-code-svg.svg')} alt='Metronic' className='p-3' />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Qr Codes of Station
            </a>
          </div>

          <div className='card-toolbar m-0'>
           
        </div>
        </div>
        <div className='card-body'>
        <div className='row'>
            {connectors.map((connector: any) => {
            return (
                <div className='col-md-6'>
                <h1 className='mb-5'>Connector {connector.connector_ID}</h1>
                <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Restrooms'
                    >
                        <QRScanner
                        value={(`https://web.zect.in?stationId=${stationId}&connectorId=${connector.connector_ID}`)}
                      //   {
                      //     connectorId: `${connector.connector_ID}`,
                      //     chargeboxId: `${stationId}`,
                      // }
                        title={'ChargeBox Id :' + stationId}
                        />
                    </label>
                    </div>
                    </div>
                </div>
            )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationQrCode
