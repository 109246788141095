import React from 'react'
import { EmployeeTable } from '../../_metronic/partials/widgets/tables/EmployeeTable'

const Employees = () => {
  return (
    <div>
        <EmployeeTable className='mb-5 mb-xl-8' />
    </div>
  )
}

export default Employees
