/* eslint-disable */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {loactindata} from '../../../../app/pages/js/locationdata'
import {ChargeDay} from '../../../../app/pages/js/Chargeperday'
import {deleteRequest, postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
import EditedAmenitiesModal from './EditedAmenitiesModal'

type Props = {
  className: string
  sessions: any
}
export interface sessionUpdates {
  chargeboxid: String
  connectorid: String
  sessions: string
}
export const SessionTable = {
  heading: 'Amenity Change Requests',
  subheading: 'Recent CPO Requests',
  cpoId: 'CPO Id',
  stationId: 'Station Id',
  request: 'Area of Change',
}

const TableWidgetDeep2: React.FC<Props> = ({className, sessions}) => {
  const [selectedStationId, setSelectedStationId] = useState('')

  const acceptAmenityChange = async (id) => {
    try {
      const res = await postRequest('/amenity-change/accept-request/' + id, {})
      console.log(res)
      if (res?.status === 200) {
        toast.success('Successfully accepted the request')
      }
    } catch (error) {}
  }

  const deleteAmenityRequest = async (id) => {
    try {
      const res = await deleteRequest('/amenity-change/delete-request/' + id)
      if (res?.status === 200) {
        toast.success('Successfully deleted the request')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{SessionTable.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{SessionTable.subheading}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px'>{SessionTable.cpoId}</th>
                <th className='min-w-100px'>{SessionTable.stationId}</th>
                <th className='min-w-100px'>{SessionTable.request}</th>
                <th className='min-w-110px'>Old Amenities</th>
                <th className='min-w-110px'>New Amenities</th>
                <th className='min-w-110px'>Deleted Amenities</th>
                <th className='min-w-110px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {sessions.map((item: any) => {
                return (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.cpoId}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item.stationId}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        Amenity
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.oldCount}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.newCount}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.delCount}
                      </a>
                    </td>
                    <td className='text-end'>
                      <a
                        className='btn btn-icon btn-bg-light btn-light-info btn-active-color-info btn-sm me-1'
                        data-bs-toggle='modal'
                        data-bs-target='#amenity_request_details'
                        onClick={() => {
                          setSelectedStationId(item?.stationId)
                        }}
                      >
                        <KTIcon iconName='information-2' className='fs-3' />
                      </a>
                      <a
                        className='btn btn-icon btn-bg-light btn-light-success btn-active-color-success btn-sm me-1'
                        onClick={() => {
                          acceptAmenityChange(item?.stationId)
                        }}
                      >
                        <KTIcon iconName='check' className='fs-3' />
                      </a>
                      <a
                        className='btn btn-icon btn-bg-light btn-light-danger btn-active-color-danger btn-sm me-1'
                        onClick={() => {
                          deleteAmenityRequest(item?.stationId)
                        }}
                      >
                        <KTIcon iconName={'trash'} className='fs-3' />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <EditedAmenitiesModal stationId={selectedStationId} />
    </div>
  )
}

export {TableWidgetDeep2}
