/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import NewMap from './Map/NewMap'
import {locationInitialValues} from '../Helpers/AllInitialValues'
import { error } from 'console'

const GeneralDetailsTwo = ({errors, setFieldValue, values,touched}) => {
  const [location, setLocation] = useState<any>(locationInitialValues)

  function LocationHandler(item) {
    setLocation(item)
    setFieldValue('location', item)
  }
  useEffect(() => {
    setLocation(values.location)
  }, [values.location])

  return (
    <div>
      <div className='card card-custom mt-15 mb-10'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/location-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Location Details
            </a>
          </div>

          <div className='card-toolbar m-0'>
           
          </div>
        </div>
        <div className='card-body'>
          <div className='row d-flex justify-content-between align-items-center mt-10'>
            <div className='col-md-6 pe-5'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Latitude'
                      name='latitude'
                      value={location.latitude}
                    />
                    <span className='text-danger fs-5'>{ errors.latitude && errors?.latitude}</span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Longitude'
                      name='longitude'
                      value={location.longitude}
                    />
                    <span className='text-danger fs-5'>{errors.longitude && errors?.longitude}</span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Address'
                      name='address'
                      value={location.address}
                    />
                    <span className='text-danger fs-5'>{errors.address && errors?.address}</span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='City'
                      name='city'
                      value={location.city}
                      onChange={(e:any)=>{setFieldValue("location[city]",e.target.value),console.log("updating")}}
                    />
                    <span className='text-danger fs-5'>{errors?.city && errors?.city}</span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='State'
                      name='state'
                      value={location.state}
                    />
                    <span className='text-danger fs-5'>{errors.state && errors?.state }</span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Country'
                      name='country'
                      value={location.country}
                    />
                    <span className='text-danger fs-5'>{ errors.country && errors.country }</span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='Pincode'
                      name='pincode'
                      value={location.pincode}
                    />
                    <span className='text-danger fs-5'>{errors.pincode && errors?.pincode }</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-10'>
                {/* <ModalMap setFieldValue={setFieldValue} LocationHandler = {LocationHandler}/> */}
                <NewMap setFieldValue={setFieldValue} LocationHandler={LocationHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralDetailsTwo
