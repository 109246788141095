import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {TransactionDetails} from './../../../app/pages/js/TransactionDetails'
import {getRequest, postRequest, putRequest} from '../Helpers/Requests'
import * as Yup from 'yup'
import {ErrorMessage, Field, Formik, Form} from 'formik'

import toast from 'react-hot-toast'

const addExpenseValidation = Yup.object().shape({
  ack:Yup.mixed().required("File is required to upload")
})

function AddAcknowledgementModal({expenseId}) {
  

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const formData = new FormData();
     
      formData.append("file",values.ack)
      const res=await putRequest("/expenses/upload-ack/"+expenseId,formData)
      if(res?.status===200){
        toast.success("Successfully created an expense")
      }
    } catch (error:any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='upload_ack'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <h5 className='modal-title'>Upload Expense Acknowledgement</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={{}}
                validationSchema={addExpenseValidation}
                onSubmit={handleSubmit}
              >
                {({setFieldValue}) => (
                  <Form>
                    <div>
                      <div className='row'>
                        
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Acknowledgement</label>
                          <input
                            className='form-control'
                            type='file'
                            name='ack'
                            id='acknowledgement'
                            onChange={(event: any) => {
                              setFieldValue('ack', event.target.files[0])
                              const maxFileSize = 1024 * 1024 * 2
                              if (event.target.files[0].size > maxFileSize) {
                                setFieldValue('ack', '')
                                toast.error('File size should be less than 2MB')
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='modal-footer mt-3'>
                        <button
                          type='button'
                          className='btn btn-light-danger'
                          data-bs-dismiss='modal'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-light-success'
                          data-bs-dismiss='modal'
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default AddAcknowledgementModal
