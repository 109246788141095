/* eslint-disable */


import {Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {useEffect, useState} from 'react'
import axios from '../../API/Api_Instance'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {getRequest} from '../Helpers/Requests'

interface Amenities {
  id: any
  name: string
  icon: string
  type: string
}

interface AuthData {
  token: string
}

const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

const Amenities = ({UpdateAmenities, Amenities}) => {
  const [Complimentary, setComplimentary] = useState([])
  const [NearBy, setNearBy] = useState([])

  
  const getAllamenities = async () => {
    try {
      const res = await getRequest('/amenities/')
      if (res && res.data) {
        let data = res.data.data
        let ComplimentaryServices = data.filter((e: any) => {
          if (e.type == 'Complimentary Services') return e
        })
        let NearByServices = data.filter((e: any) => {
          if (e.type == 'Near By Services') return e
        })
        setComplimentary(ComplimentaryServices)
        setNearBy(NearByServices)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    getAllamenities()
  }, [])

  const navigate = useNavigate()

  return (
    <div>
      {/* Complimentarty Services:: Starts:: Here */}
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/complementary-services-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Complimentary Services
            </a>
          </div>

          <div className='card-toolbar m-0'>
            
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            {Complimentary.map((e: Amenities) => {
              return (
                <div className='col-md-3' key={e.id}>
                  <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='ComplimentaryServices'
                        id='Restrooms'
                        onChange={() => UpdateAmenities(e.id)}
                        checked={Amenities.includes(e.id) ? true : false}
                      />
                      <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Restrooms'
                      >
                        <i className={`fa-solid ${e.icon} fs-3 fw-bold text-dark me-3`}></i>
                        {e.name}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
            
          </div>
          
        </div>
      </div>

    

      <div className='card card-custom mt-15 mb-10'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/NearBy.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Near By Services
            </a>
          </div>

          <div className='card-toolbar m-0'>
            
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            {NearBy.map((e: Amenities) => {
              return (
                <div className='col-md-3' key={e.id}>
                  <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='NearBy'
                        id='Restrooms'
                        onChange={() => UpdateAmenities(e.id)}
                        checked={Amenities.includes(e.id) ? true : false}
                      />
                      <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Tea/Coffee'
                      >
                        <i className={`fa-solid ${e.icon} fs-3 fw-bold text-dark me-3`}></i>

                        {e.name}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
            
          </div>
          
        </div>
      </div>

    </div>
  )
}

export default Amenities
