export const Tdetails = [
    {
        id: '1',
        username: 'Mani Mani',
        email: 'vanapallimani.kumari@teliolabs.com',
        mobile: '+91 - 8309251545',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'NA',
        vehicle: 'NA',
    },
    {
        id: '2',
        username: 'SaiKiran',
        email: 'N/A',
        mobile: '+91 - 9010002526',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'NA',
        vehicle: 'NA',
    },
    {
        id: '3',
        username: 'Krishna Reddy',
        email: 'krishna.robjack@gmail.com',
        mobile: '+91 - 8686491478',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'TG',
        vehicle: 'NA',
    }
]

export const Ptransactions = [
    {
        id: '1',
        username: 'Rakesh',
        email: 'NA',
        mobile: '+91 - 7753917266',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'TG',
        vehicle: 'NA',
    },
    {
        id: '2',
        username: 'Krishna Reddy',
        email: 'krishna.robjack@gmail.com',
        mobile: '+91 - 8686491478',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'TG',
        vehicle: 'NA',
    },
    {
        id: '3',
        username: 'Rakesh',
        email: 'NA',
        mobile: '+91 - 7753917266',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'TG',
        vehicle: 'NA',
    },
]
export const Fdetails = [
    {
        id: '1',
        username: 'Rakesh',
        email: 'NA',
        mobile: '+91 - 7753917266',
        role: 'ROLE_USER',
        activestatus: 'ACTIVE',
        tenantid: '07f2b16d-00a6-4c2d-834c-d090f6747312',
        signUp: 'MOBILE-APP',
        language: 'EN',
        PAN: 'NA',
        GST: 'NA',
        state: 'TG',
        vehicle: 'NA',
    },
]