/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from '../../API/Api_Instance'

type Props = {
  className: string
  Admin: {}
}

interface Group {
  id: number
  groupname: string
}

export interface AdminUpdate {
  id: any
  username: String
  email: String
  mobilenumber: String
  status: String
  group: String
}

export const AdminTable = {
  username: 'User Name',
  email: 'Email Id',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  group: 'Group Type',
}

const GroupDetails = ({groupData}) => {

  return (
    <div>
      {/*Form:Row::Starts*/}
      <form action=''>
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-10'>
              <input
                type='text'
                className='form-control form-control-solid border border-1 border-dark opacity-50'
                placeholder={groupData?.name}
                disabled
              />
            </div>
          </div>
        
        </div>
      </form>
      {/*Form:Row::Ends*/}
      {/*Table::Row::Starts*/}

      {/*Table::Row::Ends*/}
    </div>
  )
}

export default GroupDetails
