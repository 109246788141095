// import {useEffect, useState} from 'react'
// import * as Yup from 'yup'
// import clsx from 'clsx'
// import {Link} from 'react-router-dom'
// import {useFormik} from 'formik'
// import {requestPassword} from '../core/_requests'
// import toast from 'react-hot-toast'
// import {postRequest} from '../../../pages/Helpers/Requests'

// const forgotPasswordSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(8, 'Minimum 8 symbols')
//     .max(25, 'Maximum 25 symbols')
//     .required('Password is required'),
//   confirmPassword: Yup.string()
//     .oneOf([Yup.ref('password')], 'Passwords must match')
//     .required('Confirm password is required'),
// })

// export function ResetPassword() {
//   const [loading, setLoading] = useState(false)
//   const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
//   const [token, setToken] = useState('')
//   const [initialValues, setInitialValues] = useState({
//     email: '',
//     password: '',
//     confirmPassword: '',
//   })
//   const formik = useFormik({
//     initialValues,
//     validationSchema: forgotPasswordSchema,
//     onSubmit: (values, {setStatus, setSubmitting}) => {
//       setLoading(true)
//       setHasErrors(undefined)

//       //   requestPassword(values.email)
//       //     .then((res) => {
//       //       console.log(res);
//       //       if(res.status===200){
//       //         toast.success(res.data.message)
//       //       }
//       //       setHasErrors(false)
//       //       setLoading(false)
//       //     })
//       //     .catch(() => {
//       //       setHasErrors(true)
//       //       setLoading(false)
//       //       setSubmitting(false)
//       //       setStatus('The login detail is incorrect')
//       //     })
//     },
//   })

//   const validateTokenAndGetUser = async () => {
//     try {
//       const res = await postRequest('/auth/validate-token/get-user', {token:token})
//       if (res?.status === 200) {
//         setInitialValues({...initialValues, email: res.data.data})
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search)
//     const tokenFound = urlParams.get('token')
//     console.log(tokenFound)
//     if (tokenFound) {
//       setToken(tokenFound)
//     }
//   }, [])

//   useEffect(() => {
//     if(token!=="" && token!==undefined && token!==null){
//         validateTokenAndGetUser()
//     }
//   }, [token])

//   return (
//     <form
//       className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
//       noValidate
//       id='kt_login_password_reset_form'
//       onSubmit={formik.handleSubmit}
//     >
//       <div className='text-center mb-10'>
//         {/* begin::Title */}
//         <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
//         {/* end::Title */}

//         {/* begin::Link */}
//         <div className='text-gray-500 fw-semibold fs-6'>Enter your new Password.</div>
//         {/* end::Link */}
//       </div>

//       {/* begin::Title */}
//       {hasErrors === true && (
//         <div className='mb-lg-15 alert alert-danger'>
//           <div className='alert-text font-weight-bold'>
//             Sorry, looks like there are some errors detected, please try again.
//           </div>
//         </div>
//       )}

//       {hasErrors === false && (
//         <div className='mb-10 bg-light-info p-8 rounded'>
//           <div className='text-info'>Sent password reset. Please check your email</div>
//         </div>
//       )}
//       {/* end::Title */}

//       {/* begin::Form group */}
//       <div className='fv-row mb-8'>
//         <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
//         <input
//           type='email'
//           placeholder=''
//           autoComplete='off'
//           {...formik.getFieldProps('email')}
//           className={clsx(
//             'form-control bg-transparent',
//             {'is-invalid': formik.touched.email && formik.errors.email},
//             {
//               'is-valid': formik.touched.email && !formik.errors.email,
//             }
//           )}
//         />
//         {formik.touched.email && formik.errors.email && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.email}</span>
//             </div>
//           </div>
//         )}
//       </div>
//       <div className='fv-row mb-8'>
//         <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
//         <input
//           type='text'
//           placeholder=''
//           autoComplete='off'
//           {...formik.getFieldProps('password')}
//           className={clsx(
//             'form-control bg-transparent',
//             {'is-invalid': formik.touched.password && formik.errors.password},
//             {
//               'is-valid': formik.touched.password && !formik.errors.password,
//             }
//           )}
//         />
//         {formik.touched.password && formik.errors.password && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.password}</span>
//             </div>
//           </div>
//         )}
//       </div>
//       <div className='fv-row mb-8'>
//         <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
//         <input
//           type='test'
//           placeholder=''
//           autoComplete='off'
//           {...formik.getFieldProps('confirmPassword')}
//           className={clsx(
//             'form-control bg-transparent',
//             {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
//             {
//               'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
//             }
//           )}
//         />
//         {formik.touched.confirmPassword && formik.errors.confirmPassword && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.confirmPassword}</span>
//             </div>
//           </div>
//         )}
//       </div>
//       {/* end::Form group */}

//       {/* begin::Form group */}
//       <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
//         <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
//           <span className='indicator-label'>Submit</span>
//           {loading && (
//             <span className='indicator-progress'>
//               Please wait...
//               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//             </span>
//           )}
//         </button>
//         <Link to='/auth/login'>
//           <button
//             type='button'
//             id='kt_login_password_reset_form_cancel_button'
//             className='btn btn-light'
//             disabled={formik.isSubmitting || !formik.isValid}
//           >
//             Cancel
//           </button>
//         </Link>{' '}
//       </div>
//       {/* end::Form group */}
//     </form>
//   )
// }

import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {requestPassword} from '../core/_requests'
import toast from 'react-hot-toast'
import {postRequest} from '../../../pages/Helpers/Requests'

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(25, 'Maximum 25 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [token, setToken] = useState('')
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  })

  const validateTokenAndGetUser = async () => {
    try {
      const res = await postRequest('/auth/validate-token/get-user', {token})
      if (res?.status === 200) {
        setInitialValues((prev) => ({...prev, email: res.data.data}))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenFound = urlParams.get('token')
    if (tokenFound) {
      setToken(tokenFound)
    }
  }, [])

  useEffect(() => {
    if (token) {
      validateTokenAndGetUser()
    }
  }, [token])

  // useEffect(()=>{
  //   setTimeout(()=>{
  //       if(!token){
  //           window.location.href="/auth/forgot-password"
  //       }
  //   },2500)
  // },[])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema}
      enableReinitialize={true}
      onSubmit={async (values, {setStatus, setSubmitting}) => {
        try {
          setLoading(true)
          setHasErrors(undefined)
          const res = await postRequest('/auth/validate-token/reset-password/', {...values,token:token})
          if (res?.status === 200) {
            toast.success('Passsword Reset Successfull')
          }
          setHasErrors(false)
          setLoading(false)
          setTimeout(() => {
            window.location.href = '/auth/login'
          }, 1000)
        } catch (error: any) {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(error.response.data.message)
          console.log(error)
        }
      }}
    >
      {({isSubmitting, isValid}) => (
        <Form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
            <div className='text-gray-500 fw-semibold fs-6'>Enter your new Password.</div>
          </div>

          {hasErrors && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <Field
              type='email'
              name='email'
              placeholder=''
              autoComplete='off'
              className='form-control'
            />
            <ErrorMessage name='email' component={'div'} className='text-danger' />
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
            <Field
              type='password'
              name='password'
              placeholder=''
              autoComplete='off'
              className='form-control'
            />
            <ErrorMessage name='password' component={"div"} className='text-danger' />
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
            <Field
              type='password'
              name='confirmPassword'
              placeholder=''
              autoComplete='off'
              className='form-control'
            />
            <ErrorMessage name='confirmPassword' component={'div'} className='text-danger' />
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-primary me-4'
              disabled={isSubmitting}
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={isSubmitting || !isValid}
              >
                Cancel
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}
