import React from 'react'
import TariffDetailsHeader from './TariffDetailsHeader'

const TariffDetails = () => {
  return (
    <div>
      <TariffDetailsHeader />
    </div>
  )
}

export default TariffDetails
