/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker} from 'react-leaflet'
import L from 'leaflet'
import MapMaker from '../../../Markers/ZectMaker.png'
import {getRequest} from '../Helpers/Requests'

const markerIcon = new L.Icon({
  iconUrl: MapMaker,
  iconSize: [40, 50],
  iconAnchor: [17, 45],
})
const InactiveMarkerIcon = new L.Icon({
  iconUrl: MapMaker,
  iconSize: [40, 50],
  iconAnchor: [17, 45],
})

const Home = () => {
  const [isVisible, setIsVisible] = useState({lat: 14.707891, lng: 77.607965})
  const [locations, setLocations] = useState<any>([])
  const Zoom_Level = 5

  const getAllLocations = async () => {
    try {
      const res = await getRequest(`/all/locations`)
      
      if (res && res.data) {
        setLocations(res.data.data.locations)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getAllLocations()
  }, [])

  return (
    <div style={{width: '100%', marginTop: '-70px'}}>
      <div style={{overflow: 'hidden'}}>
        <MapContainer
          style={{height: '84vh', width: '100%', overflow: 'hidden'}}
          center={isVisible}
          zoom={Zoom_Level}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=1lr4p1ZPHGfGrNtJ34uL'
          />
          <div>
            {locations.map((position:any, index:any) => (
              <Marker
                key={index}
                position={[position.latitude, position.longitude]}
                icon={position?.station?.status==="Active"?markerIcon:InactiveMarkerIcon}
              ></Marker>
            ))}
          </div>
        </MapContainer>
      </div>
    </div>
  )
}

export default Home
