import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {TransactionDetails} from './../../../app/pages/js/TransactionDetails'
import {getRequest} from '../Helpers/Requests'

export interface details {
  date: String
  time: String
  chargebox: String
  transactionid: String
  temperature: String
  voltage: String
  batterpercentage: String
  currentinput: String
  currentoffered: String
  powerOffered:String
  meetvalue: String
}

export const data = {
  heading: 'Transaction Details',
  date: 'Date',
  time: 'Time',
  chargebox: 'ChargeBox',
  transactionid: 'Transaction Details',
  temperature: 'Temperature',
  voltage: 'Voltage',
  batterpercentage: 'Battery Percentage',
  currentinput: 'Current Input',
  currentoffered: 'Current Offered',
  powerOffered:'Power Offered',
  meetvalue: 'Meter Value',
}

function TransactionDetailsModal({transactionId}) {
  const [transactionDetails, setTransactionDetails] = useState([])
  const [full, setFull] = useState(false)
  const [transactions, setTransactions] = useState([])

  const getTransactionDetails = async () => {
    try {
      const res = await getRequest('/transaction-details/transaction/' + transactionId)
      if (res?.status === 200) {
        setTransactionDetails(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleShowFull = () => {
    const showTransactions = full
      ? transactionDetails
      : [...transactionDetails.slice(0, 4), ...transactionDetails.slice(-3)]
    setTransactions((prev) => {
      return showTransactions
    })
  }

  useEffect(() => {
    getTransactionDetails()
  }, [transactionId])

  useEffect(() => {
    toggleShowFull()
  }, [transactionDetails, full])

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='transaction_details'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <h5 className='modal-title'>Transaction Details</h5>
              
              {full ? (
                <button type='button' className='btn btn-warning' onClick={()=>{setFull(!full)}}>
                  Show Less
                </button>
              ) : (
                <button type='button' className='btn btn-success' onClick={()=>{setFull(!full)}}>
                  Show Full
                </button>
              )}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='table-responsive'>
                <table className='table table-hover table-rounded table-striped border gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                      <th>{data.date}</th>
                      <th>{data.time}</th>
                      <th>{data.chargebox}</th>
                      <th>{data.transactionid}</th>
                      <th>{data.temperature}</th>
                      <th>{data.voltage}</th>
                      <th>{data.batterpercentage}</th>
                      <th>Current Import(Outlet)</th>
                      <th>Current Import(Ev)</th>
                      <th>{data.powerOffered}</th>
                      <th>{data.meetvalue}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.map((item: any,index:any) => {
                      const localDateTime=new Date(item?.createdAt).toLocaleTimeString();
                      return (
                        <tr key={index}>
                          <td>{item?.createdAt?.split('T')[0]}</td>
                          <td>{localDateTime}</td>
                          <td>{item?.stationId}</td>
                          <td>{item?.transactionId}</td>
                          <td>{item?.temperature}</td>
                          <td>{item?.voltage}</td>
                          <td>{item?.batteryPercentage}</td>
                          <td>{item?.currentImport}</td>
                          <td>{item?.currentImportEv}</td>
                          <td>{item?.powerOffered}</td>
                          <td>{item?.meterValue}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionDetailsModal
