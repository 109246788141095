/* eslint-disable */

import React from 'react'

const RolesDetails = ({role}) => {
  return (
    <div className='mt-15'>
      <form action='#'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='mb-10'>
              <input
                type='text'
                className='form-control form-control-solid border border-1 border-dark opacity-50'
                placeholder='role'
                disabled
                value={role.name}
              />
            </div>
          </div>
          <div className='col-md-5'>
            <div className='mb-10'>
              <input
                type='text'
                className='form-control form-control-solid border border-1 border-dark'
                placeholder='Enter Role Description'
              />
            </div>
          </div>
          <div className='col-md-2'>
            <div className='mb-10'>
              <a href='#' className='btn btn-light-success btn-lg'>
                Success
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default RolesDetails
