export const CmsStationData = [
    {
        id: '1',
        operatorname: '',
        stationame: '',
        chargebox: '',
        type: '',
        connectors: '',
        porttype: '',
        location: '',
        status: '',
    },
    {
        id: '2',
        operatorname: '',
        stationame: '',
        chargebox: '',
        type: '',
        connectors: '',
        porttype: '',
        location: '',
        status: '',
    }
]