// /* eslint-disable */

// import React, {useEffect, useState} from 'react'
// import DeviceSpecification from './DeviceSpecification'
// import DeviceConfiguration from './DeviceConfiguration'
// import DeviceTransaction from './DeviceTransaction'
// import DeviceLogos from './DeviceLogos'
// import StationDetailsAmenities from './StationDetailsAmenities'
// import StationImages from './StationImages'
// import {Link, useParams} from 'react-router-dom'
// import {KTIcon} from '../../../_metronic/helpers'
// import {getRequest} from '../Helpers/Requests'
// import DeviceManagement from './DeviceManagement'
// import StationQrCode from './StationQrCode'

// export const Heading = {
//   heading: 'StationDetails_Zect',
//   heading1: 'Device Specification',
//   heading2: 'Device Configuration',
//   heading3: 'Device Transaction',
//   heading4: 'Device Logs',
//   heading5: 'Amenties',
//   heading6: 'Station Images',
//   heading7: 'Device Management',
// }

// const StationDetailsHeader = () => {
//   const [StationsData, setStationsData] = useState()
//   const [StationAmenities, setStationAmenities] = useState([])
//   const {pointId} = useParams()
//   const [stationId, setStationId] = useState('')
//   const [connectors, setConnectors] = useState([])

  
//   const getStation = async () => {
//     try {
//       const res = await getRequest(`/stations/${pointId}`)
//       if (res && res.data) {
//         console.log(res.data.data)
//         console.log(res.data.data.station.connectors)
//         setConnectors(res.data.data.station.connectors)
//         setStationId(res.data.data.station.stationId)
//         let StationAmen = res.data.data.station.amenities
//         setStationsData(res.data.data.station)
//         setStationAmenities(StationAmen)
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   useEffect(() => {
//     getStation()
//   }, [])
//   return (
//     <div>
//       <div className='text-end mb-5'>
//         <Link
//           to='/Chargepoint'
//           className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
//         >
//           <KTIcon iconName='arrow-left' className='fs-1' />
//         </Link>
//       </div>
//       <div className='card card-custom'>
//         <div className='card-body mt-5'>
//           <h1 className='mb-5'>Station Details</h1>
//           <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5'>
//             <li className='nav-item'>
//               <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
//                 {Heading.heading1}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
//                 {Heading.heading7}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
//                 {Heading.heading2}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_6'>
//                 {Heading.heading3}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_7'>
//                 {Heading.heading4}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_8'>
//                 {Heading.heading5}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_9'>
//                 {Heading.heading6}
//               </a>
//             </li>
//             <li className='nav-item'>
//               <a className='nav-link' data-bs-toggle='tab' href='#ktTabPane'>
//                 QR Codes
//               </a>
//             </li>
//           </ul>
//           <div className='tab-content' id='myTabContent'>
//             <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
//               <DeviceSpecification />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
//               <DeviceManagement stationId={stationId} id={pointId} connectors={connectors} />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
//               <DeviceConfiguration />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
//               <DeviceTransaction stationId={stationId} />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_7' role='tabpanel'>
//               <DeviceLogos stationId={stationId} />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_8' role='tabpanel'>
//               <StationDetailsAmenities amenities={StationAmenities} />
//             </div>
//             <div className='tab-pane fade' id='kt_tab_pane_9' role='tabpanel'>
//               <StationImages />
//             </div>
//             <div className='tab-pane fade' id='ktTabPane' role='tabpanel'>
//               <StationQrCode stationId={stationId} connectors={connectors} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default StationDetailsHeader


import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { getRequest } from '../Helpers/Requests';
import DeviceManagement from './DeviceManagement';
import DeviceSpecification from './DeviceSpecification';
import DeviceConfiguration from './DeviceConfiguration';
import DeviceTransaction from './DeviceTransaction';
import DeviceLogos from './DeviceLogos';
import StationDetailsAmenities from './StationDetailsAmenities';
import StationImages from './StationImages';
import StationQrCode from './StationQrCode';
import StationSettings from './StationSettings';

const Heading = {
  heading: 'StationDetails_Zect',
  heading1: 'Device Specification',
  heading2: 'Device Configuration',
  heading3: 'Device Transaction',
  heading4: 'Device Logs',
  heading5: 'Amenties',
  heading6: 'Station Images',
  heading7: 'Device Management',
};

const StationDetailsHeader = () => {
  const [stationId, setStationId] = useState('');
  const [connectors, setConnectors] = useState([]);
  const [stationAmenities, setStationAmenities] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // State to hold active tab index
  const [stationName,setStationName]=useState("");
  const [stationImages,setStationImages]=useState<any>([])
  const { pointId } = useParams();

  // Fetch station details and initialize state
  useEffect(() => {
    const getStation = async () => {
      try {
        const res = await getRequest(`/stations/${pointId}`);
        if (res && res.data) {
          const { station } = res.data.data;
          setStationName(station.stationName);
          setStationId(station.stationId);
          setConnectors(station.connectors);
          setStationAmenities(station.amenities);
          setStationImages(station.images);
        }
      } catch (error) {
        console.error('Error fetching station details:', error);
      }
    };

    getStation();
  }, [pointId]);

  // Initialize active tab from local storage on component mount
  useEffect(() => {
    const storedTab = localStorage.getItem('selectedTab');
    if (storedTab !== null) {
      setActiveTab(parseInt(storedTab));
    }
  }, []);

  // Handle tab click and update active tab in state and local storage
  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem('selectedTab', index.toString());
  };

  return (
    <div>
      <div className="text-end mb-5">
        <Link
          to="/Chargepoint"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        >
          <KTIcon iconName="arrow-left" className="fs-1" />
        </Link>
      </div>
      <div className="card card-custom">
        <div className="card-body mt-5">
          <h1 className="mb-5">{stationName}</h1>
          <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
                onClick={() => handleTabClick(0)}
              >
                {Heading.heading1}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
                onClick={() => handleTabClick(1)}
              >
                {Heading.heading7}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
                onClick={() => handleTabClick(2)}
              >
                {Heading.heading2}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_6"
                onClick={() => handleTabClick(3)}
              >
                {Heading.heading3}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 4 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_7"
                onClick={() => handleTabClick(4)}
              >
                {Heading.heading4}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 5 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_8"
                onClick={() => handleTabClick(5)}
              >
                {Heading.heading5}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 6 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_9"
                onClick={() => handleTabClick(6)}
              >
                {Heading.heading6}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 7 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#ktTabPane"
                onClick={() => handleTabClick(7)}
              >
                QR Codes
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 8 ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#kt_tab_pane_10"
                onClick={() => handleTabClick(8)}
              >
                Settings
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}
              id="kt_tab_pane_4"
              role="tabpanel"
            >
              <DeviceSpecification />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}
              id="kt_tab_pane_3"
              role="tabpanel"
            >
              <DeviceManagement
                stationId={stationId}
                id={pointId}
                connectors={connectors}
              />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}
              id="kt_tab_pane_5"
              role="tabpanel"
            >
              <DeviceConfiguration stationId={stationId}/>
            </div>
            <div
              className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`}
              id="kt_tab_pane_6"
              role="tabpanel"
            >
              <DeviceTransaction stationId={stationId} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`}
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <DeviceLogos stationId={stationId} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 5 ? 'show active' : ''}`}
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <StationDetailsAmenities amenities={stationAmenities} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 6 ? 'show active' : ''}`}
              id="kt_tab_pane_9"
              role="tabpanel"
            >
              <StationImages images={stationImages} stationId={pointId}/>
            </div>
            <div
              className={`tab-pane fade ${activeTab === 7 ? 'show active' : ''}`}
              id="ktTabPane"
              role="tabpanel"
            >
              <StationQrCode stationId={stationId} connectors={connectors} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 8 ? 'show active' : ''}`}
              id="kt_tab_pane_10"
              role="tabpanel"
            >
              <StationSettings stationId={stationId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationDetailsHeader;
