/* eslint-disable */

import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {WalletId} from '../js/WallletList'
import Top from '../../modules/Chargestations/Charge/components/Top'
import { KTIcon } from '../../../_metronic/helpers'

export interface traiffD {
  id: String
  payment: String
  order: String
  gateway: String
  date: String
  operation: String
  paymentgateway: String
  description: String
  amount: String
  adjustment: String
}

export const Wallet = {
  heading: 'Wallet Details',
  payment: 'Payment Transaction Id',
  order: 'Order Id',
  gateway: 'Payment Gateway Id',
  date: 'Date',
  operation: 'Operation',
  paymentgateway: 'Payment Gateway',
  description: 'Description',
  amount: 'Amount (Rs)',
  adjustment: 'Wallet Adjustment',
}

const WalletDetailsHeader = () => {
  let {pointId} = useParams()

  // var pid: number = parseInt(pointId);
  var result = WalletId.find(function (e) {
    return e.id == pointId
  })
  return (
    <>
      {/* Top Section Starts Here */}
      <div className='text-end mb-5'>
        <Link
          to='/Evusers/EV/Wallet'
          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <Top />
      {/* Top Section Ends Here */}
      {/* Heading Starts Here */}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header bg-success d-flex justify-content-center'>
          <h3 className='card-title text-white'>{Wallet.heading}</h3>
        </div>
      </div>
      {/* Heading Ends Here */}

      {/* WaletTable:Details:: Starts */}

      <div className='table-responsive'>
        <table className='table table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th className='min-w-250px'>{Wallet.payment}</th>
              <th className='min-w-250px'>{Wallet.order}</th>
              <th className='min-w-250px'>{Wallet.gateway}</th>
              <th className='min-w-250px'>{Wallet.date}</th>
              <th className='min-w-250px'>{Wallet.operation}</th>
              <th className='min-w-250px'>{Wallet.paymentgateway}</th>
              <th className='min-w-250px'>{Wallet.description}</th>
              <th className='min-w-250px'>{Wallet.amount}</th>
              <th className='min-w-250px'>{Wallet.adjustment}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{result?.payment}</td>
              <td>{result?.order}</td>
              <td>{result?.gateway}</td>
              <td>{result?.date}</td>
              <td>{result?.operation}</td>
              <td>{result?.paymentgateway}</td>
              <td>{result?.description}</td>
              <td>{result?.amount}</td>
              <td>{result?.adjustment}</td>
            </tr>
            <tr>
              <td>{result?.payment}</td>
              <td>{result?.order}</td>
              <td>{result?.gateway}</td>
              <td>{result?.date}</td>
              <td>{result?.operation}</td>
              <td>{result?.paymentgateway}</td>
              <td>{result?.description}</td>
              <td>{result?.amount}</td>
              <td>{result?.adjustment}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* WaletTable:Details:: Ends */}
    </>
  )
}

export default WalletDetailsHeader
