import {Field, FieldArray} from 'formik'
import DaysButton from '../../UI/DaysButton'

const PriceTypeVariable = ({
  values,
  errors,
  setFieldValue,
}: {
  values: any
  errors: any
  setFieldValue: any
}) => {
  const Days = ['Monday', 'TuesDay', 'WednesDay', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  return (
    <>
      <div className='row'>
        <FieldArray name={`variablePrices`}>
          {({push, pop}) => (
            <>
              <div className='row'>
                <div className='d-flex justify-content-end mb-10'>
                  <button
                    className='btn btn-light-success btn-sm'
                    type='button'
                    onClick={() =>
                      push({
                        vp: [{time: '', startTime: '', endTime: '', price: ''}],
                        days: [],
                      })
                    }
                  >
                    <i className='bi bi-plus-circle fs-4'></i>Tariff
                  </button>
                </div>
              </div>
              <div>
                {values.variablePrices.map((vp, index) => {
                  const selectedDays = vp.days || []
                  const disabledDays = values.variablePrices
                    .filter((_, i) => i !== index)
                    .flatMap((s) => s.days || [])
                  return (
                    <div key={index}>
                      <div>
                        <DaysButton
                          onSelectDay={(newSelectedDays: any) => {
                            setFieldValue(`variablePrices[${index}].days`, newSelectedDays)
                          }}
                          days={Days}
                          key={index}
                          disabledArray={disabledDays}
                          selectedDays={selectedDays}
                        />
                      </div>
                      {
                        <FieldArray name={`variablePrices[${index}].vp`}>
                          {({push: pushInner, pop: popInner}) => (
                            <>
                              <div>
                                {values.variablePrices[index].vp.map((ele, i) => (
                                  <div key={i}>
                                    <div className='row mt-5'>
                                      <div className='col-md-3'>
                                        <div className='mb-10'>
                                          <Field
                                            as='select'
                                            className='form-select'
                                            aria-label='Select example'
                                            name={`variablePrices[${index}].vp[${i}].time`}
                                          >
                                            <option value='Select Time'>Select Time</option>
                                            <option value='TIME'>TIME</option>
                                          </Field>
                                          <span className='text-danger fs-5'>
                                            {/* {errors.time && errors.time} */}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className='mb-10 opacity-50'>
                                          <Field
                                            type='time'
                                            className='form-control'
                                            name={`variablePrices[${index}].vp[${i}].startTime`}
                                            disabled
                                            value={
                                              i !== 0
                                                ? values.variablePrices[index].vp[i - 1].endTime
                                                : '00:00'
                                            }
                                          />
                                          <label htmlFor='' className='mt-1'>
                                            Start time
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className='mb-10'>
                                          <Field
                                            type='time'
                                            className='form-control'
                                            name={`variablePrices[${index}].vp[${i}].endTime`}
                                          />
                                          <label htmlFor='' className='mt-1'>
                                            End time
                                          </label>
                                          <br />
                                          <span className='text-danger fs-5'>
                                            {/* {errors.endTime && errors.endTime} */}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className='mb-10'>
                                          <Field
                                            type='number'
                                            className='form-control'
                                            placeholder='Price (excluding tax)'
                                            name={`variablePrices[${index}].vp[${i}].price`}
                                          />
                                          <span className='text-danger fs-5'>
                                            {/* {errors.price && errors.price} */}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className='row mb-5'>
                                <div className='col-md-12'>
                                  <div className='d-flex justify-content-end'>
                                    <button
                                      type='button'
                                      className='btn btn-icon btn-light-success btn-sm me-5 mb-5'
                                      onClick={() => {
                                        pushInner({
                                          time: '',
                                          startTime: '',
                                          endTime: '',
                                          price: '',
                                        })
                                      }}
                                    >
                                      <i className='bi bi-plus-circle fs-4'></i>
                                    </button>
                                    <button
                                      type='submit'
                                      disabled={values.variablePrices[index].vp.length < 2}
                                      className='btn btn-icon btn-light-success btn-sm me-3 mb-5'
                                      onClick={() => {
                                        popInner()
                                      }}
                                    >
                                      <i className='bi bi-trash3-fill fs-4'></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </FieldArray>
                      }
                    </div>
                  )
                })}
              </div>
              <div className='d-flex justify-content-start mb-10'>
                <button
                  type='button'
                  disabled={values.variablePrices.length < 2}
                  onClick={() => {
                    pop()
                  }}
                  className='btn btn-light-success btn-sm ms-5'
                >
                  <i className='bi bi-trash3-fill fs-4 me-2'></i>Tariff
                </button>
              </div>
            </>
          )}
        </FieldArray>
      </div>
    </>
  )
}

export default PriceTypeVariable
