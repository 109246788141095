/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {tariffUsers} from '../js/TariffDetails'
import {KTIcon} from '../../../_metronic/helpers'
import axios from '../../API/Api_Instance'
import {point} from 'leaflet'
import {TariffTable} from '../js/TariffTABLE'

type Props = {
  className: string
  tariffD: {}
}

interface Tariff {
  name: String
  operatorId: String
  country: String
  currency: String
  priceType: String
  fixedPrice: {
    price: String
    time: String
    minutes: String
  }
  variablePrices: [
    {
      time: String
      startTime: String
      endTime: String
      price: String
      days: []
    }
  ]
}

export interface traiffD {
  time: String
  price: String
  starttime: String
  endtime: String
  days: String
}

export const Tariffs = {
  heading: 'Tariff Details',
  name: 'Name',
  operatorid: 'Operator Id',
  currency: 'Currency',
  countrycode: 'Country Code',
  type: 'Type',
  time: 'Time',
  price: 'Price',
  starttime: 'Start Time',
  endtime: 'End Time',
  days: 'Days',
  stepsize: 'Step Size (Mins)',
}

interface AuthData {
  token: string
}

const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
const {token}: AuthData = storedDataString ? JSON.parse(storedDataString) : {token: ''}

const TariffDetailsHeader = () => {
  let {pointId} = useParams()
  const [Tariff, setTariff] = useState<Tariff | null>(null)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tariff/${pointId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        console.log(res.data.data.tariff)
        setTariff(res.data.data.tariff)
      })
      .catch((err) => console.log(err))
  }, [pointId])
  return (
    <div>
      {/* TariffHeader:Details:: Starts */}
      <div className='text-end'>
        <Link
          to='/Chargestations/Charge/Tariff'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title text-black'>{Tariffs.heading}</h3>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Tariffs.name}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{Tariff?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Tariffs.operatorid}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{Tariff?.operatorId}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Tariffs.currency}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>INR</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Tariffs.countrycode}</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {Tariff?.country}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Tariffs.type}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{Tariff?.priceType}</span>
            </div>
          </div>
        </div>
      </div>
      {/* TariffHeader:Details:: Ends */}
      {/*Table::Row::Starts */}
      <div className='table-responsive'>
        {Tariff?.priceType == 'Fixed' ? (
          <table className='table table-row-dashed table-row-gray-300 gy-7 gs-7'>
            <thead>
              <tr className='fw-bold fs-6 border-bottom-2 border-gray-200'>
                <th className='min-w-200px'>{Tariffs.time}</th>
                <th className='min-w-200px'>{Tariffs.price}</th>
                <th className='min-w-200px'>{Tariffs.stepsize}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='fw-bold'>{Tariff.fixedPrice.time}</td>
                <td className='fw-bold'>{Tariff.fixedPrice.price}</td>
                <td className='fw-bold'>{Tariff.fixedPrice.minutes}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className='table table-row-dashed table-row-gray-300 gy-7 gs-7'>
            <thead>
              <tr className='fw-bold fs-6 border-bottom-2 border-gray-200'>
                <th className='min-w-200px'>{Tariffs.time}</th>
                <th className='min-w-200px'>{Tariffs.price}</th>
                <th className='min-w-200px'>{Tariffs.starttime}</th>
                <th className='min-w-200px'>{Tariffs.endtime}</th>
                <th className='min-w-200px'>{Tariffs.days}</th>
              </tr>
            </thead>
            <tbody>
              {Tariff?.variablePrices.map((item: any, index) => {
                return (
                  <tr key={index}>
                    <td className='fw-bold'>{item.time}</td>
                    <td className='fw-bold'>{item.price}</td>
                    <td className='fw-bold'>{item.startTime}</td>
                    <td className='fw-bold'>{item.endTime}</td>
                    <td className='fw-bold'>{item.days.join(',')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
      {/*Table::Row::Ends */}
    </div>
  )
}

export default TariffDetailsHeader
