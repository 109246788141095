import React from 'react'
import RolesSingleData from './Components/RolesSingleData'

const RolesSinglePage = () => {
  return (
    <div>
      <RolesSingleData />
    </div>
  )
}

export default RolesSinglePage
