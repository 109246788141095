import React from 'react'
import GeneralDetailsOne from './GeneralDetailsOne'
import GeneralDetailsTwo from './GeneralDetailsTwo'
import GeneralDetailsThree from './GeneralDetailsThree'

const GeneralDetail = ({errors, values, setFieldValue,touched}) => {
  return (
    <div>
      <GeneralDetailsOne errors={errors} values={values} setFieldValue={setFieldValue} touched={touched}/>
      <GeneralDetailsTwo errors={errors} setFieldValue={setFieldValue} values={values} touched={touched} />
      <GeneralDetailsThree errors={errors} setFieldValue={setFieldValue} values={values} touched={touched} />
    </div>
  )
}

export default GeneralDetail
