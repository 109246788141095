/* eslint-disable */


import React, {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {newCmsUserValidation} from '../js/newCmsUserValidation'
import {useNavigate, useParams} from 'react-router-dom'
import PopUpSumbit from './PopUpSubmit'
import axios from '../../API/Api_Instance'
import toast,{Toaster} from 'react-hot-toast'
import {getRequest, putRequest} from '../Helpers/Requests'

export interface EdiCMSD {
  id: any
  operatorId: String
  OperatorName: String
  group: String
  email: String
  country: String
  state: String
  city: String
  pincode: String
  countryCode: String
  mobileNumber: String
  address1: String
  address2: String
  AMCstart: String
  AMCend: String
}

const EditDetailsCMS = () => {
  const {pointId} = useParams()
  const navigate = useNavigate();
  const [roles,setRoles]=useState([])

  const [initialValues, setInitialValues] = useState({
    operatorId: '',
    OperatorName: '',
    group: '',
    email: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    countryCode: '',
    mobileNumber: '',
    address1: '',
    address2: '',
    AMCstart: '',
    AMCend: '',
  })

  const handleSubmit = (values) => {
    putRequest(`cms/users/${pointId}`, values)
      .then((res) => {
        console.log(res)
        toast.success('Updated SuccessFully')
        setTimeout(() => {
          navigate('/UserManagment/User/CmsUsers')
        }, 1000)
      })
      .catch((err) => {
        toast.error('Invalid Data Check The Data')
        console.log(err)
      })
  }

  const getUserObject = async () => {
    try {
      const res = await getRequest(`cms/users/${pointId}`)
      console.log(res)
      if (res && res.data) {
        const fetchedData = res.data.data.cmsUser
        delete fetchedData.id
        setInitialValues(fetchedData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getUserObject()
  }, [pointId])

  const getAssignableRoles = async () => {
    try {
      const res = await getRequest(`/roles`)
      if (res && res.data) {
        // setRoles(res.data.data.filter((item) => item.name !== 'admin' && item.name !== 'CPO'))
        setRoles(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAssignableRoles()
  }, [])

  return (
    <div className='mt-10'>
     
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT CMS USER DETAILS</h3>
        </div>
        <div className='card-body'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={newCmsUserValidation}
            onSubmit={handleSubmit}
          >
            {({errors}) => (
              <Form>
                <div className='row pt-5'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Operator ID'
                        name='operatorId'
                      />
                      <span className='text-danger fs-5'>
                        {errors.operatorId && errors.operatorId}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Operator Name'
                        name='OperatorName'
                      />
                      <span className='text-danger fs-5'>
                        {errors.OperatorName && errors.OperatorName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                      />
                      <span className='text-danger fs-5'>{errors.email && errors.email}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='group'
                      >
                        <option value=''>Select Group</option>
                        {/* <option value='Admin'>Admin</option>
                        <option value='CPO'>CPO</option>
                        <option value='gfgffjh'>gfgffjh</option>
                        <option value='Operator'>Operator</option>
                        <option value='SubCpo'>SubCpo</option>
                        <option value='SubOperator'>SubOperator</option>
                        <option value='testing'>testing</option> */}
                        {roles.map((item: any, i: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>
                      <span className='text-danger fs-5'>{errors.group && errors.group}</span>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='country'
                      >
                        <option value=''>Select Country</option>
                        <option value='India'>India</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.country && errors.country}</span>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='state'
                      >
                        <option value=''>Select State</option>
                        <option value='Andaman and Nicobar Islands'>
                          Andaman and Nicobar Islands
                        </option>
                        <option value='Andhra Pradesh'>Andhra Pradesh</option>
                        <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                        <option value='Assam'>Assam</option>
                        <option value='Bihar'>Bihar</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.state && errors.state}</span>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field type='text' className='form-control' placeholder='City' name='city' />
                      <span className='text-danger fs-5'>{errors.city && errors.city}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        name='pincode'
                      />
                      <span className='text-danger fs-5'>{errors.pincode && errors.pincode}</span>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='countryCode'
                      >
                        <option value='+91'>India</option>
                        <option value='+977'>Nepal</option>
                        <option value='+1'>United States</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.countryCode && errors.countryCode}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Mobile Number'
                        name='mobileNumber'
                      />
                      <span className='text-danger fs-5'>
                        {errors.mobileNumber && errors.mobileNumber}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line1'
                        name='address1'
                      />
                      <span className='text-danger fs-5'>{errors.address1 && errors.address1}</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line2'
                        name='address2'
                      />
                      <span className='text-danger fs-5'>{errors.address2 && errors.address2}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <label htmlFor='startDate'>AMC Start Date</label>
                      <Field
                        type='date'
                        className='form-control'
                        id='startDate'
                        name='AMCstart'
                      />
                      <span className='text-danger fs-5'>{errors.AMCstart && errors.AMCstart}</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <label htmlFor='endDate'>AMC end Date</label>
                      <Field
                        type='date'
                        className='form-control'
                        id='endDate'
                        name='AMCend'
                      />
                      <span className='text-danger fs-5'>{errors.AMCend && errors.AMCend}</span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-primary'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    {/* <PopUpSumbit text={'Update'} /> */}
                    <button type='submit' className='btn btn-light-success'>
                      Update
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </div>
                          </div>
                          <div className='modal-body'>
                            <p>Your data is not saved, Are you sure to exit ?</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                            >
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/UserManagment/User/CmsUsers')}
                              className='btn btn-light-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditDetailsCMS
