import React from 'react'
import AddStationDetails from './Components/AddStationDetails'

const AddChargeStation = () => {
  return (
    <div>
      <AddStationDetails />
    </div>
  )
}

export default AddChargeStation
