/* eslint-disable */

import {ImCross} from 'react-icons/im'
import {KTSVG} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {getToken} from './Token'
import {postRequest} from '../Helpers/Requests'

const AssignedRoles = ({assignedroles, getRole}) => {
  const {pointId} = useParams()

  const removePermissionForRole = async (id) => {
    const data = {permissionId: id, roleId: pointId}
    const res = await postRequest('/permissions/remove', data)
    if (res && res.data) {
      console.log(res.data)
      getRole()
    }
  }
  return (
    <div>
      <ul className='list-group rounded-0'>
        {assignedroles.map((item, index: number) => (
          <li
            key={index}
            className='list-group-item text-dark fw-bold py-7 d-flex gap-5 fs-6 align-items-center'
          >
            <div className='d-flex justify-content-end flex-shrink-0'>
              <ImCross
                size={15}
                className='text-danger hoverable'
                style={{cursor: 'pointer'}}
                // data-bs-toggle='modal'
                // data-bs-target='#modal'
                onClick={() => {
                  removePermissionForRole(item.id)
                }}
              />
              {/* <div className='modal fade' tabIndex={-1} id='modal'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header  bg-success'>
                      <h5 className='modal-title text-white'>Confirmation</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-success ms-2 text-white'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body d-flex  align-items-center'>
                      <h5>Are You Sure Want To Remove This Role</h5>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        No
                      </button>
                      <button
                        type='button'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                        onClick={() => {
                          removePermissionForRole(item.id)
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <label>
              <div className='me-3' id='flexCheckChecked'></div>
              {item.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AssignedRoles
