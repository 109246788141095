import React from 'react'
import FailedDetails from './Components/FailedDetails'

const FailedT = () => {
  return (
    <div>
      <FailedDetails />
    </div>
  )
}

export default FailedT
